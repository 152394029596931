import React from 'react';
import './sk-textbox.scss';

const SKTextBox = ({ placeholder = "", value = null, onChange = () => { } }) => {
    return (
        <div className='sk-textbox'>
            <input type='text' value={value} onChange={(e) => onChange(e.target.value)}></input>
        </div>
    )
}

export {
    SKTextBox
};
