import React, { Fragment, useState, useEffect } from "react";
import "./AccountSetup.scss";
import ChangePassword from "../change-password/ChangePassword";
import Button from "../../ui/button/Button";
import AdoptSignature from "../agreement/adoptSignature/AdoptSignature";
import EditInfo from "../account/edit-info/EditInfo";
import Footer from "../../common/footer/Footer";
import Header from "../../common/header/Header";
import FinalPage from "../agreement/finalPage/FinalPage";
import { uploadSignature, GetComposer } from "../../../store/composer/actions";
import { useDispatch } from "react-redux";
import Spinner from "../../ui/spinner/Spinner";

const AccountSetup = (props) => {
    const dispatch = useDispatch();
    const [btn1Title, setBtn1Title] = useState("READY! GO TO STEP 2");
    const [step, setSteps] = useState(1);
    const [composerGuid, setComposerGuid] = useState("");
    const [composer, setComposer] = useState({});
    const [signature, setSignatureData] = useState(undefined)
    const [guID, setguIDData] = useState(undefined)
    const [isAuthorisedsignature, setAuthorisedsignature] = useState(false)
    const [showAdoptValidations, setShowAdoptValidations] = useState(false)
    const [userValid, setUserValid] = useState(undefined)
    const [showStepBtn, setShowStepBtn] = useState(true)

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true)
        dispatch(GetComposer(props.match.params.token)).then(res => {
            if (res.Success) {
                setComposerGuid(res.Composers.Guid)
                setUserValid(true)
            }

            setIsLoading(false)

        }).catch(err => {
            console.log("ERROR: ", err)
            setUserValid(false)
            setIsLoading(false)
        })
    }, [])


    const handleReadyToGo = () => {
        if (step == 1) {
            handleComposerPageButton()
        }
        else if (step == 2) {
            handleComposerEditInfoPageButton()
        }

        else if (step == 3) {
            if (isAuthorisedsignature === true) {
                uploadSignatureAPICall()
            }
            else {
                setShowAdoptValidations(true)
            }
        }
    };


    const handleGoToPage = (isGoTo) => {
        if (isGoTo) {
            setSteps(step + 1)
            setBtn1Title("GO TO STEP " + (step + 2))
        }
    }

    const handleGotoPageEdit = (composerData) => {
        setComposer(composerData)
    }

    const handleComposerPageButton = () => {
        document.querySelector("#change-password-account-setup").click()
    }

    const handleComposerEditInfoPageButton = () => {
        document.querySelector("#edit-info-account-setup").click()
    }

    const signatureSelectedAction = (signatureData, isAuthorized) => {
        if (signatureData.id != "" && isAuthorized != false) {
            setSignatureData(signatureData.signature)
            setguIDData(signatureData.id)
            setAuthorisedsignature(true)
        } else {
            setAuthorisedsignature(false)
        }
    }

    const uploadSignatureAPICall = () => {
        const data = {
            "ImageData": signature,
            "ComposerGuid": guID
        }

        dispatch(uploadSignature(data)).then(res => {
            if (res.Success) {
                setSteps(step + 1)
                setShowStepBtn(false)

            }
        }).catch(error => {
        })
    }

    const renderSection = renderValue => {
        switch (renderValue) {
            case 1:
                return (
                    <div>
                        <ChangePassword showFooter={false} isAccountSetupFlow={true} composerGuid={composerGuid} gotonext={handleGoToPage}></ChangePassword>
                    </div>
                );
            case 2:
                return (
                    <div style={{ padding: "20px" }}>
                        <EditInfo accountSetupPage={true} displayButton={false} composerGuid={composerGuid} gotonext={handleGoToPage} pageEditData={handleGotoPageEdit}></EditInfo>
                    </div>
                );

            case 3:
                return (
                    <div style={{ padding: "20px" }}>
                        <AdoptSignature isSegnatureSelected={signatureSelectedAction} composerName={composer} showAdoptValidations={showAdoptValidations}></AdoptSignature>
                    </div>
                );
            case 4:
                return (
                    <div style={{ height: "40rem", paddingTop: "2rem" }}>
                        <FinalPage></FinalPage>
                    </div>
                );
            default:
                return null;
        }
    };

    const renderButton = () => {
        if (step === 1 || step === 2) {
            return null
        }


        return (<div>
            {showStepBtn ? (<div className={`${step == 1 ? "step-btn step1-btn" : "step-btn"}`}>
                <Button
                    title={btn1Title}
                    buttonStyle="ready-btn"
                    onClick={handleReadyToGo}
                />
            </div>) : null}
        </div>)
    }

    const renderView = () => {
        if (isLoading) {
            return <Spinner isLoading={isLoading} />
        } else {
            if (userValid) return <div> {renderSection(step)}{renderButton()}</div>
            else return <div className="not-found"><span>Composer Not Found</span></div>
        }
    }

    return (
        <div className="account-setup">
            <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                <Header title="ACCOUNT SETUP" />
                {renderView()}
            </div>
            <Footer />
        </div>
    );
};

export default AccountSetup;

