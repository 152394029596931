import React, { useState } from "react";

const ContactUs = () => {
  return (
    <div>
      <h1 className="title">Contact Us</h1>
      <div>
        <h5 className="title is-6">General questions:</h5>
        <p className="subtitle is-5">
          anr@scorekeepersmusic.com<br/>
          <div>* Before contacting us, please note that many questions are addressed on the <a href="/production-guidelines">Production Guidelines</a> and <a href="/faq">FAQ</a> pages accessed via the links to your left.</div>
        </p>        
      </div>
      <br />
      <br />
      <div >
        <h5 className="title is-6">Technical support:</h5>
        <p className="subtitle is-5">tech@scorekeepersmusic.com</p>
      </div>
    </div>
  );
}

export default ContactUs;