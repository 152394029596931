import React, { Fragment, useState, useEffect, useRef } from "react";
import "./AdoptSignature.scss";
import Checkbox from "../../../ui/checkbox/Checkbox";
import Button from "../../../ui/button/Button";

const AdoptSignature = (props) => {

  const [selectedAuthorizSignature, setAuthorizeSignature] = useState(false);
  const [selectedCheckbox, setSelectedCheckbox] = useState({ id: "", value: false});
  const [username, setUserName] = useState("");
  const [request, setRequest] = useState(
    {
      id: "",
      signature: "",
    }
  )

  const handleCheckboxChange = (value, id) => {

    setSelectedCheckbox({ id, value });
    if (value === true) {
      DownloadCanvasAsImage(id)
    } else {
      setRequest({
        signature: "",
        id: ""
      });
    }
  };

  useEffect(() => {
    setUserName(props.composerName.FirstName + " " + props.composerName.MiddleName + " " + props.composerName.LastName);
  },[props.composerName.FirstName])

  const nameRef = useRef(null);

  const DownloadCanvasAsImage = (id) => {
    const canvas = nameRef.current
    canvas.width = 800;
    canvas.height = 200;
    const ctx = canvas.getContext("2d")

    if (id === "historia") {
      ctx.font = "bold 100px Historia";
      ctx.fontWeight = 'bold'
      ctx.fillText(username, 10, 160);
    } else if (id === "balnes") {
      ctx.font = "bold 80px DemoBalnes";
      ctx.fillText(username, 10, 160);
    } else if (id === "aerotis") {
      ctx.font = "bold 50px aerotis";
      ctx.fillText(username, 10, 160);
    } else {
      ctx.font = "bold 80px MrDeHaviland-Regular";
      ctx.fillText(username, 10, 160);
    }
    createCanvas();
  }

  const createCanvas = () => {
    let downloadLink = document.createElement('a');
    downloadLink.setAttribute('download', 'CanvasAsImage.png');
    let canvas = document.getElementById('myCanvas');
    let dataURL = canvas.toDataURL('image/png');
    setRequest({
      signature: dataURL,
      id: props.composerName.Guid
    });
  }

  const renderCanvas = () => {
    { props.isSegnatureSelected(request, selectedAuthorizSignature) }
    return (
      <div className="canvasStye">
        <canvas ref={nameRef} id="myCanvas" />
      </div>
    )
  }

  return (
    <div className="adoptSignature-page ">
      <div className="adoptSignature-parent-container">
        <div className="adoptSignature-content">
          By checking the "Authorize Signature" box, Composer agrees that its electronic Signature is the binding equivalent of its manual signature for the purpose of executing any ScoreKeepers Music Agreement(s) and Schedule(s). Composer further agrees that its use of a keypad, mouse or other device to select an item, button, or icon constitutes its signature (E-Signature) as acceptance and agreement as if actually signed by composer in writing. Composer also agrees that no certification authority or other third party verification is necessary to validate its E-Signature and that the lack of such certification or third party verification will not in any way affect the enforceability of Composer E-Signature in contracts between Composer end ScoreKeepers Music, Inc. Composer further agrees that each use of its E-Signature is bound by the terms and conditions of Scorekeepers Music Agreements and Schedules as they exist on the date such documents are E-Signed by Composer.
        </div>
        <div>
          <div className="adopt-signature-button-container">
            <Button
              title="ADOPT YOUR E-SIGNATURE"
              buttonStyle="adoptSignature-btn"
              disabled={true}
            ></Button>
          {
           !selectedCheckbox.value && props.showAdoptValidations ? (<span className="error-message">Please select E-SIGNATURE</span>) : ''
          }
          </div>
          <div className="adoptSignature-container">
           <div className="adopt-signature-container-list adopt-font-historia">
              {username}
              <Checkbox
                id="historia"
                onChange={value => handleCheckboxChange(value, "historia")}
                value={
                  selectedCheckbox.id === "historia" ? selectedCheckbox.value : false}
              ></Checkbox>
            </div>
            <div className="adopt-signature-container-list adopt-font-balnes">
              {username}
              <Checkbox
                id="balnes"
                onChange={value => handleCheckboxChange(value, "balnes")}
                value={selectedCheckbox.id === "balnes" ? selectedCheckbox.value : false}
              ></Checkbox>
            </div>
            <div className="adopt-signature-container-list adopt-font-aerotis">
              {username}
              <Checkbox
                id="aerotis"
                onChange={value => handleCheckboxChange(value, "aerotis")}
                value={selectedCheckbox.id === "aerotis" ? selectedCheckbox.value : false}
              ></Checkbox>
            </div>
            <div className="adopt-signature-container-list adopt-font-mrDeHaviland-Regular">
              {username}
              <Checkbox
                id="mrDeHaviland"
                onChange={value => handleCheckboxChange(value, "mrDeHaviland")}
                value={selectedCheckbox.id === "mrDeHaviland" ? selectedCheckbox.value : false}
              ></Checkbox>
            </div>
          </div>
          <div className="adopt-signature-authorised b-checkbox">
              <Checkbox
                onChange={value => {
                  setAuthorizeSignature(value);
                }}
                value={selectedAuthorizSignature}
              />
              AUTHORIZE SIGNATURE
          </div>
            { !selectedAuthorizSignature && props.showAdoptValidations ? ( <span className="error-message" style={{marginLeft:'9.5rem'}}> Please check Authorize Signature</span>) : '' }
          {renderCanvas()}
        </div>
      </div>
    </div>
  );
};

export default AdoptSignature;
