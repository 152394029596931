import React, { Fragment } from 'react';
import "./FileList.scss"
import { FontIconButton } from '../../ui/font-icon/font-icon';

const FileItem = ({ name, index, onFileDelete=()=>{} }) => 
{
    return (
    <div className="title is-7 is-marginless">
        {name}
        <span className="delete-file" onClick={() => onFileDelete(name)}>
            <FontIconButton name='close' size={12} />
        </span>
    </div>
    )
}

const mapFiles = (files = [], onFileDelete) => {
    let fileItems = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        fileItems.push(<FileItem name={file.name} index={i} key={`fileName_${i}`} onFileDelete={onFileDelete} />)
    }
    return fileItems
}

const FileList = ({
    files = [],
    onFileDelete = () => {},
}) => {
    return (
        <Fragment>
            <div className="file-list-container">
                {mapFiles(files, onFileDelete)}
            </div>
        </Fragment>
    )
}

export default FileList;