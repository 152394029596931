export const LOAD_UPLOADED_TRACKS = "LOAD_UPLOADED_TRACKS";

export const LoadUploadedTracks = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
        }

        handleFetch(`/api/tracks/uploaded-tracklist`, content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}

export const LoadContextMenuData = (type = "") => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/tracks/context/${type}`)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}

export const SaveTracksMetaData = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }
        handleFetch(`/api/tracks/save`, content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}