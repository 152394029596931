import React, { Fragment } from 'react';
import "./UploadComposer.scss"
import Button from '../../ui/button/Button';
import Icon from '../../ui/icon/Icon';
import Textarea from '../../ui/textarea/Textarea';
import FileList from '../file-list/FileList';
import FileSelector from '../../ui/file-select/FileSelector';
import Image from '../../ui/image/Image';

const UploadComposer = ({
    onUpload = () => { },
    message = "",
    messageClassName,
    comment = "",
    fileSelectTitle = "",
    bottomNote1 = "",
    bottomNote2 = "",
    onFileSelect = () => { },
    onNoteChange = () => { },
    files = [],
    note = "",
    uploadButtonDivStyle = "",
    isShowNote = true,
    notesWordCount="0",
    notesMaxLength="0",
    onFileDelete = () => {}
}) => {

    return (
        <Fragment>
            <div className="upload-composer-cotainer">
                <div className="text-container mg-top-1 mg-lft-rgt-3">
                    <Image url={require("../../../appearance/images/upload.png")} imgClassName="img-container" />
                    <div className="columns is-marginless label-container">
                        <div className="is-paddingless label-container">
                            <p className={"subtitle is-6 is-marginless " + messageClassName}>{message}</p>
                            <p className="subtitle is-7">{comment}</p>
                        </div>
                        <div className="is-paddingless">
                            <FileSelector title={fileSelectTitle} onFileSelect={onFileSelect} buttonStyle="is-small blue-small-button" />
                        </div>
                    </div>
                </div>
                <div className="mg-lft-rgt-3 mg-top-1">
                    <FileList files={files} onFileDelete={onFileDelete}/>
                </div>
                {isShowNote
                    ?
                    <div className="mg-lft-rgt-3 mg-top-2">
                        <Textarea className="textarea-hgt-7" value={note} 
                            onChange={onNoteChange} 
                            maxLength={notesMaxLength}
                            placeholder="Include a note with your submission, and/or provide web url for online streaming (no downloads please)." 
                        />
                        <div>{notesWordCount} character(s) remaining.</div>
                    </div>
                    : null
                }
                <div className={"text-container mg-top-1 " + uploadButtonDivStyle}>
                    <div className="columns is-marginless label-container">
                        <div className="column is-8 is-paddingless label-container">
                            <p className="subtitle is-7 is-marginless label-padding">{bottomNote1}</p>
                            <p className="subtitle is-7 label-padding">{bottomNote2}</p>
                        </div>
                        <div className="column is-4 is-paddingless shift-to-end">
                            <Button title="UPLOAD" onClick={onUpload} buttonStyle="green-large-button" />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default UploadComposer;