import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import { FetchEmailTemplates } from "../../../../store/composer/actions"
import { ShowErrorNotification } from 'components/ui/snackbar/Snackbar';
import "./CurrentMusicNeeds.scss"

const NewsInfo = ({ }) => {

    const dispatch = useDispatch();
    const [emailTemplates, setEmailTemplates] = useState(undefined)

    useEffect(() => {
        getNewsInfo();
    }, [])

    const getNewsInfo = () => {
        dispatch(FetchEmailTemplates("News and Info")).then(res => {
            if (res.Success) {
                setEmailTemplates(res.EmailTemplates)
            } else {
                ShowErrorNotification("Unable to fetch Current Music Needs ", 3000)
            }
        })
    }

    const getHtmlData = () => {
        if (!emailTemplates || !emailTemplates.EmailDescription) {
            return null
        }

        let el = document.querySelector("#current-music-needs")
        el.innerHTML = null;
        let contentDiv = document.createElement('div');
        contentDiv.innerHTML = emailTemplates.EmailDescription
        el.appendChild(contentDiv)
    }

    return (
        <div>
            <h3 className="title is-3">Current Music Needs</h3>
            <div className="current-music-needs" id="current-music-needs">
                {getHtmlData()}
            </div>
        </div>
    )
}

export default NewsInfo