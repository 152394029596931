import { TrackAudioPlayer } from 'utils/common/trackPlayer';

class CommonAPI {
    constructor(store) {
        this.GetStore = () => store;
        this.GetState = () => store.getState();
        this.TrackPlayer = new TrackAudioPlayer();

        this.EnableDebug = (value) => {
            localStorage.setItem('debug-enabled', value);
        }

        this.GetMissingWaveformImageUrl = () => {
            return 'appearance/images/missing_waveform.png'
        }

        this.GetPreviewUrl = (track) => {
            return track.S3Url
        }

        this.ConvertToSeconds = (hhmmss) => {
            let seconds = 0;
            let parts = hhmmss.split(':').reverse();

            parts.forEach((p, i) => {
                const v = Number(p) != 'NaN' ? Number(p) : 0;
                if (i == 0) seconds += v;
                if (i == 1) seconds += (v * 60);
                if (i == 2) seconds += (v * 3600);
            })

            return seconds;
        }

        this.ConvertToHHMMSS = (seconds) => {
            const ss = Number(seconds) % 60;
            const mm = parseInt(seconds / 60);
            const hh = parseInt(seconds / 3600);

            return ((hh > 0) ? hh + ':' : '') +
                ((mm > 0) ? mm + ':' : ':') +
                (ss.padLeft(2, '0'));
        }
    }

    get isSmallScale() {
        return window.innerWidth < 1500;
    }

    get isNormalScale() {
        return window.innerWidth >= 1500;
    }

    get windowHeight() {
        return parseInt(document.querySelector(".main-view").getBoundingClientRect().height);
    }
}

export default CommonAPI;