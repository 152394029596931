import React, { Fragment, useState } from "react";
import "./Select.scss";
import Icon from "../icon/Icon";


const OptionItem = ({ option, onOptionSelect }) => {
    return <span className="options" onClick={() => onOptionSelect(option)}>{option.label}</span>
}

const Options = ({ options, onOptionSelect, toggleOptions }) => {
    if (!options || !toggleOptions) return null

    const optionList = options.map(option => <OptionItem key={"option_" + option.id} option={option} onOptionSelect={onOptionSelect} />)

    return (
        <div className="options-div">
            {optionList}
        </div>
    )
}


const Select = ({
    label,
    onSelect = () => { },
    options = [],
    selectedOption = {},
    labelClassName = ""
}) => {
    const [toggleOptions, setToggleOptions] = useState(false)

    const handleSelectButton = () => {
        setToggleOptions(!toggleOptions)
    }

    const handleSelectedOption = (option) => {
        onSelect(option)
        setToggleOptions(!toggleOptions)
    }

    const handleOnMouseLeave = () => {
        if (toggleOptions) {
            setToggleOptions(!toggleOptions)
        }
    }

    return (
        <Fragment>
            <div className="select-container" onMouseLeave={handleOnMouseLeave}>
                <p className={labelClassName}>{label}</p>
                <div className="select-body-container">
                    <span className="select-button-div" onClick={handleSelectButton}>
                        <span className="select-button">{selectedOption && selectedOption.label ? selectedOption.label : "Select"}</span>
                        <Icon iconContainerStyle="icon-container" iconName="fas fa-caret-down" iconStyle="icon-style" />
                    </span>
                    <Options options={options} onOptionSelect={handleSelectedOption} toggleOptions={toggleOptions} />
                </div>
            </div>
        </Fragment>
    )
}

export default Select;