import * as Actions from './actions';

let defaultState = {
   isFetching: false
}

const reducers = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.UI_RESET: {
            return defaultState;
        }
        case Actions.COMPOSER_USER:{
            return {
                ...state,
                isFetching: true,
                composerUser:action.composerUser
            }
        }

        default:
            return state;
    }
}

export default reducers;