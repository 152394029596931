const emailValidation = (email) => {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,20})$/;
    return reg.test(email)
}

const isNumberOnly = (number) => {
    const re = /^(\d+(\.\d{0,3})?|\.?\d{1,2})$/;
    return re.test(number)
}


export {
    emailValidation,
    isNumberOnly
}