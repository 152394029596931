import React, { Fragment } from 'react';
import "./Button.scss"

const Button = ({
    title,
    disabled = false,
    onClick,
    buttonStyle,
    id = ""
}) => {
    return (
        <Fragment>
            <button disabled={disabled} className={"button " + buttonStyle} onClick={onClick} id={id}>{title}</button>
        </Fragment>
    )
}

export default Button;