import React, { Fragment } from 'react';
import "./Textarea.scss"

const Textarea = ({
    onChange = () => { },
    value,
    className,
    placeholder = "",
    maxLength = "1000",
    onBlur = () => { },
}) => {
    return (
        <div className="textarea-container">
            <textarea
                onChange={(e) => onChange(e.target.value)}
                value={value}
                className={className}
                placeholder={placeholder}
                onBlur={(e) => onBlur(e.target.value)}
                maxLength={maxLength}
            />
        </div>
    )
}

export default Textarea;