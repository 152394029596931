import React, { Fragment } from 'react';
import "./Header.scss"
import logo from "../../../appearance/images/app-logo.png";

const Header = ({ title }) => {
    return (
        <Fragment>
            <div className="columns header-container is-mobile is-marginless">
                <div className="column is-2 is-paddingless logo-container">
                    <img src={logo} className="logo" />
                </div>
                <div className="column title-container">
                    <p>{title}</p>
                </div>
            </div>
        </Fragment>
    )
}

export default Header;