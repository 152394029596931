import React, { useState, useEffect } from "react";
import "./SignSchedule.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchSignScheduleTemplates,
  showAgreementPdfFile,
  DownloadComposerAgreement,
  generateSignSchedulePdf,
} from "../../../../store/composer/actions";
import Button from "../../../ui/button/Button";
import Spinner from "../../../ui/spinner/Spinner";
import Signhere from "../../../../appearance/images/sing_here.png";
import Image from "../../../ui/image/Image";
import { ShowErrorNotification } from "../../../ui/snackbar/Snackbar";

const SignSchedule = ({
  submissionType = "",
  tracks = [],
  composerGuid = "",
  composer,
  goToNext = () => {},
  downloadFileName = () => {},
  onBackClick = () => {},
}) => {
  const [agreementFileName, setAgreementFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState(undefined);
  const [showImage, setShowImage] = useState(false);
  const [composerDetail, setComposerDetail] = useState({});
  const dispatch = useDispatch();

  const getTemplateType = () => {
    if (submissionType.toLowerCase() === "exclusive") {
      return "ComposerAgreement-Ex";
    } else if (submissionType.toLowerCase() === "non-exclusive") {
      return "ComposerAgreement-NonEx";
    } else {
      return "ComposerAgreement-Custom";
    }
  };

  useEffect(() => {
    const data = {
      TemplateType: getTemplateType(),
      Tracks: tracks.map((t) => ({ Name: t.Filename })),
      ComposerGuid: composerGuid,
      Composers: composer.label,
    };

    // setIsLoading(true)
    dispatch(FetchSignScheduleTemplates(data))
      .then((res) => {
        setEmailTemplates(res.template);
        setComposerDetail(res.Composers);
        // setIsLoading(false);
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, []);

  const getHtmlData = () => {
    if (!emailTemplates || !emailTemplates) {
      return null;
    } else {
      let el = document.querySelector("#signOnline-page");
      el.innerHTML = null;
      let contentDiv = document.createElement("div");
      contentDiv.innerHTML = emailTemplates;
      el.classList.add("show-pdf-align");
      el.appendChild(contentDiv);

      let element = document.getElementById("sign-image");
      let signatureImage = element.src;
      let elementClick = document.getElementById("signClickImage");
      elementClick.src = Signhere;
      elementClick.width = "100";
      elementClick.height = "10";
      !showImage
        ? (element.style.display = "none")
        : (element.style.display = "block");
      element.src = signatureImage;
      elementClick.addEventListener("click", () => {
        setIsLoading(true);
        element.classList.add("show-signature-image");
        const data = {
          TemplateType: getTemplateType(),
          Tracks: tracks.map((t) => ({ Id: t.Id, Name: t.Filename })),
          Composer: composerDetail,
          Composers: composer.label,
        };

        dispatch(generateSignSchedulePdf(data))
          .then((res) => {
            setIsLoading(false);
            fetchPdfUrl(res.ComposerSignSchedule.FileKey);
            downloadFileName(res.ComposerSignSchedule.FileKey);
            setShowImage(true);
            goToNext();
          })
          .catch((err) => {
            setIsLoading(false);
            console.log("ERROR: ", err);
          });
      });
    }
  };

  const fetchPdfUrl = (fileName) => {
    dispatch(showAgreementPdfFile(fileName, "signSchedule"))
      .then((res) => {
        setIsLoading(false);
        if (res.Success) {
          setAgreementFileName(res.fileName);
        } else {
          throw new Error("Unable to fetch agreement pdf.");
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
        setIsLoading(false);
        console.error(err);
      });
  };

  const readyToDownload = () => {
    dispatch(DownloadComposerAgreement(agreementFileName, "signSchedule"));
  };

  // const gotoNextStep = (advanceToNextStep = false) => {
  //     if (advanceToNextStep) {
  //         downloadFileName(agreementFileName)
  //         goToNext();
  //     }
  //     else {
  //         ShowErrorNotification("Please Click on Sign Here!", 3000)
  //     }
  // }

  return (
    <div className="sign-schedule-container">
      <Spinner isLoading={isLoading} />
      <div className="row-container-row">
        <h3 className="title is-3">SIGN SCHEDULE</h3>
        <p
          className="row-container-Cancel cancel-button-link"
          onClick={onBackClick}
        >
          {"< CANCEL, RETURN TO BEGINING"}
        </p>
      </div>
      <div className="cancel-button">
        <Image
          url={require("../../../../appearance/images/Composer-Uploads-Step3.png")}
          height={200}
          imgClassName="cancel-button-img"
        />
        {/* <div className="cancel-button-link" onClick={onBackClick}>{"< CANCEL, RETURN TO BEGINING"} </div> */}
      </div>
      <div className="sign-schedule-body">
        <div className="signOnline-page" id="signOnline-page">
          <div className="show-pdf-align">{getHtmlData()}</div>
        </div>
      </div>
    </div>
  );
};

export default SignSchedule;
