import React from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";
// import { Scrollbars } from 'react-custom-scrollbars';
import { ScrollWindow } from "../scroll-window/scroll-window";
import { Flex } from "../../ui/flex/flex";
import { FontIcon, FontIconButton } from "../../ui/font-icon/font-icon";

import "./context-menu.scss";

// // Load the CommonAPI for the Environment
// const CommonAPI = require((process.env.API_CLASS)).default;
// window.Common = new CommonAPI(store);

const contextMenus = new Array(10);
let checkedItems = [];
let menuOptions = {};
let clickOffsetX;
let clickOffsetY;

const callbacks = {
  onItemSelected: () => {},
};

const itemIsChecked = (item) => {
  if (checkedItems) return checkedItems.some((i) => i.Value.Id === item.Value.Id);
  else return false;
};

const itemHasAllChildrenChecked = (item) => {
  if (item.SubMenu && item.SubMenu.length > 0) {
    let checked = true;

    item.SubMenu.forEach((subItem) => {
      if (!itemIsChecked(subItem)) checked = false;
    });

    return checked;
  }

  return false;
};

const itemHasSomeChildrenChecked = (item) => {
  const childrenCount = item.SubMenu && item.SubMenu.length > 0 ? item.SubMenu.length : 0;
  let childrenChecked = 0;

  if (itemIsChecked(item)) return true;

  if (childrenCount > 0) {
    item.SubMenu.forEach((subItem) => {
      if (itemHasSomeChildrenChecked(subItem)) {
        childrenChecked++;
      }
    });
  }

  return childrenCount > 0 && childrenChecked > 0;
};

const defaultSettings = {
  height: null,
  width: null,
  lineHeight: 30,
  maxHeight: 500,
  maxWidth: null,
  minHeight: 32,
  minWidth: null,
};

const eventPath = (evt) => {
  var path = null; //evt.path || (evt.composedPath && evt.composedPath());
  var target = evt.target;

  if (path != null) {
    // Safari doesn't include Window, but it should.
    return path.indexOf(window) < 0 ? path.concat(window) : path;
  }

  if (target === window) {
    return [window];
  }

  function getParents(node, memo) {
    memo = memo || [];
    var parentNode = node.parentNode;

    if (!parentNode) {
      return memo;
    } else {
      return getParents(parentNode, memo.concat(parentNode));
    }
  }

  return [target].concat(getParents(target), window);
};

const onContextClick = (event) => {
  const path = eventPath(event);
  const contextWindow = path.findIndex((ep) => ep.className && ep.className.indexOf("cm-window") > -1);

  if (contextWindow === -1) {
    closeContextMenu();
  }
};

let menuTimer = 0;
export const closeContextMenu = () => {
  const cm = document.getElementById("context-menu");
  if (menuTimer) {
    clearTimeout(menuTimer);
  }

  if (cm != null) {
    document.body.removeChild(cm);
  }

  for (let i = 0; i < 10; i++) {
    contextMenus[i] = null;
  }

  if (callbacks.onClose) callbacks.onClose();

  document.removeEventListener("click", onContextClick);
};

export const showContextMenu = (
  x,
  y,
  menu,
  selectedItems = [],
  options,
  onItemSelected = () => {},
  onItemChecked = () => {},
  onClose = () => {},
  fromTrackPlayer,
  contextMenuName
) => {
  if (document.getElementById("context-menu") != null) {
    return;
  }
  clearTimeout(menuTimer);

  checkedItems = selectedItems.items || [];

  // checkedItems = selectedItems
  //     ? (selectedItems.constructor === Array)
  //         ? selectedItems.slice(0)
  //         : []
  //     : [];
  callbacks.onItemChecked = onItemChecked;
  callbacks.onItemSelected = onItemSelected;
  callbacks.onClose = onClose;

  const cm = document.createElement("div");
  cm.id = "context-menu";
  cm.className = "admin";
  document.body.appendChild(cm);

  menuOptions = Object.assign({}, defaultSettings, options);

  ReactDOM.render(
    <ContextMenuContainer>
      <ContextMenu
        x={x}
        y={y}
        level={0}
        menu={menu ? menu.slice(0) : []}
        options={menuOptions}
        parent={menuOptions.owner}
        fromTrackPlayer={fromTrackPlayer}
        contextMenuName={contextMenuName}
      />
    </ContextMenuContainer>,
    cm
  );

  document.addEventListener("click", onContextClick);
};

class ContextMenuContainer extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      selectedItems: [],
      childMenus: new Array(10),
    };
  }

  componentDidMount() {
    const { level } = this.props;
    contextMenus[level] = this;
  }

  handleContainerClick(event) {
    event.stopPropagation();
    event.preventDefault();
    setTimeout(() => closeContextMenu(), 5000);
  }

  render() {
    const { children } = this.props;

    return (
      <div
        className="cm-container"
        onClick={this.handleContainerClick.bind(this)}
        onContextMenu={this.handleContainerClick.bind(this)}
      >
        {children}
      </div>
    );
  }
}

class ContextMenu extends React.Component {
  constructor() {
    super(...arguments);
    this.itemElements = [];
    this.currentSubMenu = null;
  }

  get windowHeight() {
    return parseInt(document.querySelector(".main-page").getBoundingClientRect().height);
  }

  componentDidMount() {
    contextMenus[this.props.level] = this;
    clearTimeout(menuTimer);
    if (this.props.fromTrackPlayer)
      menuTimer = setTimeout(() => {
        closeContextMenu();
      }, 5000);

    // Adjust and update the Context Menu Y position if it is too tall and goes off the screen
    const rect = this.element.getBoundingClientRect();
    if (rect.bottom > window.innerHeight) {
      this.element.style.top = rect.top - (rect.bottom - window.innerHeight) + "px";
    }
  }

  componentDidUpdate() {
    const parentObj = ReactDOM.findDOMNode(this.props.parent);
    if (parentObj === null) {
      return;
    }
    //const parentRect = ReactDOM.findDOMNode(this.props.parent).getBoundingClientRect();
    const elm = ReactDOM.findDOMNode(this.element);
    const shadowElm = ReactDOM.findDOMNode(this.shadowElement);
    const rect = elm.getBoundingClientRect();
    var x = this.props.x;
    var y = this.props.y;

    if (y + rect.height > window.innerHeight) {
      y = window.innerHeight - Math.min(rect.height, this.props.options.maxHeight);
    }

    if (x + rect.width > window.innerWidth) {
      x = this.props.parent
        ? ReactDOM.findDOMNode(this.props.parent).getBoundingClientRect().left - rect.width
        : window.innerWidth - rect.width;
    }

    // elm.style.left = x + 'px';
    // elm.style.top = y + 'px';

    const shadowRect = elm.getBoundingClientRect();
    shadowElm.style.left = shadowRect.left + "px";
    shadowElm.style.top = shadowRect.top - 20 + "px";
    shadowElm.style.width = shadowRect.width + 20 + "px";
    shadowElm.style.height = shadowRect.height + 40 + "px";
  }

  handleWindowClick(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  handleItemClick(item, elementIndex, event) {
    const element = this.itemElements[elementIndex];

    if (!element) return;

    const isChecked = !element.classList.contains("checked");
    if (menuOptions.multiSelect) {
      if (item.SubMenu && item.SubMenu.length > 0) {
      } else {
        const subIndex = (checkedItems || []).findIndex((ci) => ci.Value.Id === item.Value.Id);

        if (this.parentMenu) {
          let allChecked = true;
          ReactDOM.findDOMNode(this.parentMenu).classList.toggle("checked", isChecked);
        }

        if (isChecked) {
          if (subIndex === -1) checkedItems.push(item);
        } else {
          if (subIndex >= 0) checkedItems.splice(subIndex, 1);
        }
      }

      callbacks.onItemSelected(checkedItems, isChecked);
    } else {
      if (!this.currentSubMenu) {
        callbacks.onItemSelected(item);
      }
      element.classList.toggle("checked", isChecked);
      setTimeout(() => closeContextMenu(), 300);
    }

    this.forceUpdate();
    if (this.parentMenu) this.parentMenu.forceUpdate();
    if (this.currentSubMenu) this.currentSubMenu.forceUpdate();
  }

  handleMouseLeave(elementIndex, event) {
    const target = event ? event.relatedTarget : null;
    const owner = this.props.options.owner;
    const context = document.getElementById("context-menu");
    const parentElement = this.itemElements[elementIndex];

    clearTimeout(menuTimer);

    if (event.target !== parentElement && parentElement.classList) parentElement.classList.toggle("parent-item", false);

    if (owner && owner instanceof Node) {
      if (!owner.contains(target) && context && !context.contains(target)) {
        menuTimer = setTimeout(() => closeContextMenu(), 5000);
      }
    }
  }

  handleMouseEnter(item, elementIndex, event) {
    const { options, level } = this.props;
    const newLevel = level + 1;

    clearTimeout(menuTimer);

    if (item.SubMenu && item.SubMenu.length > 0) {
      if (contextMenus[newLevel] && contextMenus[newLevel].parent === item && newLevel !== 2) return;

      if (contextMenus[newLevel] != null) {
        contextMenus[0].currentSubMenu = null;
        ReactDOM.unmountComponentAtNode(contextMenus[newLevel].subMenu);

        if (contextMenus[newLevel + 1] != null) {
          ReactDOM.unmountComponentAtNode(contextMenus[newLevel + 1].subMenu);
        }
      }
      const cm = document.getElementById("context-menu");
      const rect = event.target.closest(".cm-item").getBoundingClientRect();
      const temp = cm.appendChild(document.createElement("div"));
      const parentElement = this.itemElements[elementIndex];

      this.itemElements.forEach((e) => {
        if (e !== parentElement) e.classList.toggle("parent-item", false);
        else e.classList.toggle("parent-item", true);
      });

      var currentSubMenu = ReactDOM.render(
        <ContextMenu
          x={rect.right - 9}
          y={rect.top - 8}
          level={newLevel}
          menu={item.SubMenu}
          options={options}
          parent={this}
        />,
        temp
      );
      this.currentSubMenu = currentSubMenu;
      this.currentSubMenu.parentMenu = this;
      contextMenus[level + 1] = { parent: item, subMenu: temp };
    } else {
      if (contextMenus[newLevel] != null) {
        this.currentSubMenu = null;
        ReactDOM.unmountComponentAtNode(contextMenus[newLevel].subMenu);
      }

      contextMenus[newLevel] = null;
    }
  }

  render() {
    const {
      x = 0,
      y = 0,
      menu = [],
      parent = null,
      options = defaultSettings(),
      fromTrackPlayer,
      contextMenuName,
    } = this.props;

    let maxChar = 35;

    if (menu.length === 0) return <div />;

    const menuItems = menu.map((item, index) => {
      let checked = itemIsChecked(item);
      let someChecked = false;

      if (item.SubMenu && item.SubMenu.length > 0) {
        checked = itemHasAllChildrenChecked(item);
        someChecked = itemHasSomeChildrenChecked(item);
      }

      if (item.Selected) {
        checked = item.Selected;
      }

      const cmItemClass = classNames({
        "cm-item": true,
        checked: checked,
        "some-checked": someChecked,
        children: item.SubMenu && item.SubMenu.length > 0,
      });

      const hasChildren = item.SubMenu && item.SubMenu.length > 0;

      maxChar = item.Label.length > maxChar ? item.Label.length : maxChar;

      return (
        <div
          key={index}
          ref={(i) => {
            this.itemElements[index] = i;
          }}
          className={cmItemClass}
          onClick={this.handleItemClick.bind(this, item, index)}
          onMouseEnter={this.handleMouseEnter.bind(this, item, index)}
        >
          {item.Selected !== undefined && item.Selected !== null ? (
            item.Selected === true ? (
              <div className="checkbox_new" width={20} />
            ) : (
              <div className="checkBoxSpace" />
            )
          ) : (item.Selected === null || item.Selected === undefined) && hasChildren ? (
            <div className="checkBoxSpace" />
          ) : (
            <div className="checkbox" width={20} />
          )}
          <div className="label">{item.Label}</div>
          <div className="submenu" width={20} />
        </div>
      );
    });

    let componentStyle = Object.assign(
      {},
      {
        left: x,
        top: y,
        width: options.width,
        height: options.height,
        maxHeight: options.maxHeight,
        maxWidth: options.maxWidth,
        minHeight: options.minHeight,
        overflowY: "auto", // TODO: temporary until ScrollWindow can get fixed
      }
    );

    if (options.height == null) {
      // Original Height calculation
      //componentStyle.height = Math.min(menuItems.length * 40, options.maxHeight) + 40;//16;
      switch (contextMenuName) {
        case "Edit":
          componentStyle.height = menuItems.length * 40 + 40;
          break;

        case "Genre":
          componentStyle.height = menuItems.length * 40 + 40;
          componentStyle.maxHeight = menuItems.length * 40; //to be reviewed by Eric
          break;

        case "Mood":
          componentStyle.height = menuItems.length * 40 + 120;
          componentStyle.maxHeight = menuItems.length * 40 + 120;
          break;

        case "Energy":
          componentStyle.height = menuItems.length * 40 + 50;
          break;

        case "SoundsLike":
          componentStyle.height = menuItems.length * 40 + 60;
          break;

        default:
          componentStyle.height = Math.min(menuItems.length * 40, options.maxHeight) + 40;
          break;
      }
    }

    if (options.width == null) {
      componentStyle.width = 250;
    }

    if (parent instanceof ContextMenu == false) {
      if (clickOffsetX + componentStyle.height < window.pageYOffset + this.windowHeight) {
        componentStyle.top = clickOffsetX;
      } else {
        if (window.pageYOffset !== 0) {
          componentStyle.top += window.pageYOffset;
          if (componentStyle.top < window.pageYOffset) {
            componentStyle.top = window.pageYOffset;
          }
        }
      }

      componentStyle.top = componentStyle.top + 25;
    }

    const cmWindowClass = classNames({
      "cm-window": true,
      "cm-window-child": parent instanceof ContextMenu,
    });

    if (parent instanceof ContextMenu) {
      // if ((window.innerWidth - x) <= 300) {
      //     if (parent.element)
      //         componentStyle.left = parent.element.getBoundingClientRect().left - parent.element.getBoundingClientRect().width;

      // } else {
      componentStyle.left = componentStyle.left + 5;
      componentStyle.top = parent.element.offsetTop;
      // }
      if (componentStyle.top + componentStyle.height < componentStyle.maxHeight) {
        componentStyle.top = componentStyle.top + window.pageYOffset;
      }
      if (componentStyle.top < window.pageYOffset) {
        componentStyle.top = window.pageYOffset;
      }

      if (window.innerHeight < componentStyle.top + componentStyle.height) {
        if (parent.element) componentStyle.top = parent.element.offsetTop;
      } else {
        if (componentStyle.height > options.maxHeight)
          if (parent.element) componentStyle.top = parent.element.getBoundingClientRect().top;
      }

      componentStyle.height = parent.element.clientHeight;
      componentStyle.maxHeight = parent.element.clientHeight;
    }

    return (
      <React.Fragment>
        <div
          ref={(i) => {
            this.element = i;
          }}
          className={cmWindowClass}
          style={componentStyle}
          onClick={this.handleWindowClick.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this, 0)}
        >
          <ScrollWindow showscrollIndicator={true}>{menuItems}</ScrollWindow>
        </div>
        <div
          ref={(i) => {
            this.shadowElement = i;
          }}
          className="cm-window-shadow"
          onMouseLeave={this.handleMouseLeave.bind(this, 0)}
        ></div>
      </React.Fragment>
    );
  }
}

export class ContextMenuList extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {
      openMenu: false,
    };
  }

  handleCloseMenu() {
    this.setState({ openMenu: false });
  }

  handleShowContextMenu(e) {
    const { value = null } = this.props;
    const {
      contextMenu,
      contextMenuName = null,
      contextMenuData = [],
      multiSelect = false,
      onSelect = () => {},
      selectedItems = [],
    } = this.props;

    if (contextMenuName != null) {
      const rect = ReactDOM.findDOMNode(this).getBoundingClientRect();
      // const selectedItems = [];
      let x = rect.x;
      this.setState({ openMenu: true });
      if (window.innerWidth - rect.x + 40 <= 150) {
        x = window.innerWidth - 220;
      }
      var minWidth = null;
      if (contextMenuName === "Composer") {
        x = x - 250;
        minWidth = 500;
      } else if (contextMenuName === "PaymentPreferenceAgreement") {
        clickOffsetX = rect.top;
      } else {
        clickOffsetX = this.contextOwner.offsetTop;
      }

      clickOffsetY = this.contextOwner.offsetLeft;

      showContextMenu(
        x,
        rect.y,
        contextMenuData,
        selectedItems,
        {
          multiSelect: multiSelect,
          owner: this.contextOwner,
          width: minWidth,
        },
        onSelect,
        null,
        this.handleCloseMenu.bind(this),
        null,
        contextMenuName
      );
    }
  }

  handleContextMenu(e) {
    if (e.target.className.indexOf && e.target.className.indexOf("toggle") !== -1) return;

    if (this.props.toggleOnClick) {
      this.props.onToggle(!this.props.toggled);
      return;
    }

    closeContextMenu();
    this.handleShowContextMenu(0);
  }

  handleMouseEnter(e) {
    closeContextMenu();
    this.handleShowContextMenu();
  }

  handleMouseLeave(e) {
    const target = e.relatedTarget;
    const context = document.getElementById("context-menu");
    const scrollbar1 = document.querySelector(".basic-search .scroll-window > div:last-child");
    const scrollbar2 = document.querySelector(".basic-search .scroll-window > div:last-child > div:first-child");

    // if we are NOT going to the context menu, close the context menu
    if (context && context instanceof Node) {
      if (!context.contains(target) && target !== scrollbar1 && target !== scrollbar2) {
        setTimeout(() => closeContextMenu(), 4000);
      }
    }
  }

  render() {
    const {
      title = "",
      icon = null,
      toggled = false,
      hideToggle = false,
      children = null,
      onToggle = () => {},
      selectElement = null,
    } = this.props;

    const sectionHeaderClass = classNames("section-header", { "open-menu": this.state.openMenu });

    const toggledIcon = toggled ? (
      <div className="basic-search-untoggled" style={{ width: 48, height: 48 }} />
    ) : (
      <div className="basic-search-untoggled" style={{ width: 48, height: 48 }} />
    );

    const sectionClass = classNames({
      section: true,
      "no-toggle": hideToggle,
    });

    const contentClass = classNames({
      "section-content": true,
      toggled: toggled || hideToggle,
    });

    const selectMenuIcon = <Flex className="font-icon icon-download-all playlist-icon"></Flex>;

    const sectionIcon = icon ? (
      <Flex className="section-icon">
        <FontIcon name={icon} />
      </Flex>
    ) : (
      ""
    );

    return (
      <div
        className={sectionClass}
        ref={(i) => {
          this.contextOwner = i;
        }}
      >
        <Flex
          row
          className={sectionHeaderClass}
          onClick={this.handleContextMenu.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
        >
          {sectionIcon}
          {/* <Flex>{selectMenuIcon}</Flex> */}
          <Flex>{selectElement}</Flex>
        </Flex>
        <Flex className={contentClass}>{children}</Flex>
      </div>
    );
  }
}

class SelectedItem extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const { index = null, item = null, label = null, onRemoveSelectedItem = () => {}, itemType = "" } = this.props;
    return itemType === "pills" ? (
      <div className="item" onClick={onRemoveSelectedItem.bind(this, index)}>
        <Flex position="left" className="ellipsis">
          {label}
        </Flex>
        <div className="close-icon">
          <FontIconButton name="close-thin" size={12} />
        </div>
      </div>
    ) : (
      <div>
        <div style={{ width: "99%" }} className="item" onClick={onRemoveSelectedItem.bind(this, index)}>
          <Flex position="left" className="ellipsis">
            {label}
          </Flex>
          <div className="close-icon">
            <FontIconButton name="close-thin" size={12} />
          </div>
        </div>
        <div className="clear"></div>
      </div>
    );
  }
}

export class SelectedItemsClearAll extends React.Component {
  constructor() {
    super(...arguments);
  }

  render() {
    const { onClearAllItems = () => {} } = this.props;

    return (
      <Flex row className="item-clear-all" onClick={onClearAllItems}>
        <Flex width="90%" position="right" className="ellipsis">
          Clear All
        </Flex>
        <Flex width="10%">
          <FontIconButton name="close-thin" size={12} />
        </Flex>
      </Flex>
    );
  }
}

export class GetSelectedItemsElement extends React.Component {
  render() {
    const {
      selectedItems = [],
      onRemoveSelectedItem = () => {},
      onClearAllItems = () => {},
      itemType = "",
    } = this.props;

    var items = "";
    if (selectedItems.items === undefined) return "";
    if (selectedItems.items.Label !== undefined) {
      items = (
        <SelectedItem
          key={0}
          index={0}
          label={selectedItems.items.Label}
          item={selectedItems.items}
          onRemoveSelectedItem={onRemoveSelectedItem}
          itemType={itemType}
        />
      );
    } else {
      items = selectedItems.items.map((m, i) => (
        <SelectedItem
          key={i}
          index={i}
          label={m.Label}
          item={m}
          onRemoveSelectedItem={onRemoveSelectedItem}
          itemType={itemType}
        />
      ));
    }

    //const clearAllButton = selectedItems.items.length > 1 ? (<SelectedItemsClearAll onClearAllItems={onClearAllItems} />) : '';

    return itemType == "pills" ? (
      <Flex className="pills-items">{items}</Flex>
    ) : (
      <Flex width="100%" className="selected-items">
        {/* { clearAllButton } */}
        {items}
      </Flex>
    );
  }
}
