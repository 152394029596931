export const UI_RESET = 'UI_RESET';
export const COMPOSER_USER = "COMPOSER_USER";

export const loginComposer = (login) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(login)
        }
        fetch(`/api/login`, content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    dispatch(composerUserDetailsInStateAction(json.userDetails));
                    resolve(json);
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}

export const logoutComposer = (logout) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(logout)
        }
        fetch(`/api/logout`, content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json)
                }
            }, () => {
                reject();
            }).catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}

export const resetPassword = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }
        fetch(`/api/reset-password`, content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                } else {
                    reject()
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}

export const resetPasswordModified = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(data)
        }
        fetch('api/reset-password-modified', content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                } else {
                    reject()
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}



export const resetPasswordExisting = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }

        fetch('/api/reset-password-existing-account', content)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json();
                    resolve(json);
                } else {
                    reject()
                }
            }, () => {
                reject();
            })
            .catch(err => {
                console.log('ERROR: ', err);
                reject();
            });
    });
}


export function composerUserDetailsInStateAction(composerUser) {
    return {
        type: COMPOSER_USER,
        composerUser
    }
}