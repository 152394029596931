import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./Cowriter.scss"
import Button from "../../ui/button/Button";
import Modal from "../../common/modal/Modal";
import NewCoWriterGroupModal from "./NewCoWriterGroupModal";
import { GetAllComposers, GetComposerGroups, GetPendingComposerGroups, getComposer, SetComposers } from "../../../store/composer/actions";

const CoWriters = (props) => {
  const dispatch = useDispatch();
  const [toggleNewCowriterModal, setToggleNewCowriterModal] = useState(false);
  const [groups, setGroups] = useState([]);
  const [composer, setComposer] = useState([])
  const [composers, setComposers] = useState([])
  const [pendingComposerGroups, setPendingGroups] = useState([])

  useEffect(() => {
    fetchCowriterGroup();
    fetchPendingCowriterGroups();
    fetchComposers();
  }, [])

  const fetchComposers = async () => {
    const composers = await dispatch(GetAllComposers());
    setComposers(composers);
  }

  const fetchPendingCowriterGroups = async () => {
    const primaryComposer = await dispatch(getComposer());
    const pendingComposerGroups = await dispatch(GetPendingComposerGroups(primaryComposer.Composer.Guid));
    setPendingGroups(pendingComposerGroups);
  }

  const fetchCowriterGroup = () => {
    dispatch(GetComposerGroups()).then(res => {
      if (res.Success) {
        setGroups(res.Composers)
      }
    }).catch(err => {
      console.log("ERROR: ", err)
    })
  }

  const handleModalClose = () => {
    setToggleNewCowriterModal(false)
  }

  const handleCowriterModalDone = (cowriters) => {
    setToggleNewCowriterModal(false)
    fetchCowriterGroup();
    fetchPendingCowriterGroups();
  }

  const handleCreateButtonClick = () => {
    dispatch(getComposer()).then(res => {
      if (res.Success) {
        setComposer({ ...res.Composer })
        setToggleNewCowriterModal(true)
      }
    }).catch(err => {
      console.log("ERROR: ", err)
    })
  }

  return (
    <div className="co-writers-container">
      <p className="title is-3">Co-Writers</p>
      <div className="subtitle-container">
        <p className="subtitle is-5">
          If you will be uploading approved tracks with co-writers, you need to create a "co-writer group" here. Upon upload you will be able to select the co-writer group for your tracks.
        </p>
      </div>
      <div className="goToButton-container-body">
        <Button
          title="+ CREATE NEW GROUP"
          buttonStyle="ready-btn"
          onClick={handleCreateButtonClick}
        />
      </div>
      <CoWritersGroups groups={groups} pendingGroups={pendingComposerGroups} />

      <Modal isVisible={toggleNewCowriterModal} onClose={handleModalClose}>
        <NewCoWriterGroupModal onClose={handleModalClose} composer={composer} composers={composers} onDone={handleCowriterModalDone} />
      </Modal>
    </div>
  )
}

const CoWritersGroups = ({ groups = [], pendingGroups = [] }) => {

  const pendingComposerGroups = pendingGroups.length > 0 
    ? (<div className='pending-cowriter-groups'>
          <div className='title'>YOUR PENDING CO-WRITER GROUPS:</div>
          <div className='sub-title'>Pending Co-Writer Groups will be available after they have been reviewed</div>
          <CoWriterGroupsName groups={pendingGroups} />
      </div>)
    : null;

  return (
    <div className="co-writer-group-container">
      <p className="title is-6">YOUR CO-WRITER GROUPS:</p>
      <CoWriterGroupsName groups={groups} />
      {pendingComposerGroups}
    </div>
  )
}

const CoWriterGroupsName = ({ groups = [] }) => {

  const mappedArray = groups.map(group => <p key={group.Guid} className="groups-name">{group.ComposerGroupName}</p>)

  return (
    <div>
      {mappedArray}
    </div>
  )
}




export default CoWriters;