import React, { Component } from 'react';
import classNames from 'classnames';
import Styles from './font-icon.scss';

export class FontIcon extends Component {
    constructor() {
        super(...arguments);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.name != nextProps.name ||
            this.props.color != nextProps.color ||
            this.props.size != nextProps.size ||
            this.props.style != nextProps.style ||
            this.props.className != nextProps.className
        )   
    }    

    render() {
        const {
            name = 'home',
            color = null,
            size = null,
            style = {},
            className = null,
            onClick = () => {}
        } = this.props;

        const iconName = 'icon-' + name;

        const iconStyle = Object.assign(style, {
            color: color,
            fontSize: size
        })

        const iconClass = classNames({
            'font-icon': true,
            [iconName]: true
        }, className);

        return (
            <div className={iconClass} style={iconStyle} onClick={onClick} />
        )
    }
}

export class FontIconButton extends Component {
    constructor() {
        super(...arguments);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.props.name != nextProps.name ||
            this.props.color != nextProps.color ||
            this.props.size != nextProps.size ||
            this.props.style != nextProps.style ||
            this.props.className != nextProps.className
        )   
    }     

    render() {
        const {
            name = 'home',
            color = null,
            size = null,
            style = {},
            className = null,
            onClick = () => {},
            onHover = () => {}
        } = this.props;

        const iconName = 'icon-' + name;

        const iconStyle = Object.assign(style, {
            color: color,
            fontSize: size
        })

        const iconClass = classNames({
            'font-icon': true,
            'font-button': true,
            [iconName]: true
        }, className);

        return (
            <div className={iconClass} style={iconStyle} onMouseOver={onHover.bind(this)} onClick={onClick.bind(this)} />
        )
    }
}