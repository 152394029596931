export const UPLOAD_NON_COMPOSER_REQUEST = "UPLOAD_NON_COMPOSER_REQUEST"
export const UPLOAD_NON_COMPOSER_SUCCESS = "UPLOAD_NON_COMPOSER_SUCCESS"
export const UPLOAD_NON_COMPOSER_FAILURE = "UPLOAD_NON_COMPOSER_FAILURE"

export const UPLOAD_COMPOSER_REQUEST = "UPLOAD_COMPOSER_REQUEST"
export const UPLOAD_COMPOSER_SUCCESS = "UPLOAD_COMPOSER_SUCCESS"
export const UPLOAD_COMPOSER_FAILURE = "UPLOAD_COMPOSER_FAILURE"

export const UPLOAD_APPROVED_TRACKS_REQUEST = "UPLOAD_APPROVED_TRACKS_REQUEST"
export const UPLOAD_APPROVED_TRACKS_SUCCESS = "UPLOAD_APPROVED_TRACKS_SUCCESS"
export const UPLOAD_APPROVED_TRACKS_FAILURE = "UPLOAD_APPROVED_TRACKS_FAILURE"

export const UPLOAD_SIGNATURE_REQUEST = "UPLOAD_SIGNATURE_REQUEST"
export const UPLOAD_SIGNATURE_SUCCESS = "UPLOAD_SIGNATURE_SUCCESS"
export const UPLOAD_SIGNATURE_FAILURE = "UPLOAD_SIGNATURE_FAILURE"

export const LOAD_COMPOSER = "LOAD_COMPOSER"
export const SET_COMPOSER = "SET_COMPOSER"
export const SET_COMPOSERS = "SET_COMPOSERS"

export function uploadNoncomposerRequest() {
    return { type: UPLOAD_NON_COMPOSER_REQUEST }
}

export function uploadNoncomposerSuccss(payload) {
    return {
        type: UPLOAD_NON_COMPOSER_SUCCESS,
        payload,
    }
}

export function uploadNoncomposerFailure(payload) {
    return {
        type: UPLOAD_NON_COMPOSER_FAILURE,
        payload,
    }
}

export const uploadNoncomposerTracks = (data, files) => (
    dispatch,
    getState
) => {
    dispatch(uploadNoncomposerRequest())
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append("name", data.name)
        formData.append("email", data.email)
        formData.append("website", data.website)
        formData.append("notes", data.notes)

        for (let index = 0; index < files.length; index++) {
            const element = files[index]
            formData.append("files", element)
        }

        const content = {
            method: "POST",
            body: formData,
        }

        handleFetch(`/api/composer/noncomposer/tracksubmission`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(uploadNoncomposerSuccss(json))
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                dispatch(uploadNoncomposerFailure(err))
                reject()
            })
    })
}

////Signature

export function uploadSignatureRequest() {
    return { type: UPLOAD_SIGNATURE_REQUEST }
}

export function uploadSignatureSuccss(payload) {
    return {
        type: UPLOAD_SIGNATURE_SUCCESS,
        payload,
    }
}

export function uploadSignatureFailure(payload) {
    return {
        type: UPLOAD_SIGNATURE_FAILURE,
        payload,
    }
}

export const uploadSignature = (data) => (dispatch, getState) => {
    dispatch(uploadSignatureRequest())
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data),
        }

        handleFetch(`/api/composer/upload-signature`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(uploadSignatureSuccss(json))
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                dispatch(uploadSignatureFailure(err))
                reject()
            })
    })
}

export const generateSignOnlinePdf = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data),
        }

        handleFetch(`/api/composer/generate-signOnline-pdf`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const generateSignSchedulePdf = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data),
        }

        handleFetch(`/api/composer/generate-signSchedule-pdf`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const showAgreementPdfFile = (fileName, signSchedule) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/signed/url/${fileName}/${signSchedule}`)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json()
                    resolve(json)
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const LoadAgreement = (guid) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/agreements/${guid}`)
            .then(async (response) => {
                if (response.ok) {
                    const json = await response.json()
                    resolve(json)
                }
            })
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const DownloadAgreement = (fileName, type) => (dispatch, getState) => {
    const link = document.createElement("a")
    link.href = `/api/composer/get/${fileName}/${type}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const DownloadComposerAgreement = (fileName) => (dispatch, getState) => {
    const composerName = fileName.split("/")[0]
    const scheduleFile = fileName.split("/").pop()

    const link = document.createElement("a")
    link.href = `/api/composer/get/agreement/${composerName}/${scheduleFile}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const DownloadComposerSchedule = (fileName) => async (
    dispatch,
    getState
) => {
    const composerName = fileName.split("/")[0]
    const scheduleFile = fileName.split("/").pop()

    const link = document.createElement("a")
    link.href = `/api/composer/get/schedule/${composerName}/${scheduleFile}`
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export function uploadComposerRequest() {
    return { type: UPLOAD_COMPOSER_REQUEST }
}

export function uploadComposerSuccss(payload) {
    return {
        type: UPLOAD_COMPOSER_SUCCESS,
        payload,
    }
}

export function uploadComposerFailure(payload) {
    return {
        type: UPLOAD_COMPOSER_FAILURE,
        payload,
    }
}

export const uploadComposerTracks = (data, files) => (dispatch, getState) => {
    dispatch(uploadComposerRequest())
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append("composerId", "")
        formData.append("submissionType", data.submissionType)
        formData.append("notes", data.notes)

        for (let index = 0; index < files.length; index++) {
            const element = files[index]
            formData.append("files", element)
        }

        const content = {
            method: "POST",
            credentials: "include",
            body: formData,
        }

        handleFetch(`/api/composer/tracksubmission`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(uploadComposerSuccss(json))
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                dispatch(uploadComposerFailure(err))
                reject()
            })
    })
}

export function uploadApprovedTracksRequest() {
    return { type: UPLOAD_APPROVED_TRACKS_REQUEST }
}

export function uploadApprovedTracksSuccss(payload) {
    return {
        type: UPLOAD_APPROVED_TRACKS_SUCCESS,
        payload,
    }
}

export function uploadApprovedTracksFailure(payload) {
    return {
        type: UPLOAD_APPROVED_TRACKS_FAILURE,
        payload,
    }
}

export const uploadApprovedTracks = (data, files) => (dispatch, getState) => {
    dispatch(uploadApprovedTracksRequest())
    return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append("composerid", "")
        formData.append("composerGroupId", data.composerGroupId)
        formData.append("submissionType", data.submissionType)
        formData.append("notes", data.notes)

        for (let index = 0; index < files.length; index++) {
            const element = files[index]
            formData.append("files", element)
        }

        const content = {
            method: "POST",
            body: formData,
        }

        handleFetch(`/api/composer/uploadapprovedtrack`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        // dispatch(uploadApprovedTracksSuccss(json))
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                // dispatch(uploadApprovedTracksFailure(err))
                reject()
            })
    })
}

export const SaveComposerAction = (composer) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                composer: composer,
            }),
        }
        handleFetch(`/api/composer/update`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        resolve(json)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const GetComposer = (guid) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/${guid}`)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(SetComposer(json.Composers))
                        resolve(json)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const SendAgreementEmailConfirmation = (guid) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/sendMailAgreement/${guid}`)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(SetComposer(json.Composers))
                        resolve(json)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const FetchEmailTemplates = (templateType) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/emailtemplate/${templateType}`)
            .then(
                async (response) => {
                    const json = await response.json()
                    dispatch(SetComposer(json.Composers))
                    resolve(json)
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const FetchSignOnlineTemplates = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                data: data,
            }),
        }
        handleFetch(`/api/composer/signOnline/template`, content)
            .then(
                async (response) => {
                    const json = await response.json()
                    resolve(json)
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const FetchSignScheduleTemplates = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                data: data,
            }),
        }
        handleFetch(`/api/composer/signSchedule/template`, content)
            .then(
                async (response) => {
                    const json = await response.json()
                    resolve(json)
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export function SetComposer(composer) {
    return {
        type: SET_COMPOSER,
        composer,
    }
}

export const GetAllComposers = () => (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
        const content = {
            method: "GET",
            credentials: "include",
        }

        try {
            const response = await handleFetch(
                `/api/composer/get/AllComposers`,
                content
            )

            if (response.ok) {
                const json = await response.json()
                dispatch(SetComposers(json.Composers))
                resolve(json.Composers)
            }
        } catch (ex) {
            console.log("ERROR: ", ex)
            reject(ex)
        }
    })
}

export const GetComposerGroups = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: "",
        }

        handleFetch(`/api/composer/get/ComposerGroups`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(SetComposers(json.Composers))
                        resolve(json)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const GetPendingComposerGroups = (primaryComposerGuid = null) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ primaryComposerGuid: primaryComposerGuid }),
        }

        handleFetch(`/api/composer/pendingComposerGroups`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()

                        if (!json.Success) {
                            reject(json.ErrorMessage || "")
                        }

                        resolve(json.ComposerGroups)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const getComposer = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "GET",
            credentials: "include",
        }

        handleFetch(`/api/composer/get/composer`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        // dispatch(SetComposers(json.Composer))
                        resolve(json)
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const SaveComposerGroup = (ComposersDetail) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(ComposersDetail),
        }

        handleFetch(`/api/composer/cowriter/savecomposer`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()

                        if (!json.Success) {
                            throw new Error(json.ErrorMessage)
                        }

                        resolve()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const removeApprovedTracks = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data),
        }

        handleFetch(`/api/composer/removeApprovedTracks`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        dispatch(uploadApprovedTracksSuccss(json))
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                reject()
            })
    })
}

export const updateApprovedTracksStatus = (data) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        const content = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(data),
        }

        handleFetch(`/api/composer/updateApprovedTracksStatus`, content)
            .then(
                async (response) => {
                    if (response.ok) {
                        const json = await response.json()
                        resolve(json)
                    } else {
                        reject()
                    }
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.error("updateApprovedTracksStatus ERROR: ", err)
                reject()
            })
    })
}

export const FetchComposersTracks = (composerGuid) => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/composersTracks/${composerGuid}`)
            .then(
                async (response) => {
                    const json = await response.json()
                    //dispatch(SetComposer(json.ComposersTracks))
                    resolve(json)
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export const FetchComposerDocuments = (composerGuid) => (
    dispatch,
    getState
) => {
    return new Promise((resolve, reject) => {
        handleFetch(`/api/composer/composersDocuments/${composerGuid}`)
            .then(
                async (response) => {
                    const json = await response.json()
                    resolve(json)
                },
                () => {
                    reject()
                }
            )
            .catch((err) => {
                console.log("ERROR: ", err)
                reject()
            })
    })
}

export function SetComposers(composers) {
    return {
        type: SET_COMPOSERS,
        composers,
    }
}
