import React from 'react';
import './Checkbox.scss';

const Checkbox = ({
    value = false,
    disabled = false,
    onChange = () => { },
}) => {
    return (
        <input className='checkbox-style' type='checkbox' onChange={(e) => onChange(e.target.checked)} checked={value} disabled={disabled} />
    )
}

export default Checkbox