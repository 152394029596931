import 'react-hot-loader';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';
import "./appearance/_main.scss"
import { logError } from './utils/logging';

import { createGlobalState } from 'react-hooks-global-state';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

window.State = createGlobalState({
    approvedTracks: [],
    uploadModalVisible: true,
    uploadModalTracks: [],
    showLogoutModal: false
});

// Load the CommonAPI for the Environment
const CommonAPI = require((process.env.API_CLASS)).default;
window.Common = new CommonAPI(store);

window.onerror = (message, source, lineNumber, colNumber, error) => {
    logError('Error',
        JSON.stringify({
            message: message,
            source: source,
            lineNumber: lineNumber,
            colNumber: colNumber,
            error: error,
            stack: error.stack
        })
    );

    return true;
}

window.handleFetch = (url, content) => {
    return new Promise((resolve, reject) => {
      fetch(url, content)
        .then(response => {
            // response only can be ok in range of 2XX
            if (response.ok) {
                // you can call response.json() here too if you want to return json
                resolve(response);
            } else {

            switch (response.status) {
                case 401:   // 401 Unauthorized
                    window.location.href = '/login';
                    alert("Your session has expired. Please login in again.");
                    break;
                default:
                    console.log('Some error occured');
                    break;
            }

                //here you also can thorow custom error too
                reject(response);
            }
        })
        .catch(error => {
            console.log(error);
            reject(error);
        })
    });
  }

ReactDOM.render(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
            <div className='app-root'>
                <App />
            </div>
        </Provider>
    </MuiPickersUtilsProvider>,
    document.getElementById('root')
);

if (module.hot)
    module.hot.accept();