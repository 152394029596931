import React, { Fragment } from 'react';
import "./Modal.scss"

const Modal = ({ isVisible, onClose, children }) => {

    if (!isVisible) return null

    return (
        <Fragment>
            <div className={"modal " + (isVisible ? "is-active" : "")}>
                <div className="modal-background" onClick={onClose}></div>
                <div className="modal-content modal-container">
                    {children}
                    <button className="button is-white close-button" aria-label="close" onClick={onClose}>X</button>
                </div>
            </div>
        </Fragment>
    )
}

export default Modal;