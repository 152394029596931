import React, { Fragment, useState, useEffect } from "react";
import "./ScheduleFinalPage.scss";
import Image from "../../../ui/image/Image";

import Done from "../../../../appearance/images/Done.png";
import { DownloadComposerSchedule, updateApprovedTracksStatus } from "../../../../store/composer/actions";
import { useDispatch } from "react-redux";

const ScheduleFinalPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    updateTrackStatus();
  })

  const [hasUpdatedTrackStatus, setHasUpdatedTrackState] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [messageHeader, setMessageHeader] = useState(null);
  const [message, setMessage] = useState(null);

  const readyToDownload = () => {
    dispatch(DownloadComposerSchedule(props.location.state.downloadSignScheduleFileName));
  };

  const updateTrackStatus = () => {
    if (!hasUpdatedTrackStatus) {
      const composerTrackIds = props.location.state.composerTracks.map(t => t.Id);
      dispatch(updateApprovedTracksStatus(composerTrackIds)).then(res => {
        setHasUpdatedTrackState(true);
        if (res.Success) {
          setMessageHeader(<span>This Schedule has been signed and filed.</span>)
          setMessage(<span>Your track(s) will soon be reviewed for deployment into the library.<br />  You will receive an automated email once your track(s) have been deployed and made live in the ScoreKeepers library.</span>)
        } else {
          throw new Error('Unable to update');
        }
      }).catch(error => {
        setHasError(true);
        setMessageHeader(<span>An error occurred while setting the status on the track.</span>)
        setMessage(<span>Please contact <a href='emailto:tech@scorekeepersmusic.com'>tech@scorekeepersmusic.com</a> for assistance.</span>);
      })
    }
  }

  const downloadLink = (!hasError && message)
    ? (
      <p className="download-link" onClick={readyToDownload}>
        Download Signed Schedule
      </p>)
    : null;

  return (
    <div className="schedule-final-page">
      <div className="image-center">
        <Image url={Done} />
      </div>
      <p>{messageHeader}</p>
      <p>{message}</p>
      {downloadLink}
    </div>
  );
};

export default ScheduleFinalPage;
