import React, { Fragment } from 'react';

const Image = ({
    url,
    imgClassName,
    width = "",
    height = "",
}) => {
    return (
        <Fragment>
            <img src={url} className={imgClassName} width={width} height={height} />
        </Fragment>
    )
}

export default Image;