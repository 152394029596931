import React, { useState, useEffect, useRef } from "react";
import Header from "../../../common/header/Header";
import TextInput from "../../../ui/text-input/TextInput";
import Button from "../../../ui/button/Button";
import { SaveComposerAction, SaveComposerGroup } from "../../../../store/composer/actions";
import { useDispatch } from "react-redux";
import "./CreateAccount.scss";
import Footer from "../../../common/footer/Footer";
import DatePicker from "react-date-picker";
import { ShowSuccessNotification, ShowErrorNotification } from "../../../ui/snackbar/Snackbar";
import { emailValidation } from "../../../../utils/validation";
import { loginComposer } from "../../../../store/user-interface/actions";
import { ContextMenuList, GetSelectedItemsElement } from "../../../ui/context-menu/context-menu";
import { LoadContextMenuData } from "../../../../store/tracks/actions";
import SelectContextComponents from '../../../ui/select/SelectContextComponent';
import Modal from "../../../common/modal/Modal";
import ConfirmationModal from "../../../common/modal/modal-content/confirmation-modal/ConfirmationModal";
import { KeyboardDatePicker } from "@material-ui/pickers";

const scrollToRef = (ref) => window.scrollTo(ref.current.offsetLeft, ref.current.offsetTop)

const CreateAccount = (props) => {
	const [firstName, setFirstName] = useState("");
	const [errorFirstName, setErrorFirstName] = useState(null);
	const [lastName, setLastName] = useState("");
	const [errorLastName, setErrorLastName] = useState(null);
	const [emailAddress, setEmailAddress] = useState("");
	const [errorEmailAddress, setErrorEmailAddress] = useState(null);
	const [birthDate, setBirthDate] = useState(null);
	const [errorBirthDate, setErrorBirthDate] = useState(null);
	const [address, setAddress] = useState("");
	const [errorAddress, setErrorAddress] = useState(null);
	const [city, setCity] = useState("");
	const [errorCity, setErrorCity] = useState(null);
	const [state, setState] = useState("");
	const [errorState, setErrorState] = useState(null);
	const [zipCode, setZipCode] = useState("");
	const [errorZipCode, setErrorZipCode] = useState(null);
	const [country, setCountry] = useState("");
	const [errorCountry, setErrorCountry] = useState(null);
	const [mainInstruments, setMainInstruments] = useState("");
	const [errorMainInstruments, setErrorMainInstruments] = useState(null);
	const [genre, setGenre] = useState("");
	const [errorGenre, setErrorGenre] = useState(null);
	const [pro, setPro] = useState("");
	const [errorPro, setErrorPro] = useState(null);
	const [proMembership, setProMembership] = useState("");
	const [errorProMembership, setErrorProMembership] = useState(null);
	const [ipiCae, setIpiCae] = useState("");
	const [errorIpiCae, setErrorIpiCae] = useState(null);
	const [userName, setUserName] = useState("");
	const [errorUserName, setErrorUserName] = useState("");
	const [password, setPassword] = useState("");
	const [errorPassword, setErrorPassword] = useState(null);
	const [phone, setPhone] = useState("");
	const [errorPhone, setErrorPhone] = useState(null);
	const [suffix, setSuffix] = useState("");
	const [website, setWebsite] = useState("");
	const [agentOrRepresentation, setAgentOrRepresentation] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [USPro, setUSPro] = useState("");
	const [selectedPaymentPreference, setSelectedPaymentPreference] = useState([])
	const [paymentPreferenceContextMenu, setPaymentPreferenceContextMenu] = useState([])
	const [ComposerPaymentMethod, setComposerPaymentMethod] = useState([])
	const [errorSelectedPaymentPreference, setErrorSelectedPaymentPreference] = useState([])
	const [PaypalEmailAddress, setPaypalEmailAddress] = useState("")
	const [errorPayPalEmailAddress, setErrorPayPalEmailAddress] = useState(null);
	const [genreContextMenu, setGenreContextMenu] = useState([])
	const [selectedGenre, setSelectedGenre] = useState(undefined)
	const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false)
	const [composerGenre, setComposerGenre] = useState([])

	const dispatch = useDispatch();
	const myRef = useRef(null)

	useEffect(() => {
		loadPaymentPreferenceContextMenuData();
		loadGenreContextMenuData();
	}, [])

	const loadGenreContextMenuData = () => {
		dispatch(LoadContextMenuData("Genre")).then(res => {
				setGenreContextMenu(res)
		})
	}

	const loadPaymentPreferenceContextMenuData = () => {
		dispatch(LoadContextMenuData("PaymentMethod")).then(res => {
			setPaymentPreferenceContextMenu(res)
		})
	}

	const handleValidateSubmit = () => {
		if (!firstName) setErrorFirstName("Firstname is required");

		//if (!lastName) setErrorLastName("Lastname is required");

		if (!emailAddress) setErrorEmailAddress("Email address is required");

		if (!emailAddress.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
			setErrorEmailAddress("Email address is not valid");
		// if (!birthDate) setErrorBirthDate("BirthDate is required");

		if (!address) setErrorAddress("Address is required");

		if (!city) setErrorCity("City is required");

		if (!state) setErrorState("State is required");

		if (!zipCode) setErrorZipCode("ZipCode is required");

		if (!country) setErrorCountry("Country address is required");

		if (!mainInstruments)
			setErrorMainInstruments("MainInstruments is required");

		//if (!genre) setErrorGenre("Genre is required");
		if (!composerGenre.ComposerGenre.length > 0) setErrorGenre("Genre is required");

		if (!pro) setErrorPro("Pro is required");

		// if (!proMembership) setErrorProMembership("ProMembership is required");

		if (!ipiCae) setErrorIpiCae("IpiCae is required");

		setErrorProMembership("");
		if (proMembership !== "") {
			if (proMembership == ipiCae)
			{
				setErrorProMembership("PRO Membership is required");
				setToggleConfirmationModal(true);
			}
		}

		// if (!userName) setErrorUserName("Username is required");

		if (!password) setErrorPassword("Password is required");

		if (!phone) setErrorPhone("Phone Number is required");

		if (!ComposerPaymentMethod.length > 0) setErrorSelectedPaymentPreference("Payment Preference is required");

		if (ComposerPaymentMethod.length > 0 && ComposerPaymentMethod[0].PaymentMethod === "PayPal" && !PaypalEmailAddress) setErrorPayPalEmailAddress("PayPal Email Address is required");

		if (ComposerPaymentMethod.length > 0 && ComposerPaymentMethod[0].PaymentMethod === "PayPal" && PaypalEmailAddress && !PaypalEmailAddress.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/))
			setErrorPayPalEmailAddress("Email address is not valid");

	};

	const ClearFieldsValue = () => {
		setFirstName('');
		setMiddleName('');
		setLastName('');
		setSuffix('');
		setEmailAddress('');
		setBirthDate('');
		setPhone('');
		setAddress('');
		setCity('');
		setState('');
		setZipCode('');
		setCountry('');
		setWebsite('');
		setAgentOrRepresentation('');
		setMainInstruments('');
		setGenre('');
		setPro('');
		setProMembership('');
		setUSPro('');
		setIpiCae('');
		setUserName('');
		setPassword('');
	}

	const readyToRock = () => {
		if (!password) {
			setErrorPassword("Password is required");
			myRef.current.scrollIntoView({ behavior: "smooth" });
			return;
		}

		handleValidateSubmit();
		if (
			errorFirstName === "" &&
			//errorLastName === "" &&
			errorEmailAddress === "" &&
			errorEmailAddress !== 'Email address is not valid' &&
			// errorBirthDate === "" &&
			errorAddress === "" &&
			errorCity === "" &&
			errorState === "" &&
			errorZipCode === "" &&
			errorCountry === "" &&
			errorMainInstruments === "" &&
			errorGenre === "" &&
			errorPro === "" &&
			(errorProMembership == null || errorProMembership === "") &&
			errorIpiCae === "" &&
			// errorUserName === "" &&
			errorPassword === "" &&
			errorPhone === "" &&
			(errorPayPalEmailAddress === null || errorPayPalEmailAddress === "")
			) {

				if (ComposerPaymentMethod[0].PaymentMethod === "PayPal" && !PaypalEmailAddress) {
					return
				}

				let birthdateVal = null;
				if (birthDate != null) {
					if (typeof birthdate === "string") {
						birthdateVal = new Date(birthDate);

						if (birthdateVal == "Invalid Date") {
							birthdateVal = null;
						}
					} else {
						birthdateVal = birthDate; //birthDate.getMonth() + 1 + "-" + birthdate.getDate() + "-" + birthDate.getFullYear();
					}
				}

				//const birthDateValue = new Date()

				const accountData = {
					FirstName: firstName,
					LastName: lastName,
					MiddleName: middleName !== null ? middleName : " ",
					LastName: lastName,
					SuffixName: suffix,
					PRO: pro,
					USPro: USPro,
					IpicaeNumber: ipiCae,
					ProMembershipNo: proMembership,
					EmailAddress: emailAddress,
					PhoneNumber: phone,
					Agent: agentOrRepresentation,
					WebsiteUrl: website,
					MainInstrument: mainInstruments,
					BirthDate: birthdateVal,
					MainGenre: genre,
					Username: userName,
					Password: password,
					MailingAddress: address,
					MailingCity: city,
					MailingCountry: country,
					MailingPostalCode: zipCode,
					MailingState: state,
					SendMail: false,
					ComposerPaymentMethod,
					PaypalEmailAddress,
					AgreementType: props.agreementId,
					AgreementDate: null,
					AllowNonExclusive: false,
					CustomCues: true,
					...composerGenre
			};

			dispatch(SaveComposerAction(accountData)).then(res => {
				if (res.Success) {
					// 05/19/2020 - Creating a Single Composer Group is now handled in the ComposerUser TRIGGER on the database.

					const loginDetail = { "username": accountData.EmailAddress, "password": accountData.Password, "rememberMe": false }
					dispatch(loginComposer(loginDetail)).then(resLogin => {
						if (resLogin.success) {
							// localStorage.setItem("username", res.Composer.FirstName + " " + res.Composer.MiddleName + " " + res.Composer.LastName)
							localStorage.setItem("username", res.Composer.FirstName)
							props.history.push('/submit-tracks');
						}
						else {
							ShowErrorNotification(res.errorMessage, 3000)
						}
					});
					ClearFieldsValue();
					props.username(res.Composer)
					props.gotoNextPage(true)
				} else {
					ShowErrorNotification(res.ErrorMessage, 3000);
				}
			})
		}
	};

	const getComposerGenreData = (composerGenreData) => {
    if (!composerGenreData || composerGenreData === 0) {
        return [];
    }
    return composerGenreData.map(x => ({ GenreId: x.Value.Id }))
  }

	const handleContextMenuSelect = (type, items = [], checked = true) => {
		if (type === 'Genre') {
			items = selectedGenre ?  [ ...selectedGenre.items, ...items ] : items;
      setSelectedGenre({ items });
      setComposerGenre({ ComposerGenre: getComposerGenreData( items )});
      items ? setErrorGenre("") : setErrorGenre("Genre is required");
    }
		else {
			var ComposerPaymentMethod = [{ Id: "0", ComposerPaymentMethodId: items.Value.Id + "", PaymentMethod: items.Value.Name }]
			setSelectedPaymentPreference({ items: [{ ...items }] })
			setComposerPaymentMethod(ComposerPaymentMethod)
			setPaypalEmailAddress(items.Value.Name !== "PayPal" ? "" : PaypalEmailAddress)
			items ? setErrorSelectedPaymentPreference("") : setErrorSelectedPaymentPreference("Payment Preference is required")
			setErrorPayPalEmailAddress(items.Value.Name !== "PayPal" ? "" : errorPayPalEmailAddress);
		}
	}

	const handleEmailChange = (value, type) => {
		if (type === "Email") {
			if (!emailValidation(value)) {
				setErrorEmailAddress("Email Address is not valid")
			} else {
				setErrorEmailAddress("")
			}
			setEmailAddress(value);
			setUserName(value);
		}

		else if (type === "PayPalEmail") {
			if (!emailValidation(value)) {
				setErrorPayPalEmailAddress("Email address is not valid")
			} else {
				setErrorPayPalEmailAddress("")
			}
			setPaypalEmailAddress(value);
		}
	}

	const handleModalClose = () => {
		setToggleConfirmationModal(false);
	}

	const handleRemoveSelectedItem = (type, index) => {
    switch (type) {
        case "Genre":
            const genre = { items: [...selectedGenre.items] }
            genre.items.splice(index, 1)
            setSelectedGenre({ items: [...genre.items] })
            break;

        default:
            break;
    }
	}

	const renderHeder = () => {

		if (props.showHeader === false) {
			return null
		} else {
			return (
				<div><Header title="NEW COMPOSER - CREATE ACCOUNT" /></div>
			)
		}
	}

	const renderFooter = () => {

		if (props.showFooter === false) {
			return null
		} else {
			return (
				<div><Footer /></div>
			)
		}
	}

	const { displayButton = true } = props

	return (
		<div className="create-account-container">
			{renderHeder()}
			<div className="create-account-container-body">
				<div className="create-account-body-parent">
					<div className="columns  mg-lft-rgt-1 ">
						<p className="column info">
							Please sign up using your name, exactly as it is registered with
							your PRO (performing rights organization).
            			</p>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="FIRST NAME:"
								type="text"
								value={firstName}
								onChange={value => {
									value ? setErrorFirstName("") : "";
									setFirstName(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorFirstName ? (
								<span className="error">{errorFirstName}</span>
							) : null}
						</div>
						<div className="column">
							<TextInput
								label="MIDDLE NAME:"
								type="text"
								value={middleName}
								onChange={value => {
									setMiddleName(value);
								}}
								labelClassName="label-style"
							/>
						</div>
						<div className="column">
							<TextInput
								label="LAST NAME:"
								type="text"
								value={lastName}
								onChange={value => {
									value ? setErrorLastName("") : "";
									setLastName(value);
								}}
								//isRequired={true}
								labelClassName="label-style"
							/>
							{errorLastName ? (
								<span className="error">{errorLastName}</span>
							) : null}
						</div>
						<div className="column">
							<TextInput
								label="SUFFIX:"
								type="text"
								value={suffix}
								onChange={value => {
									setSuffix(value);
								}}
								labelClassName="label-style"
								isDisabled={true}
							/>
						</div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="EMAIL ADDRESS"
								type="text"
								value={emailAddress}
								isRequired={true}
								labelClassName="label-style"
								onChange={(value) => handleEmailChange(value, "Email")}
							/>
							<div className="comment">(will also be your login username)</div>
							{errorEmailAddress ? (
								<span className="error">{errorEmailAddress}</span>
							) : null}{" "}
						</div>
						<div className="column is-3">
							<span className="label-style">BIRTH DATE:</span>
								<KeyboardDatePicker
									value={birthDate}
									onChange={date => {
										if (date == "Invalid Date") {
											setErrorBirthDate("Invalid Date")
											setBirthDate(null)
										}
										else {
											setErrorBirthDate("")
											setBirthDate(date)
										}
									}}
									onError={(value) => {} }
									format="MM/dd/yyyy"
									mask="__/__/____"
								/>
							{errorBirthDate ? (
								<span className="error">{errorBirthDate}</span>
							) : null}
						</div>
						<div className="column">
							<TextInput
								label="PHONE:"
								type="text"
								value={phone}
								onChange={value => {
									value ? setErrorPhone("") : "";
									setPhone(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorPhone ? (
								<span className="error">{errorPhone}</span>
							) : null}
						</div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="ADDRESS"
								type="text"
								value={address}
								onChange={value => {
									value ? setErrorAddress("") : null;
									setAddress(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorAddress ? (
								<span className="error">{errorAddress}</span>
							) : null}
						</div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="CITY/TOWN:"
								type="text"
								value={city}
								onChange={value => {
									value ? setErrorCity("") : null;
									setCity(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorCity ? <span className="error">{errorCity}</span> : null}
						</div>
						<div className="column">
							<TextInput
								label="STATE/REGION/PROVINCE:"
								type="text"
								value={state}
								onChange={value => {
									value ? setErrorState("") : null;
									setState(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorState ? <span className="error">{errorState}</span> : null}
						</div>
						<div className="column">
							<TextInput
								label="ZIP/POSTAL CODE:"
								type="text"
								value={zipCode}
								onChange={value => {
									value ? setErrorZipCode("") : null;
									setZipCode(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorZipCode ? (
								<span className="error">{errorZipCode}</span>
							) : null}
						</div>
						<div className="column">
							<TextInput
								label="COUNTRY:"
								type="text"
								value={country}
								onChange={value => {
									value ? setErrorCountry("") : null;
									setCountry(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorCountry ? (
								<span className="error">{errorCountry}</span>
							) : null}
						</div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="WEBSITE:"
								type="text"
								value={website}
								onChange={value => {
									setWebsite(value);
								}}
								labelClassName="label-style"
							/>
						</div>
						<div className="column">
							<TextInput
								label="AGENT/REPRESENTATION:"
								type="text"
								value={agentOrRepresentation}
								onChange={value => {
									setAgentOrRepresentation(value);
								}}
								labelClassName="label-style"
							/>
						</div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="MAIN INSTRUMENT(S) :"
								type="text"
								value={mainInstruments}
								onChange={value => {
									value ? setErrorMainInstruments("") : null;
									setMainInstruments(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorMainInstruments ? (
								<span className="error">{errorMainInstruments}</span>
							) : null}
						</div>
					  <div className="column">
              <div className="context-menu-div">
                  <p className="label-style payment-label"><span className="error">*</span>Genre(s):</p>
                  <ContextMenuList
                      title={"Genre"}
                      toggled={0}
                      contextMenuName={"Genre"}
                      contextMenuData={genreContextMenu}
                      multiSelect={true}
                      onSelect={(items) => handleContextMenuSelect("Genre", items)}
                      selectedItems={selectedGenre}
                      selectElement={<SelectContextComponents value="Select" width={200} />}
                  />
              </div>
              <div className="full-width context-selected-items-div">
                <GetSelectedItemsElement
                  selectedItems={selectedGenre}
                  onRemoveSelectedItem={(items) => handleRemoveSelectedItem("Genre", items)}
                  itemType="pills"
                />
              </div>
                 <span className="is-size-7">(Please select which music genres you are proficient in)</span>
                  {errorGenre ? (
                  <span className="error">{errorGenre}</span>
                  ) : null}
            </div>
					</div>
					<div className="columns  mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="PRO:"
								type="text"
								value={pro}
								onChange={value => {
									value ? setErrorPro("") : null;
									setPro(value);
									setUSPro((value.toUpperCase() === 'BMI' || value.toUpperCase() === 'SESAC') ? value.toUpperCase() : 'ASCAP');
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorPro ? <span className="error">{errorPro}</span> : null}
						</div>
						<div className="column">
							<TextInput
								label="U.S. PRO:"
								type="text"
								value={USPro}
								onChange={value => {
									setUSPro(value);
								}}
								labelClassName="label-style"
							/>
							<div className="comment">(authorized U.S. PRO if foreign composer)</div>
						</div>
						<div className="column">
							<TextInput
								label="PRO MEMBERSHIP OR ACCOUNT#:"
								type="text"
								value={proMembership}
								onChange={value => {
									//value ? setErrorProMembership("") : null;
									setProMembership(value);
								}}
								labelClassName="label-style"
							/>
							{/* {errorProMembership ? (
								<span className="error">{errorProMembership}</span>
							) : null} */}
						</div>
						<div className="column">
							<TextInput
								label="IPI/CAE#:"
								type="text"
								value={ipiCae}
								onChange={value => {
									value ? setErrorIpiCae("") : null;
									setIpiCae(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorIpiCae ? (
								<span className="error">{errorIpiCae}</span>
							) : null}
						</div>
					</div>

					<div className="columns  mg-lft-rgt-1">
						<div className="column">

							<div className="context-menu-div">
								<p className="label-style payment-label"><span className="error">*</span>PAYMENT PREFERENCE:</p>
								<ContextMenuList
									title={"Payment Preferences"}
									toggled={0}
									contextMenuName={"PaymentPreferenceAgreement"}
									contextMenuData={paymentPreferenceContextMenu}
									multiSelect={false}
									onSelect={(items) => handleContextMenuSelect("PaymentPreference", items)}
									selectElement={<SelectContextComponents value={selectedPaymentPreference && selectedPaymentPreference.items && selectedPaymentPreference.items.length > 0 ? selectedPaymentPreference.items[0].Label : "Select"} width={"50%"} />}
								/>
							</div>

							{errorSelectedPaymentPreference ? (
								<div className="error">{errorSelectedPaymentPreference}</div>
							) : null}{" "}

						</div>
						<div className="column">
							<TextInput
								label="Paypal Email Address"
								type="text"
								value={PaypalEmailAddress}
								labelClassName="label-style"
								onChange={(value) => handleEmailChange(value, "PayPalEmail")}
								isDisabled={!(selectedPaymentPreference && selectedPaymentPreference.items && selectedPaymentPreference.items.length > 0 && selectedPaymentPreference.items[0].Label === 'PayPal')}
							/>
							<span className="is-size-7">(If you selected PayPal as your Payment Preference)</span>
							{errorPayPalEmailAddress ? (
								<div className="error">{errorPayPalEmailAddress}</div>
							) : null}{" "}
						</div>
					</div>

					<div className="columns mg-lft-rgt-1">
						<div className="column">
							<TextInput
								label="USERNAME:"
								type="text"
								value={userName}
								onChange={value => {
									value ? setErrorUserName("") : null;
									setUserName(value);
								}}
								labelClassName="label-style"
								isDisabled={true}
							/>
							{/* {errorUserName ? (
								<span className="error">{errorUserName}</span>
							) : null} */}
						</div>
						<div className="column" ref={myRef}>
							<TextInput
								label="CREATE A PASSWORD:"
								type="password"
								value={password}
								onChange={value => {
									value ? setErrorPassword("") : null;
									setPassword(value);
								}}
								isRequired={true}
								labelClassName="label-style"
							/>
							{errorPassword ? (
								<span className="error">{errorPassword}</span>
							) : null}
						</div>
					</div>
					<div className={`${displayButton ? "button-container-body" : "button-container-body-none"}  is-3 mg-top-1`}>
						<Button
							title="READY TO ROCK"
							onClick={readyToRock}
							buttonStyle="green-large-button"
							id="new-composer-ready-to-rock"
						/>
					</div>
				</div>
			</div>
			{renderFooter()}

			<Modal isVisible={toggleConfirmationModal} onClose={handleModalClose}>
						<ConfirmationModal
								title="Wait!"
								message="PRO membership or account number is not the same as your IPI/CAE Number. If you do not know your PRO membership or account number please leave this field blank. We encourage you to update this information at a later time in your composer account."
								onConfirm={handleModalClose}
								isCancelButton="false"
						/>
				</Modal>

		</div>
	);
};

export default CreateAccount;
