import React, { Fragment } from 'react';
import "./Title.scss"

const Title = ({
    title,
}) => {
    return (
        <Fragment>
            <div className="title-container">
                <p className="title is-6">{title}</p>
            </div>
        </Fragment>
    )
}

export default Title;