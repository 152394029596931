import React, { Fragment } from 'react';

const Icon = ({
    iconContainerStyle,
    iconStyle,
    iconName,
}) => {
    return (
        <Fragment>
            <div className={iconContainerStyle}>
                <span className={iconStyle}>
                    <i className={iconName}></i>
                </span>
            </div>
        </Fragment>
    )
}

export default Icon;