import React, { Fragment } from "react";
import "./LogoutView.scss";


const LogoutView = ({
    name = "",
    onLogoutClick = () => {
       
     }
}) => {
    return (
        <Fragment>
            <div className="column logout-view-container">
                <div className="logout-view-container-body">
                    <span><p className="title is-5">{`Hi, ${name}!`}</p></span>
                    <button className="button is-small mg-lft-pt-5 logout-button" onClick={onLogoutClick}>Log out</button>
                </div>
            </div>
        </Fragment>
    )
}

export default LogoutView;