import React, { useEffect, useState } from "react";
import "./FAQ.scss"
import { useDispatch } from "react-redux"
import { FetchEmailTemplates } from "../../../../store/composer/actions"
import { ShowErrorNotification, ShowSuccessNotification } from 'components/ui/snackbar/Snackbar';

const FAQ = ({ }) => {
    const dispatch = useDispatch();
    const [emailTemplates, setEmailTemplates] = useState(undefined)

    useEffect(() => {
        getFaq();
    }, [])

    const getFaq = () => {
        dispatch(FetchEmailTemplates("faq")).then(res => {
            if (res.Success) {
                setEmailTemplates(res.EmailTemplates)
            } else {
                ShowErrorNotification("Unable to fetch FAQ", 3000)
            }
        })
    }

    const getHtmlData = () => {
        if (!emailTemplates || !emailTemplates.EmailDescription) {
            return null
        }

        let el = document.querySelector("#faq")
        el.innerHTML = null;
        let contentDiv = document.createElement('div');
        contentDiv.innerHTML = emailTemplates.EmailDescription
        el.appendChild(contentDiv)
    }

    return (
        <div>
            <h3 className="title is-3">FAQ</h3>
            <div className="faq" id="faq">
                {getHtmlData()}
            </div>
        </div>
    )
}

export default FAQ