import React, { Fragment } from "react";
import { BrowserRouter as Routers, Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux"

import "./Account.scss"
import Menu from "../../common/menu/Menu";
import ApprovedTrack from "./approved-tracks/ApprovedTracks";
import ContactUs from "./contant-us/ContactUs";
import Footer from "../../common/footer/Footer";
import SubmitTracks from "./submit-tracks/SubmitTracks";
import LogoutView from "../../common/logout-view/LogoutView";
import EditInfo from "./edit-info/EditInfo";
import FAQ from "./faq/FAQ";
import { logoutComposer } from "../../../store/user-interface/actions";
import ProductionGuidelines from "./production-guidelines/ProductionGuidelines";
import ChangePassword from "../change-password/ChangePassword";
import CoWriters from "../co-writers/CoWriters";
import CurrentMusicNeeds from "./current-music-needs/CurrentMusicNeeds";
import ComposerTracks from "../composer-tracks/ComposerTracks";
import ScheduleFinalPage from "./approved-tracks/ScheduleFinalPage";
import ComposerDocuments from "../composer-documents/ComposerDocuments";

const Account = (props) => {
    const dispatch = useDispatch();
    const username = localStorage.getItem("username")

    if (!username) {
        props.history.push("/login?path=" + props.location.pathname.replace("/", ""));
    }

    const handleLogout = () => {
        dispatch(logoutComposer()).then(res => {
            if (res.success) {
                localStorage.removeItem("username");
                localStorage.removeItem("composerId");
                props.history.push("/login");
            } else {
                // ShowErrorNotification(res.errorMessage,3000)
            }
        });
    };
    return (
        <Routers>
            <Fragment>
                <div className="account-container">
                    <div className="columns is-marginless account-menu">
                        <div className="is-paddingless menu-main-container">
                            <Menu />
                        </div>
                        <div className="column body-section is-marginless">
                            <LogoutView name={username} onLogoutClick={handleLogout} />
                            <div style={{ marginTop: 45, marginLeft: 50, marginRight: 50 }}>
                                <Switch>
                                    <Route exact path="/submit-tracks" component={SubmitTracks} />
                                    <Route exact path="/approved-tracks/schedule" component={ScheduleFinalPage} />
                                    <Route exact path="/approved-tracks" component={ApprovedTrack} />
                                    <Route exact path="/edit-info" component={EditInfo} />
                                    <Route exact path="/contact-us" component={ContactUs} />
                                    <Route exact path="/faq" component={FAQ} />
                                    <Route exact path="/current-music-needs" component={CurrentMusicNeeds} />
                                    <Route exact path="/production-guidelines" component={ProductionGuidelines} />
                                    <Route exact path="/change-password" component={ChangePassword} />
                                    <Route exact path="/co-writers" component={CoWriters} />
                                    <Route exact path="/composer-tracks" component={ComposerTracks} />
                                    <Route exact path="/composer-documents" component={ComposerDocuments} />
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </Fragment>
        </Routers>
    )
}

export default Account;
