import React, { Fragment } from 'react';
import "./FileSelector.scss"

const FileSelector = ({
    title,
    buttonStyle,
    onFileSelect = () => { },
}) => {

    const handleSelectFile = () => {
        document.getElementById("file").click()
    }

    return (
        <Fragment>
            <div className="file-select-container">
                <button className={"button " + buttonStyle} onClick={handleSelectFile}>{title}</button>
                <input type="file" id="file" onChange={onFileSelect} className="file-input" multiple />
            </div>
        </Fragment>
    )
}

export default FileSelector;