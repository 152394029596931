import React, { Fragment, useState } from 'react';
import "./ChangePassword.scss"
import Title from '../../ui/title/Title';
import TextInput from '../../ui/text-input/TextInput';
import Button from '../../ui/button/Button';
import Image from '../../ui/image/Image';
import logo from "../../../appearance/images/app-logo-medium.png"
import Footer from '../../common/footer/Footer';
import { useDispatch } from "react-redux";
import { ShowErrorNotification, ShowSuccessNotification } from '../../../components/ui/snackbar/Snackbar';

import { resetPasswordModified, resetPasswordExisting } from '../../../store/user-interface/actions';

const ChangePassword = (props) => {
    const { history } = props;
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const dispatch = useDispatch();
    const handleReset = () => {
        if (!password) {
            ShowErrorNotification("Please enter password", 3000);
            return;
        }

        if (!confirmPassword) {
            ShowErrorNotification("Please enter confirm password", 3000);
            return;
        }

        if (confirmPassword !== password) {
            ShowErrorNotification("Password and confirm password should be same", 3000);
            return;
        }

        let data = { password }

        if (props.showFooter === false) {
            data['Guid'] = props.composerGuid
            dispatch(resetPasswordExisting(data)).then(res => {
                if (res.Success) {
                    ShowSuccessNotification("Password reset successfully!", 3000);
                    props.gotonext(true)
                }
            })
        }
        else {
            dispatch(resetPasswordModified(data)).then(res => {
                if (res.Success) {
                    history.push('/submit-tracks');
                    ShowSuccessNotification("Password reset successfully!", 3000);
                }
                else {
                    ShowErrorNotification(res.errorMessage, 3000)
                }
            }).catch(err => {
                ShowErrorNotification("Password reset  failure", 3000)
            });
        }

    }

    const renderFooter = () => {

        if (props.showFooter === false) {
            return null
        } else {
            return (
                <div><Footer /></div>
            )
        }
    }

    const { isAccountSetupFlow = false } = props

    return (
        <div className="change-password-cotainer">
            {/* <div className="logo-section">
                <Image url={logo} imgClassName="" />
            </div> */}

            <div className="change-password-content-section">

                <div className="columns ">
                    <div className="column input-section">"
                        <p className="change-password-message">Create Your Password:</p>
                    </div>
                </div>

                <div className="columns ">
                    <div className="column input-section">
                        <TextInput label="PASSWORD:" type="password" value={password} onChange={(value) => { setPassword(value) }} />
                    </div>
                </div>
                <div className="columns ">
                    <div className="column input-section">
                        <TextInput label="CONFIRM PASSWORD:" type="password" value={confirmPassword} onChange={(value) => { setConfirmPassword(value) }} />
                    </div>
                </div>

                <div className="button-container-body horizontal-end">
                    <Button
                        title={isAccountSetupFlow ? "READY! GO TO STEP 2" : "RESET"}
                        onClick={handleReset}
                        buttonStyle={isAccountSetupFlow ? "go-to-next-button" : "blue-large-button"}
                        id="change-password-account-setup"
                    />
                </div>
            </div>
            {renderFooter()}
            {/* <Footer /> */}
        </div>
    )
}

export default ChangePassword;