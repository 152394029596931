import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import userInterfaceReducer from './user-interface/reducer'
import composerReducer from "./composer/reducer"

export const store = createStore(
    combineReducers({
        UserInterface: userInterfaceReducer,
        Composer: composerReducer,
    }),
    applyMiddleware(thunk),
    //
);

window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();