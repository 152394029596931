import React, { useState } from 'react';
import { useDispatch } from "react-redux";

import "./Login.scss"
import TextInput from '../../ui/text-input/TextInput';
import Button from '../../ui/button/Button';
import Image from '../../ui/image/Image';
import logo from "../../../appearance/images/app-logo-medium.png"
import Checkbox from '../../ui/checkbox/Checkbox';
import Footer from '../../common/footer/Footer';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../components/ui/snackbar/Snackbar';
import Modal from '../../common/modal/Modal';
import ForgotModal from '../../common/modal/modal-content/forgot-modal/ForgotModal'
import { loginComposer, resetPassword } from '../../../store/user-interface/actions';
import ForgotModalSuccess from '../../common/modal/modal-content/forgot-modal/ForgotPasswordSuccessModal';

const Login = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [isRememberMe, setIsRememberMe] = useState(false)
    const dispatch = useDispatch();
    const { history } = props;
    const [toggleForgotModal, setToggleForgotModal] = useState(false);
    const [toggleForgotModalSuccess, setToggleForgotModalSuccess] = useState(false);
    const [resetPasswordUsername, setResetPasswordUsername] = useState("")
    const [resetPasswordUsernameError, setResetPasswordUsernameError] = useState("")

    const handleLogin = () => {
        let loginDetail = { "username": email, "password": password, "rememberMe": isRememberMe }

            if(loginDetail.password === "") {
                 ShowErrorNotification("Please Enter Password", 3000)
                 return;
            }
            else if(loginDetail.username === ""){
                ShowErrorNotification("Please Enter Username", 3000)
                 return;
            }

        dispatch(loginComposer(loginDetail)).then(res => {
            if (res.success) {
                localStorage.setItem("username", res.userDetails.Fullname)
                localStorage.setItem("composerId", res.userDetails.ComposerGuid)
                localStorage.setItem("isPasswordModified", res.userDetails.IsPasswordModified)
                localStorage.setItem('allowNonExclusive', res.userDetails.AllowNonExclusive);

                if (props.location.search === "") {
                    history.push('/submit-tracks');
                }
                else {
                    const params = new URLSearchParams(props.location.search)
                    const path = params.get('path')
                    history.push('/' + path);
                }
            }
            else {
                ShowErrorNotification(res.errorMessage, 3000)
            }
        });
    }

    const handleRememberMe = () => {
    }

    const handleForgot = () => {
        setToggleForgotModal(!toggleForgotModal);
    }

    const handleEnterKeyUsername = (e) =>{
        if(e.which === 13){
            event.preventDefault();
            if(e.target.value ===''){
                 ShowErrorNotification("Please Enter Email Address", 3000)
                 return;
            }
            document.getElementById("password-field").focus()
        }
    }
    const handleEnterKeyLogin = (e) => {
        if(e.which === 13){
            event.preventDefault();
            handleLogin()
         }
    }

    const handleModalClose = () => {
        setToggleForgotModal(false);
        setToggleForgotModalSuccess(false);
        setResetPasswordUsername("")
    }

    const handleForgotPasswordUsernameChange = (value) => {
        if (!value) {
            setResetPasswordUsernameError("Please Enter Email Address");
        } else {
            setResetPasswordUsernameError("");
        }

        setResetPasswordUsername(value);
    }

    const handleResetPassword = () => {
        if (!resetPasswordUsername) {
            setResetPasswordUsernameError("Please Enter Email Address");
            return;
        }

        const data = {
            username: resetPasswordUsername
        }

        dispatch(resetPassword(data)).then(res => {
            if (res.Success) {
                setToggleForgotModal(false);
                setToggleForgotModalSuccess(true);
            } else {
                ShowErrorNotification("Unable to reset password", 3000);
            }
        }).catch(err => {
            ShowErrorNotification("Unable to reset password", 3000);
        });
    }

    const renderLogin = () => {
        return (
            <>
                { renderDownForMaintenance() }
                <p className="login-message">Welcome back! Please login to your account:</p>
                <div className="columns is-marginless">
                    <div className="column input-section">
                        <TextInput name='username' label="EMAIL ADDRESS (username):"  type="text" value={email} onChange={(value) => { setEmail(value) }} onKeyPress = { handleEnterKeyUsername } />
                    </div>
                    <div className="column input-section">
                        <TextInput name='password' label="PASSWORD:" id ="password-field" type="password" value={password} onChange={(value) => { setPassword(value) }} onKeyPress = { handleEnterKeyLogin } />
                    </div>
                </div>
                <div className="remember-me-section">
                    <div className="forgot-password-container">
                        <p className="forgot-password">
                            Forgot email address or password? <a onClick={handleForgot} >Click here</a>
                        </p>
                    </div>
                    <div className="remember-me-div">
                        <label>
                            Remember me?
                        </label>
                        <Checkbox onChange={(value) => { setIsRememberMe(value) }} value={isRememberMe} />
                    </div>
                    <Button title="ROCK ON!" onClick={handleLogin} buttonStyle="green-large-button" />
                </div>
            </>
        );
    }

    const renderDownForMaintenance = () => {
        return null;

        // return (            
        //     <div className='notice'>
        //         We are currently looking into an issue some users are having when trying to upload tracks.<br/>
        //         If you have any questions, please contact <a href="emailto:tech@scorekeepersmusic.com">tech@scorekeepersmusic.com</a>
        //     </div>
        // );
    }

    return (
        <div className="login-cotainer">
            <div className="logo-section">
                <Image url={logo} imgClassName="" />
            </div>

            <div className="login-content-section">
                {/* {renderDownForMaintenance()} */}
                {renderLogin()}
            </div>
            <Footer />

            <Modal isVisible={toggleForgotModal && !toggleForgotModalSuccess} onClose={handleModalClose}>
                <ForgotModal
                    onClick={handleResetPassword} username={resetPasswordUsername}
                    onUsernameChange={handleForgotPasswordUsernameChange}
                    usernameError={resetPasswordUsernameError}
                />
            </Modal>

            <Modal isVisible={!toggleForgotModal && toggleForgotModalSuccess} onClose={handleModalClose}>
                <ForgotModalSuccess onClick={() => setToggleForgotModalSuccess(false)} />
            </Modal>
        </div>
    )
}

export default Login;