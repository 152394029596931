import React, { Fragment, useState, useEffect } from 'react';
import "./NewComposerModal.scss"
import Title from '../../../../ui/title/Title';
import TextInput from '../../../../ui/text-input/TextInput';
import UploadComposer from '../../../upload-composer/UploadComposer';
import { useDispatch } from "react-redux"
import { uploadNoncomposerTracks } from '../../../../../store/composer/actions';
import { ShowErrorNotification } from "../../../../ui/snackbar/Snackbar"
import Spinner from '../../../../ui/spinner/Spinner';
import { emailValidation } from '../../../../../utils/validation';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
import Modal from '../../Modal';

const NewComposerModal = ({ onClose }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [website, setWebsite] = useState("")
    const [notes, setNotes] = useState("")
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [isShowMessage, setIsShowMessage] = useState(false)
    const [notesWordCount, setNotesWordCount] = useState(500)
    const [notesMaxLength, setNotesMaxLength] = useState(500)
    const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false)

    useEffect(() => {
        clearFields();
    }, [])


    const handleFileSelect = (e) => {
        let validFileNames = true;

        if (e.target.files) {
            const selectedfiles = e.target.files;
            const totalFileLenght = selectedfiles.length + files.length
            if (totalFileLenght > 3) {
                ShowErrorNotification("Maximum 3 tracks are allowed", 3000)
                return;
            }

            let isAudioFiles = true;
            for (let i = 0; i < selectedfiles.length; i++) {
                const file = selectedfiles[i];
                if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
                    isAudioFiles = false
                    break
                }
            }

            if (!isAudioFiles) {
                ShowErrorNotification("Mp3 files only (no zipped files or folders)", 3000)
                return
            }

            const tempFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                tempFiles.push(file)
            }

            for (let i = 0; i < selectedfiles.length; i++) {
                const file = selectedfiles[i];
                tempFiles.push(file)
            }

            // Filename validation. Check for special case characters.
            for (let i = 0; i < selectedfiles.length; i++) {
                if (selectedfiles[i].name.includes("/") || selectedfiles[i].name.includes("\\")
                || selectedfiles[i].name.includes("?") || selectedfiles[i].name.includes(":")) {
                    validFileNames = false;
                    break;
                }
            }

            if (!validFileNames) {
                setToggleConfirmationModal(true);
                return
            }
            
            setFiles(tempFiles)
        }
    }

    const clearFields = () => {
        setName("");
        setEmail("");
        setWebsite("");
        setNotes("");
        setFiles([]);
        clearInputFile();
    }

    const clearInputFile = () => {
        const file = document.getElementById("file")
        file.value = "";
    }

    const handleUpload = () => {
        if (!name) {
            ShowErrorNotification("Please enter name.", 3000)
            return
        }

        if (!email) {
            ShowErrorNotification("Please enter email.", 3000)
            return
        }

        if (!emailValidation(email)) {
            ShowErrorNotification("Please enter valid email.", 3000)
            return
        }

        if ((files && files.length === 0) && notes.trim() === "") {
            ShowErrorNotification("Please either select tracks or add links on notes section", 3000)
            return
        }


        const data = {
            name,
            email,
            website,
            notes
        }

        setIsLoading(true)

        dispatch(uploadNoncomposerTracks(data, files)).then(res => {
            setIsLoading(false)
            // ShowSuccessNotification("Upload successful!", 3000)
            clearFields();
            setIsShowMessage(true)
        }).catch(error => {
            setIsLoading(false)
            ShowErrorNotification("Unable to upload the tracks. Please try again", 3000)
        })
    }

    const handleNoteChange = (value) => {
        setNotes(value);
        setNotesWordCount(notesMaxLength - value.length);
    }

    const handleModalClose = () => {
        setToggleConfirmationModal(false);
    }

    return (
        <Fragment>
            <div className="new-composer-modal-cotainer">
                <Title title="NEW COMPOSER MUSIC SUBMISSION UPLOAD:" />
                <div className="mg-top-1 mg-lft-rgt-3">
                    <div className="columns is-marginless modal-note">
                        *Please use the Chrome browser, otherwise your upload may fail.
                    </div>
                    <div className="columns is-marginless">
                        <div className="column is-paddingless">
                            <TextInput value={name} label="Name:" type="text" onChange={(value) => {
                                setName(value)
                            }} />
                        </div>
                        <div className="column is-paddingless mg-lft-pt-5">
                            <TextInput value={email} label="Email:" type="email" onChange={(value) => {
                                setEmail(value)
                            }} />
                        </div>
                    </div>
                    <div className="columns is-marginless">
                        <div className="column is-9 is-paddingless mg-top-1">
                            <TextInput value={website} label="Website:" type="text" onChange={(value) => {
                                setWebsite(value);
                            }} />
                        </div>
                    </div>
                </div>
                <UploadComposer
                    files={files}
                    onUpload={handleUpload}
                    onFileSelect={handleFileSelect}
                    message="Up to 3 tracks - or provide streaming url below"
                    comment="(individual mp3 files only, no zipped files or folders.)"
                    fileSelectTitle="Select Tracks To Upload"
                    bottomNote1="*We will contact you via email after reviewing your submission(s)"
                    bottomNote2="Please do not contact ScoreKeepers unless you are experiencing technical issue with uploading."
                    note={notes}
                    notesWordCount={notesWordCount}
                    notesMaxLength={notesMaxLength}
                    onNoteChange={handleNoteChange}
                />
                {
                    isShowMessage ?
                        <div className="message-modal-cotainer">
                            <p className="text">Thanks for submitting!</p>
                            <p className="text">You will hear back from us soon!</p>
                        </div>
                        : null
                }
            </div>
            <Spinner isLoading={isLoading} />
            <Modal isVisible={toggleConfirmationModal} onClose={handleModalClose}>
                <ConfirmationModal
                    title="Warning!"
                    message="Upload file(s) without special case characters like /, \\, : or ?"
                    comment=""
                    onCancel={handleModalClose}
                    onConfirm={handleModalClose}
                    isCancelButton="false"
                />
            </Modal>

        </Fragment>
    )
}

export default NewComposerModal;