import React, { Fragment } from 'react';
import "./TextInput.scss"

const TextInput = ({
    id,
    label,
    name,
    onChange = () => { },
    value,
    type,
    labelClassName,
    inputClassName,
    isRequired = false,
    isDisabled = false,
    maxLength = -1,
    errorMsg = null,
    onBlur = () => { },
    onKeyPress = () => { },
    isBlack = false
}) => {
    return (
        <Fragment>
            <div className="text-input-container">
                {/* {isRequired ? <span className={isBlack ? "is-required-span-black" : "is-required-span"}>*</span> : null} */}
                <p className={labelClassName}>
                    {isRequired ? <span className={isBlack ? "is-required-span-black" : "is-required-span"}>*</span> : null}
                    {label}
                </p>
                <input
                    id ={id}
                    name={name}
                    type={type}
                    value={value}
                    className={"input " + inputClassName}
                    onChange={(e) => onChange(e.target.value)}
                    onBlur={(e) => onBlur(e.target.value)}
                    onKeyPress={(e) => onKeyPress(e)}
                    disabled={isDisabled}
                    maxLength={maxLength}
                />
            </div>
                {errorMsg ? (
                    <p className="input-error">{errorMsg}</p>
                ) : null}
        </Fragment>
    )
}

export default TextInput;