import React, { useState, useEffect } from "react"
import TextInput from "../../../ui/text-input/TextInput"
import Button from "../../../ui/button/Button"
import {
    SaveComposerAction,
    GetComposer,
} from "../../../../store/composer/actions"
import { useDispatch, useSelector } from "react-redux"
import "./EditInfo.scss"
import DatePicker from "react-date-picker"
import { ShowSuccessNotification } from "../../../ui/snackbar/Snackbar"
import { emailValidation } from "../../../../utils/validation"
import {
    ContextMenuList,
    GetSelectedItemsElement,
} from "../../../ui/context-menu/context-menu"
import { LoadContextMenuData } from "../../../../store/tracks/actions"
import SelectContextComponents from "../../../ui/select/SelectContextComponent"
import Modal from "../../../common/modal/Modal"
import PrivacyModal from "../../../common/modal/modal-content/privacy/Privacy"
import ConfirmationModal from "../../../common/modal/modal-content/confirmation-modal/ConfirmationModal"
import { KeyboardDatePicker } from "@material-ui/pickers"

const EditInfo = (props) => {
    const initialState = {
        FirstName: "",
        MiddleName: "",
        LastName: "",
        StageNickname: "",
        BirthDate: null,
        SuffixName: "",
        EmailAddress: "",
        PaypalEmailAddress: "",
        PhoneNumber: "",
        CellNumber: "",
        MailingAddress: "",
        MailingCity: "",
        MailingState: "",
        MailingCountry: "",
        MailingPostalCode: "",
        WebsiteUrl: "",
        Agent: "",
        MainInstrument: "",
        MainGenre: "",
        PRO: "",
        ProMembershipNo: "",
        USPro: "",
        IpicaeNumber: "",
        Password: "",
        ComposerPaymentMethod: [],
        ComposerGenre: [],
    }

    const [composer, setComposer] = useState(initialState)
    const [errorFirstName, setErrorFirstName] = useState(null)
    const [errorLastName, setErrorLastName] = useState(null)
    const [errorEmailAddress, setErrorEmailAddress] = useState(null)
    const [errorPayPalEmailAddress, setErrorPayPalEmailAddress] = useState(null)
    const [errorBirthDate, setErrorBirthDate] = useState(null)
    const [errorPhone, setErrorPhone] = useState(null)
    const [composerId, setComposerId] = useState(null)
    const [
        paymentPreferenceContextMenu,
        setPaymentPreferenceContextMenu,
    ] = useState([])
    const [selectedPaymentPreference, setSelectedPaymentPreference] = useState(
        []
    )
    const [
        errorSelectedPaymentPreference,
        setErrorSelectedPaymentPreference,
    ] = useState(null)
    const [errorMainInstruments, setErrorMainInstruments] = useState(null)
    const [errorGenre, setErrorGenre] = useState(null)
    const [errorIpicaeNumber, setErrorIpicaeNumber] = useState(null)
    const [pageTitle, setPageTitle] = useState("Edit/Update Your Information")
    const [buttonTitle, setButtonTitle] = useState("SAVE")
    const [toggleForgotModal, setToggleForgotModal] = useState(false)
    const [genreContextMenu, setGenreContextMenu] = useState([])
    const [selectedGenre, setSelectedGenre] = useState(undefined)
    const [toggleConfirmationModal, setToggleConfirmationModal] = useState(
        false
    )

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.accountSetupPage) {
            setComposerId(props.composerGuid)
            setPageTitle("Update Your Information")
            setButtonTitle("GO TO STEP 3")
        } else {
            setComposerId(localStorage.getItem("composerId"))
            setPageTitle("Edit/Update Your Information")
            setButtonTitle("SAVE")
        }
    }, [composerId])

    const loadGenreContextMenuData = () => {
        dispatch(LoadContextMenuData("Genre")).then((res) => {
            setGenreContextMenu(res)
        })
    }

    const fetchData = () => {
        if (!composerId) {
            return
        }
        dispatch(GetComposer(composerId)).then((res) => {
            let composer = res.Composers

            // Set the ProMembershipNo and BirthDate to empty strings if they are undefined
            composer.ProMembershipNo = composer.ProMembershipNo || ""
            composer.BirthDate = composer.BirthDate || ""

            setComposer(composer)
            setSelectedPaymentPreference({
                items: generatePaymentContextMenuData(
                    composer.ComposerPaymentMethod
                ),
            })
            setSelectedGenre({
                items: generateGenreContextMenuData(composer.ComposerGenre),
            })
        })
    }

    function generatePaymentContextMenuData(paymentMethods) {
        if (!paymentMethods || paymentMethods.length === 0) return []

        return paymentMethods.map((p) => ({
            Label: p.PaymentMethod,
            Value: { Id: p.ComposerPaymentMethodId, Name: p.PaymentMethod },
        }))
    }

    function generateGenreContextMenuData(composerGenre) {
        if (!composerGenre) return []

        return composerGenre.map((p) => ({
            Label: p.Genre,
            Value: { Id: p.GenreId, Name: p.Genre },
        }))
    }

    useEffect(() => {
        fetchData()
    }, [composerId])

    useEffect(() => {
        loadPaymentPreferenceContextMenuData()
        loadGenreContextMenuData()
    }, [])

    const loadPaymentPreferenceContextMenuData = () => {
        dispatch(LoadContextMenuData("PaymentMethod")).then((res) => {
            setPaymentPreferenceContextMenu(res)
        })
    }

    const handleValidateSubmit = () => {
        if (!composer.FirstName) setErrorFirstName("Firstname is required")

        //if (!composer.LastName) setErrorLastName("Lastname is required");

        if (!composer.EmailAddress)
            setErrorEmailAddress("Email address is required")

        if (
            !composer.EmailAddress.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )
        )
            setErrorEmailAddress("Email address is not valid")

        if (!composer.PhoneNumber) setErrorPhone("Phone is required")

        if (!composer.ComposerPaymentMethod.length > 0)
            setErrorSelectedPaymentPreference("Payment Preference is required")

        if (!composer.ComposerGenre.length > 0)
            setErrorGenre("Genre is required")

        if (!composer.MainInstrument)
            setErrorMainInstruments("Main instrumentation is required")

        if (
            composer.ComposerPaymentMethod.length > 0 &&
            composer.ComposerPaymentMethod[0].PaymentMethod === "PayPal" &&
            !composer.PaypalEmailAddress
        )
            setErrorPayPalEmailAddress("PayPal Email Address is required")

        if (
            composer.ComposerPaymentMethod.length > 0 &&
            composer.ComposerPaymentMethod[0].PaymentMethod === "PayPal" &&
            composer.PaypalEmailAddress &&
            !composer.PaypalEmailAddress.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            )
        )
            setErrorPayPalEmailAddress("Email address is not valid")

        if ((composer.IpicaeNumber || "").trim() == "")
            setErrorIpicaeNumber("IPI/CAE number is required")

        if (
            composer.ProMembershipNo.trim() != "" &&
            composer.ProMembershipNo.toLowerCase().trim() ==
                composer.IpicaeNumber.toLowerCase().trim()
        ) {
            setToggleConfirmationModal(true)
            return false
        }

        return true
    }

    const readyToRock = () => {
        if (handleValidateSubmit()) {
            submitForm()
        }
    }

    const submitForm = () => {
        if ((composer.Password || "") !== "") {
            const composerData = { ...composer }

            dispatch(SaveComposerAction(composerData)).then((res) => {
                ShowSuccessNotification("Account Updated", 3000)
                if (props.accountSetupPage) {
                    props.gotonext(true)
                    props.pageEditData(composer)
                }
            })

            return
        }

        if (
            (errorFirstName === null || errorFirstName === "") &&
            //(errorLastName === null || errorLastName === "") &&
            composer.EmailAddress &&
            composer.EmailAddress.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
            ) &&
            composer.PhoneNumber &&
            (errorPayPalEmailAddress === null ||
                errorPayPalEmailAddress === "") &&
            composer.ComposerPaymentMethod.length > 0 &&
            composer.ComposerGenre.length > 0 &&
            composer.MainInstrument
        ) {
            if (
                composer.ComposerPaymentMethod[0].PaymentMethod === "PayPal" &&
                !composer.PaypalEmailAddress
            ) {
                return
            }

            let bdate = ""
            let composerData = composer
            typeof (composer.BirthDate || "") === "string"
                ? (bdate = composer.BirthDate)
                : (bdate =
                      composer.BirthDate.getMonth() +
                      1 +
                      "/" +
                      composer.BirthDate.getDate() +
                      "/" +
                      composer.BirthDate.getFullYear())
            composerData.BirthDate = bdate
            composerData["SendMail"] = false
            composerData.ComposerGenre = getComposerGenreData(
                selectedGenre.items
            )

            dispatch(SaveComposerAction(composerData)).then((res) => {
                ShowSuccessNotification("Account Updated", 3000)
                if (props.accountSetupPage) {
                    props.gotonext(true)
                    props.pageEditData(composer)
                }
            })
        }
    }

    const getComposerGenreData = (composerGenreData) => {
        if (!composerGenreData || composerGenreData === 0) {
            return []
        }

        return composerGenreData.map((x) => ({ GenreId: x.Value.Id }))
    }

    const handleEmailChange = (value, type) => {
        if (type === "Email") {
            if (!emailValidation(value)) {
                setErrorEmailAddress("Email address is not valid")
            } else {
                setErrorEmailAddress("")
            }
            setComposer({ ...composer, EmailAddress: value })
        } else if (type === "PayPalEmail") {
            if (!emailValidation(value)) {
                setErrorPayPalEmailAddress("Email address is not valid")
            } else {
                setErrorPayPalEmailAddress("")
            }
            setComposer({ ...composer, PaypalEmailAddress: value })
        }
    }

    const handleContextMenuSelect = (type, items = [], checked = true) => {
        if (type === "Genre") {
            items = selectedGenre ? [...selectedGenre.items, ...items] : items
            items = [...new Set(items)]
            setSelectedGenre({ items })
            setComposer({
                ...composer,
                ComposerGenre: getComposerGenreData(items),
            })
            items ? setErrorGenre("") : setErrorGenre("Genre is required")
        } else {
            var ComposerPaymentMethod = [
                {
                    Id: "0",
                    ComposerPaymentMethodId: items.Value.Id + "",
                    PaymentMethod: items.Value.Name,
                },
            ]
            setSelectedPaymentPreference({ items: [{ ...items }] })
            setComposer({
                ...composer,
                ComposerPaymentMethod: ComposerPaymentMethod,
                PaypalEmailAddress:
                    items.Value.Name !== "PayPal"
                        ? ""
                        : composer.PaypalEmailAddress,
            })
            items
                ? setErrorSelectedPaymentPreference("")
                : setErrorSelectedPaymentPreference(
                      "Payment Preference is required"
                  )
            setErrorPayPalEmailAddress(
                items.Value.Name !== "PayPal" ? "" : errorPayPalEmailAddress
            )
        }
    }

    const handleRemoveSelectedItem = (type, index) => {
        switch (type) {
            case "Genre":
                const genre = { items: [...selectedGenre.items] }
                genre.items.splice(index, 1)
                setSelectedGenre({ items: [...genre.items] })
                break

            default:
                break
        }
    }

    const renderPrivacyLink = () => {
        return (
            <div className="privacy-link">
                <a onClick={handlePrivacyLink}>Privacy</a>
            </div>
        )
    }

    const handlePrivacyLink = () => {
        setToggleForgotModal(true)
    }

    const handleConfirmationModalClose = () => {
        setToggleConfirmationModal(false)
    }

    const handleConfirmationModalConfirm = () => {
        handleConfirmationModalClose()
        submitForm()
    }

    const handleModalClose = () => {
        setToggleForgotModal(false)
    }

    const { displayButton = true } = props
    return (
        <div className="edit-account-container">
            <div className="edit-title-container">
                <h3 className="title is-size-3 page-title">{pageTitle}</h3>
                <h6 className="title is-size-6 has-text-danger">
                    PLEASE USE YOUR COMPLETE NAME AS REGISTERED WITH YOUR PRO
                </h6>
                <span className="is-size-6">
                    *For accurate PRO reporting, please contact ScoreKeepers
                    should you need to change your First Name, Middle Name, Last
                    Name, or PRO information.
                </span>
            </div>
            <div
                className={`edit-account-container-body ${
                    props.accountSetupPage && "horizontal-center"
                }`}
            >
                <div className="edit-account-child-div">
                    {!props.accountSetupPage ? (
                        <div>
                            <div className="columns mg-lft-rgt-1">
                                <div className="column">
                                    <TextInput
                                        label="Change Your Password:"
                                        type="password"
                                        value={composer.Password}
                                        onChange={(value) => {
                                            //value ? setErrorPassword("") : null;
                                            composer.Password = value
                                            setComposer({
                                                ...composer,
                                                Password: value,
                                            })
                                        }}
                                        labelClassName="label-style"
                                    />
                                </div>
                            </div>
                            <hr className="horizontal-line" />
                        </div>
                    ) : null}
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="First Name:"
                                type="text"
                                value={composer.FirstName}
                                onChange={(value) => {
                                    value ? setErrorFirstName("") : ""
                                    //composer.FirstName = value
                                    setComposer({
                                        ...composer,
                                        FirstName: value,
                                    })
                                }}
                                isBlack={true}
                                isRequired={true}
                                labelClassName="label-style"
                                isDisabled={true}
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="Nickname/Stagename:"
                                type="text"
                                value={composer.StageNickname}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        StageNickname: value,
                                    })
                                }}
                                labelClassName="label-style"
                            />
                        </div>
                    </div>
                    <div className="columns mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Middle Name:"
                                type="text"
                                value={composer.MiddleName}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        MiddleName: value,
                                    })
                                }}
                                labelClassName="label-style"
                                isDisabled={true}
                            />
                        </div>
                        <div className="column">
                            <span className="label-style">Birth Date:</span>
                            <KeyboardDatePicker
                                value={composer.BirthDate}
                                onChange={(date) => {
                                    if (date == "Invalid Date") {
                                        setErrorBirthDate("Invalid Date")
                                        setComposer({
                                            ...composer,
                                            BirthDate: null,
                                        })
                                    } else {
                                        setErrorBirthDate("")
                                        setComposer({
                                            ...composer,
                                            BirthDate: date,
                                        })
                                    }
                                }}
                                onError={(value) => {}}
                                format="MM/dd/yyyy"
                                mask="__/__/____"
                                helperText=""
                            />
                            {errorBirthDate ? (
                                <span className="error">
                                    {composer.BirthDate == ""
                                        ? errorBirthDate
                                        : ""}
                                </span>
                            ) : null}
                        </div>
                    </div>
                    <div className="columns mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Last Name:"
                                type="text"
                                value={composer.LastName}
                                onChange={(value) => {
                                    value ? setErrorLastName("") : ""
                                    setComposer({
                                        ...composer,
                                        LastName: value,
                                    })
                                }}
                                isRequired={true}
                                isBlack={true}
                                labelClassName="label-style"
                                isDisabled={true}
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="Suffix:"
                                type="text"
                                value={composer.SuffixName}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        SuffixName: value,
                                    })
                                }}
                                labelClassName="label-style"
                                isDisabled={true}
                            />
                        </div>
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Email Address"
                                type="text"
                                value={composer.EmailAddress}
                                isRequired={true}
                                labelClassName="label-style"
                                onChange={(value) =>
                                    handleEmailChange(value, "Email")
                                }
                            />
                            {/* {
                !props.accountSetupPage && <span className="is-size-7 has-text-danger">(Updating email will also update your login username)</span>
              } */}
                            {errorEmailAddress ? (
                                <div className="error">{errorEmailAddress}</div>
                            ) : null}{" "}
                        </div>
                        {
                            // !props.accountSetupPage ?
                            //   <div className="column">
                            //     <TextInput
                            //       label="Paypal Email Address"
                            //       type="text"
                            //       value={composer.PaypalEmailAddress}
                            //       labelClassName="label-style"
                            //       onChange={(value) => handleEmailChange(value, "PayPalEmail")}
                            //     />
                            //     {errorPayPalEmailAddress ? (
                            //       <div className="error">{errorPayPalEmailAddress}</div>
                            //     ) : null}{" "}
                            //   </div>
                            // :
                            <div className="column">
                                <span className="is-size-7 has-text-danger">
                                    Updating your email address will also update
                                    your composer account login username
                                </span>
                            </div>
                        }
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Phone:"
                                type="text"
                                value={composer.PhoneNumber}
                                onChange={(value) => {
                                    value ? setErrorPhone("") : null
                                    setComposer({
                                        ...composer,
                                        PhoneNumber: value,
                                    })
                                }}
                                isRequired={true}
                                labelClassName="label-style"
                            />
                            {errorPhone ? (
                                <span className="error">{errorPhone}</span>
                            ) : null}
                        </div>
                        <div className="column">
                            <TextInput
                                label="Cell Phone:"
                                type="text"
                                value={composer.CellNumber}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        CellNumber: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Address"
                                type="text"
                                value={composer.MailingAddress}
                                onChange={(value) => {
                                    //value ? setErrorAddress("") : null;
                                    setComposer({
                                        ...composer,
                                        MailingAddress: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="City/Town:"
                                type="text"
                                value={composer.MailingCity}
                                onChange={(value) => {
                                    //value ? setErrorCity("") : null;
                                    setComposer({
                                        ...composer,
                                        MailingCity: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="State/Region/Province:"
                                type="text"
                                value={composer.MailingState}
                                onChange={(value) => {
                                    //value ? setErrorState("") : null;
                                    setComposer({
                                        ...composer,
                                        MailingState: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="Zip/Postal Code:"
                                type="text"
                                value={composer.MailingPostalCode}
                                onChange={(value) => {
                                    //value ? setErrorZipCode("") : null;
                                    setComposer({
                                        ...composer,
                                        MailingPostalCode: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="Country:"
                                type="text"
                                value={composer.MailingCountry}
                                onChange={(value) => {
                                    //value ? setErrorCountry("") : null;
                                    setComposer({
                                        ...composer,
                                        MailingCountry: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Website:"
                                type="text"
                                value={composer.WebsiteUrl}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        WebsiteUrl: value,
                                    })
                                }}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column">
                            <TextInput
                                label="Agent/Representation:"
                                type="text"
                                value={composer.Agent}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        Agent: value,
                                    })
                                }}
                                labelClassName="label-style"
                            />
                        </div>
                    </div>
                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <TextInput
                                label="Main Instrument(s) :"
                                type="text"
                                value={composer.MainInstrument}
                                onChange={(value) => {
                                    value ? setErrorMainInstruments("") : null
                                    setComposer({
                                        ...composer,
                                        MainInstrument: value,
                                    })
                                }}
                                isRequired={true}
                                labelClassName="label-style"
                            />
                            {errorMainInstruments ? (
                                <span className="error">
                                    {errorMainInstruments}
                                </span>
                            ) : null}
                        </div>
                        <div className="column">
                            <div className="context-menu-div">
                                <p className="label-style payment-label">
                                    <span className="error">*</span>Genre(s):
                                </p>
                                <ContextMenuList
                                    title={"Genre"}
                                    toggled={0}
                                    contextMenuName={"Genre"}
                                    contextMenuData={genreContextMenu}
                                    multiSelect={true}
                                    onSelect={(items) =>
                                        handleContextMenuSelect("Genre", items)
                                    }
                                    selectedItems={selectedGenre}
                                    selectElement={
                                        <SelectContextComponents
                                            value="Select"
                                            width={200}
                                        />
                                    }
                                />
                            </div>
                            <div className="full-width context-selected-items-div">
                                <GetSelectedItemsElement
                                    selectedItems={selectedGenre}
                                    onRemoveSelectedItem={(items) =>
                                        handleRemoveSelectedItem("Genre", items)
                                    }
                                    itemType="pills"
                                />
                            </div>
                            <span className="is-size-7">
                                (Please select which music genres you are
                                proficient in)
                            </span>
                            {errorGenre ? (
                                <span className="error">{errorGenre}</span>
                            ) : null}
                        </div>
                    </div>

                    <div className="columns  mg-lft-rgt-1">
                        <div className="column">
                            <div className="context-menu-div">
                                <p className="label-style payment-label">
                                    <span className="error">*</span>Payment
                                    Preference:
                                </p>
                                <ContextMenuList
                                    title={"Payment Preferences"}
                                    toggled={0}
                                    contextMenuName={"PaymentPreference"}
                                    contextMenuData={
                                        paymentPreferenceContextMenu
                                    }
                                    multiSelect={false}
                                    selectedItems={selectedPaymentPreference}
                                    onSelect={(items) =>
                                        handleContextMenuSelect(
                                            "PaymentPreference",
                                            items
                                        )
                                    }
                                    selectElement={
                                        <SelectContextComponents
                                            value={
                                                selectedPaymentPreference &&
                                                selectedPaymentPreference.items &&
                                                selectedPaymentPreference.items
                                                    .length > 0
                                                    ? selectedPaymentPreference
                                                          .items[0].Label
                                                    : "Select"
                                            }
                                            width={"50%"}
                                        />
                                    }
                                />
                            </div>
                            {errorSelectedPaymentPreference ? (
                                <span className="error">
                                    {errorSelectedPaymentPreference}
                                </span>
                            ) : null}
                        </div>
                        <div className="column">
                            <TextInput
                                label="Paypal Email Address"
                                type="text"
                                value={composer.PaypalEmailAddress}
                                labelClassName="label-style"
                                onChange={(value) =>
                                    handleEmailChange(value, "PayPalEmail")
                                }
                                isDisabled={
                                    !(
                                        selectedPaymentPreference &&
                                        selectedPaymentPreference.items &&
                                        selectedPaymentPreference.items.length >
                                            0 &&
                                        selectedPaymentPreference.items[0]
                                            .Label === "PayPal"
                                    )
                                }
                            />
                            <span className="is-size-7">
                                (If you selected PayPal as your Payment
                                Preference)
                            </span>
                            {errorPayPalEmailAddress ? (
                                <div className="error">
                                    {errorPayPalEmailAddress}
                                </div>
                            ) : null}{" "}
                        </div>
                    </div>

                    <hr className="horizontal-line" />
                    <div className="columns  mg-lft-rgt-1 ">
                        <div className="column margin-padding-zero column-1">
                            <TextInput
                                label="PRO:"
                                type="text"
                                value={composer.PRO}
                                onChange={(value) => {
                                    //value ? setErrorPro("") : null;
                                    setComposer({
                                        ...composer,
                                        PRO: value,
                                        USPro:
                                            value.toUpperCase() === "BMI" ||
                                            value.toUpperCase() === "SESAC"
                                                ? value.toUpperCase()
                                                : "ASCAP",
                                    })
                                }}
                                isRequired={false}
                                isDisabled={true}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column margin-padding-zero column-1">
                            <TextInput
                                label="U.S. PRO:"
                                type="text"
                                value={composer.USPro}
                                onChange={(value) => {
                                    setComposer({
                                        ...composer,
                                        USPro: value,
                                    })
                                }}
                                isDisabled={true}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column margin-padding-zero ">
                            <TextInput
                                label="PRO MEMBERSHIP OR ACCOUNT#:"
                                type="text"
                                value={composer.ProMembershipNo}
                                onChange={(value) => {
                                    //value ? setErrorProMembership("") : null;
                                    setComposer({
                                        ...composer,
                                        ProMembershipNo: value,
                                    })
                                }}
                                isRequired={false}
                                labelClassName="label-style"
                            />
                        </div>
                        <div className="column margin-padding-zero column-1">
                            <TextInput
                                label="IPI/CAE Number:"
                                type="text"
                                value={composer.IpicaeNumber}
                                onChange={(value) => {
                                    //value ? setErrorIpiCae("") : null;
                                    setComposer({
                                        ...composer,
                                        IpicaeNumber: value,
                                    })
                                }}
                                isRequired={true}
                                isDisabled={true}
                                labelClassName="label-style"
                            />
                            {errorIpicaeNumber ? (
                                <span className="error">
                                    {errorIpicaeNumber}
                                </span>
                            ) : null}
                        </div>
                    </div>

                    <div className="button-container-body is-3 mg-top-1 button-div-pd">
                        <div className="column has-text-danger has-text-weight-bold">
                            *Required Fields
                        </div>

                        <div className="button-container-body columns">
                            {renderPrivacyLink()}
                            <Button
                                title={buttonTitle}
                                onClick={readyToRock}
                                buttonStyle={
                                    buttonTitle === "SAVE"
                                        ? "green-large-button"
                                        : "go-to-next-button"
                                }
                                id="edit-info-account-setup"
                            />
                        </div>
                        {/* } */}
                    </div>
                </div>
            </div>

            <Modal isVisible={toggleForgotModal} onClose={handleModalClose}>
                <PrivacyModal />
            </Modal>

            <Modal
                isVisible={toggleConfirmationModal}
                onClose={handleConfirmationModalClose}
            >
                <ConfirmationModal
                    title="Wait!"
                    message="PRO membership or account number is not the same as your IPI/CAE Number. If you do not know your PRO membership or account number please leave this field blank. We encourage you to update this information at a later time in your composer account."
                    onConfirm={handleConfirmationModalConfirm}
                    isCancelButton="false"
                />
            </Modal>
        </div>
    )
}

export default EditInfo
