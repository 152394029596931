import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"

import "./ComposerTracks.scss"
import { FetchComposersTracks } from "../../../store/composer/actions";

const ComposerTracks = ({ }) => {
	const dispatch = useDispatch();
	const [composerTracks, setComposerTracks] = useState(null)
	const [filteredComposerTracks, setFilteredComposerTracks] = useState([])
	const [hasLoadedComposerTracks, setHasLoadedComposerTracks] = useState(false);

	useEffect(() => {
		if (!hasLoadedComposerTracks) {
			getComposerTracks();
		}
	}, [])

	const getComposerTracks = () => {
		const composerId = localStorage.getItem('composerId');
		dispatch(FetchComposersTracks(composerId)).then(res => {
			if (res.Success) {
				setComposerTracks(res.ComposersTracks);
				setFilteredComposerTracks(res.ComposersTracks);
			} else {
				ShowErrorNotification("Unable to fetch composer tracks", 3000)
			}
			setHasLoadedComposerTracks(true);
		})
	}

	const getFilteredComposerTracks = (value) => {
		if (value) {
			setFilteredComposerTracks(composerTracks.filter(x =>
				x.Title.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				x.OriginalTitle.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				x.Composers.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
				x.Status.toLowerCase().indexOf(value.toLowerCase()) !== -1
			));
		}
		else {
			setFilteredComposerTracks(composerTracks);
		}
	}

	const renderLoadingScreen = () => {
		return (
			<div className='is-loading'>
				<h3 className="title is-3">Loading Tracks ...</h3>
			</div>
		);
	}

	const renderNoTracksScreen = () => {
		return (
			<div style={{position:'relative'}}>
				<h3 className="title is-3">You currently have no active tracks with ScoreKeepers.</h3>
			</div>
		)
	}

	const renderTracksScreen = () => {
		return (
			<div style={{position:'relative'}}>
				<h3 className="title is-3">Your Tracks</h3>
				<div style={{paddingBottom: '25px'}}>
					<div style={{float: 'left', width: '20%', fontSize: '1.25rem', fontWeight: '600'}}>Total number of track(s): {filteredComposerTracks.length}</div>
						<div style={{float: 'left', width: '40%'}}>
							<input type="text" placeholder="Search track titles"
								style={{ marginLeft: '20px', width: '500px', height: '25px', border: '0', backgroundColor: '#dcdcdc', borderRadius: '2px', paddingLeft: '10px' }}
								onChange={(e) => getFilteredComposerTracks(e.target.value)}
							/>
						</div>
				</div>
				<div className="composer-tracks">
					<table id="main-table">
						<thead>
							<tr>
								<th style={{ width: '25%', textAlign: 'center' }}>ScoreKeepers Title</th>
								<th style={{ width: '25%', textAlign: 'center' }}>Original Title</th>
								<th style={{ width: '50%' }}>Composer(s)</th>
								{/* <th style={{ width: '10%' }}>Status</th> */}
							</tr>
						</thead>
						<tbody>
							{

								filteredComposerTracks.map(element =>
									<tr key={element.Id}>
										<td style={{ width: '25%', textAlign: 'left' }}>{element.Title}</td>
										<td style={{ width: '25%', textAlign: 'left' }}>{element.OriginalTitle}</td>
										<td style={{ width: '50%' }}>{element.Composers}</td>
										{/* <td style={{ width: '10%' }}>{element.Status}</td> */}
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	if (hasLoadedComposerTracks) {
		if (composerTracks.length > 0) {
			return renderTracksScreen();
		} else {
            return renderNoTracksScreen();
		}
	} else {
		return renderLoadingScreen();
	}
}

export default ComposerTracks