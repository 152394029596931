import * as Actions from './actions';

let defaultState = {
    isFetching: false,
    composer: []
}

const reducers = (state = defaultState, action) => {
    switch (action.type) {
        case Actions.UPLOAD_NON_COMPOSER_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }

        case Actions.UPLOAD_NON_COMPOSER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                nonComposerTracksSuccess: action.payload
            };
        }

        case Actions.UPLOAD_NON_COMPOSER_FAILURE: {
            return {
                ...state,
                isFetching: false,
                nonComposerTracksFailure: action.payload
            };
        }

        case Actions.UPLOAD_COMPOSER_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }

        case Actions.UPLOAD_COMPOSER_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                composerTracksSuccess: action.payload
            };
        }

        case Actions.UPLOAD_COMPOSER_FAILURE: {
            return {
                ...state,
                isFetching: false,
                composerTracksFailure: action.payload
            };
        }

        case Actions.UPLOAD_APPROVED_TRACKS_REQUEST: {
            return {
                ...state,
                isFetching: true
            };
        }

        case Actions.UPLOAD_APPROVED_TRACKS_SUCCESS: {
            return {
                ...state,
                isFetching: false,
                composerApprovedTracksSuccess: action.payload
            };
        }

        case Actions.UPLOAD_APPROVED_TRACKS_FAILURE: {
            return {
                ...state,
                isFetching: false,
                composerApprovedTracksFailure: action.payload
            };
        }

        case Actions.LOAD_COMPOSER: {
            return {
                ...state
            };
        }

        case Actions.SET_COMPOSER: {
            return {
                ...state,
                composer: action.composer
            };
        }

        case Actions.SET_COMPOSERS: {
            return {
                ...state,
                composers: action.composers
            };
        }

        default:
            return state;
    }
}

export default reducers;