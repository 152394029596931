import React, { Fragment, useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import "react-table-drag-select/style.css";
import { useDispatch } from "react-redux"

import "./ApprovedTracks.scss"
import UploadComposer from "../../../common/upload-composer/UploadComposer";
import Select from '../../../ui/select/Select';
import { FontIcon } from '../../../ui/font-icon/font-icon';
import { ShowErrorNotification, ShowSuccessNotification } from '../../../ui/snackbar/Snackbar';
import { uploadApprovedTracks, GetComposerGroups, removeApprovedTracks, setUploadApprovedTracksSuccess, setUploadApprovedTracksFailure } from '../../../../store/composer/actions';
import Button from '../../../ui/button/Button';
import Spinner from '../../../ui/spinner/Spinner';
import Image from "../../../ui/image/Image";
import MetaDataForm from './MetaDataForm';
import ConfirmationModal from '../../../common/modal/modal-content/confirmation-modal/ConfirmationModal';
import Modal from '../../../common/modal/Modal';
import SignSchedule from './SignSchedule';
import { Redirect } from 'react-router-dom';

const UPLOADING_QUEUED = 1;
const UPLOADING_PROCESSING = 2;
const UPLOADING_SUCCESS = 3;
const UPLOADING_ERROR = 4;
const UPLOADING_CANCELLING = 5;
const UPLOADING_CANCELLED = 6;

let isUploadCancelled = false;

Promise.each = async (arr, fn) => {
    for (const item of arr) await fn(item);
}

const ApprovedTrack = (props) => {
    const [selectedSubmissionType, setSelectedSubmissionType] = useState(undefined);
    const [notes, setNotes] = useState("")
    const [files, setFiles] = useState([]);
    const [filesName, setFilesName] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const [composerGuid, setComposerGuid] = useState("");
    const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
    const [selectedComposer, setSelectedComposer] = useState(undefined);
    const [composerOption, setComposerOption] = useState([]);
    const [downloadSignScheduleFileName, setDownloadSignScheduleFileName] = useState(undefined);
    const [composerTracks, setComposerTracks] = useState([])
    const [notesWordCount, setNotesWordCount] = useState(500)
    const [notesMaxLength, setNotesMaxLength] = useState(500)
    const [toggleNotificationModal, setToggleNotificationModal] = useState(false)
    const [isUploading, setIsUploading] = useState(false);
    const [tracksToUpload, setTracksToUpload] = useState([]);
    const [isErrorUploading, setIsErrorUploading] = useState(false);
    const [tracksErrors, setTracksErrors] = useState([]);
    const [toggleUploadNotificationModal, setToggleUploadNotificationModal] = useState(false);
    
    useEffect(() => {
        clearFields();
        loadComposerGroup();
    }, [])

    const handleOnSelect = (option) => {
        setSelectedSubmissionType(option)
    }

    const handleComposerOnSelect = (option) => {
        if (option.id === -1)
            return;

        setSelectedComposer(option)
    }

    const getFmtChunk = (data) => {
        let index = 12;
        let chunkId = new TextDecoder("utf-8").decode(new Uint8Array(result, startPos, 4));

        while (chunkId.toLowerCase() !== "fmt") {
            let chunkSizeArray = new Uint8Array(result, startPos + 4, 3);
            let chunkSize = chunkSizeArray[2] << chunkSizeArray[1] << chunkSizeArray[0];
            index += (chunkSize+4);
        }

        return "";
    }

    const isWaveFile = (file) => {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => {
                    const result = reader.result;
                    const chunkId = new TextDecoder("utf-8").decode(new Uint8Array(result, 0, 4));
                    const formatId = new TextDecoder("utf-8").decode(new Uint8Array(result, 8, 4));
                    let numChannels = 0;
                    let sampleRate = 0;
                    let bitsPerSample = 0;

                    if (chunkId == "RIFF" && formatId == "WAVE") {
                        let dataIndex = 12;
                        let dataChunkId = new TextDecoder("utf-8").decode(new Uint8Array(result, dataIndex, 4));
                        
                        while (dataChunkId.toLowerCase() !== "fmt ") {
                            let chunkSizeArray = new Uint8Array(result, dataIndex + 4, 4);
                            let chunkSize = (chunkSizeArray[3] << 24) + (chunkSizeArray[2] << 16) + (chunkSizeArray[1] << 8) + chunkSizeArray[0];
                            chunkSize += (chunkSize % 2);
                            dataIndex += (chunkSize+8);

                            if (dataIndex >= result.length) {
                                resolve(false);
                                return;
                            }

                            dataChunkId = new TextDecoder("utf-8").decode(new Uint8Array(result, dataIndex, 4));
                        }

                        numChannels = new Uint16Array(result, dataIndex + 10, 1)[0];
                        sampleRate = new Uint16Array(result, dataIndex + 12, 1)[0];
                        bitsPerSample = new Uint16Array(result, dataIndex + 22, 1)[0];
                    
                        if (chunkId !== "RIFF" || formatId !== "WAVE") {
                            reject(new Error(`${file.name} is not a valid WAV file.`));
                            return;
                        }
    
                        if (numChannels !== 2) {
                            reject(new Error(`${file.name} is ${numChannels}-channel and MUST be 2-channel.`));
                            return;
                        }
    
                        if (sampleRate !== 48000) {
                            reject(new Error(`${file.name} is ${sampleRate/1000}Khz and MUST be 48Khz.`));
                            return;
                        }
    
                        if (bitsPerSample !== 16) {
                            reject(new Error(`${file.name} is ${bitsPerSample}-bits and MUST be 16-bits `));
                            return;
                        }
                    }

                    const isValid = (chunkId == "RIFF" && formatId == "WAVE" && numChannels == 2 && sampleRate == 48000 && bitsPerSample == 16);

                    resolve(isValid);
                }
            } catch (ex) {
                reject(ex);
            }
        })
    }

    const handleFileSelect = async (e) => {
        let validFileNames = true;

        if (e.target.files) {
            const selectedfiles = e.target.files;

            let tempFiles = [];
            let tempFileName = [];
            let isAudioFiles = true;
            for (let i = 0; i < selectedfiles.length; i++) {
                try {
                    const file = selectedfiles[i];

                    const isValid = await isWaveFile(file);
                    if (!isValid) {
                        isAudioFiles = false;
                        break;
                    }

                    if (file.name.includes("/") || file.name.includes("\\") || file.name.includes("?") || file.name.includes(":"))
                    {
                        validFileNames = false;
                        break;
                    }

                    tempFiles.push(file);
                    tempFileName.push(file.name);
                } catch (e) {
                    ShowErrorNotification(e.message, 10000);
                    return;
                }
            }

            if (!isAudioFiles) {
                ShowErrorNotification("Wav format only, 48kHz, 16 bit files only (no zipped files or folders)", 6000);
                return;
            }

            if (!validFileNames) {
                setToggleNotificationModal(true);
                return;
            }

            setFiles(tempFiles);
            setFilesName(tempFileName);
        }
    }

    const clearFields = () => {
        setFiles([]);
        setNotes("");
        setSelectedSubmissionType(undefined);
        clearInputFile();
    }

    const clearInputFile = () => {
        const file = document.getElementById("file")
        if (file) {
            file.value = "";
        }
    }

    const handleUpload = async () => {
        setToggleUploadNotificationModal(true)
    }

    const handleCancelUpload = async () => {
        isUploadCancelled = true;
        setTracksToUpload([...tracksToUpload.map(x => {
            if (x.status === UPLOADING_QUEUED) {
                x.status = UPLOADING_CANCELLED;
            }
            return x;
        })]);
    }

    const goToNextStep = () => {
        setStep(step + 1)
    }

    const handleModalClose = () => {
        setToggleConfirmationModal(false);
    }

    const handleConfirmation = () => {
        // Remove the records from the table and also remove the tracks from S3
        const data = [];
        composerTracks.forEach(track => {
            data.push({
                "Id": track.Id,
                "SubmissionType": track.SubmissionType,
                "TrackTitle": track.TrackTitle,
                "PdfFileName": downloadSignScheduleFileName === undefined ? "" : downloadSignScheduleFileName

            });
        });

        dispatch(removeApprovedTracks(data)).then(res => {
            if (res.Success) {
                setToggleConfirmationModal(false);
                setStep(1);
                clearFields();
            }
        }).catch(error => {
            setIsLoading(false)
            ShowErrorNotification("Unable to remove the tracks. Please try again", 3000)
        });
    }

    const handleBackClick = () => {
        setToggleConfirmationModal(true)
    }

    function handleFileDelete(fileName) {
        const newFiles = [];
        const tempFileName = [];
        files.forEach(file => {
            if (file.name !== fileName) {
                newFiles.push(file);
                tempFileName.push({ Name: file.name })
            }
        })
        setFiles(newFiles);
        setFilesName(tempFileName);
    }

    const handleNoteChange = (value) => {
        setNotes(value);
        setNotesWordCount(notesMaxLength - value.length);
    }

    function getOptions() {
        let newOptions = [{ id: 0, label: "Exclusive" }];
        if (localStorage.getItem('allowNonExclusive') === 'true') {
            newOptions.push({ id: 1, label: "Non-Exclusive" });
        }
        newOptions.push({ id: 2, label: "Custom Cues" });
        return newOptions;
    }

    const handleNotificationClose = () => {
        setToggleNotificationModal(false);
    }

    const handleUploadNotificationCancel = () => {
        setToggleUploadNotificationModal(false);
    }
    
    const handleUploadNotificationOk = async () => {
        setToggleUploadNotificationModal(false);
        isUploadCancelled = false;

        if (!selectedSubmissionType) {
            ShowErrorNotification("Please select submission type.", 3000)
            return
        }

        if (!selectedComposer) {
            ShowErrorNotification("Please select a composer", 3000);
            return
        }

        if (files && files.length === 0) {
            ShowErrorNotification("Please select tracks", 3000)
            return
        }

        const data = {
            submissionType: selectedSubmissionType.label,
            composerGroupId: selectedComposer === undefined
                ? composerOption.length > 0 ? composerOption[0].Id : ""
                : selectedComposer.id
        }

        let tracksList = files.map(f => {
            return {
                file: f,
                status: UPLOADING_QUEUED
            };
        });

        setTracksToUpload(tracksList);
        setIsUploading(true);

        const setTrackStatus = (track, status) => {
            tracksList = tracksList.map(t => {
                if (t.file.name === track.file.name) {
                    t.status = status;
                }
                return t;
            });
            setTracksToUpload([...tracksList]);
        }

        const processRemoveTrack = async (track) => {
            setTrackStatus(track, UPLOADING_CANCELLING);

            // Delete the track from the system...
            const response = await dispatch(removeApprovedTracks([{
                Id: track.metaData.Id,
                SubmissionType: selectedSubmissionType.label,
                TrackTitle: track.TrackTitle
            }]));

            setTrackStatus(track, response.Success ? UPLOADING_CANCELLED : UPLOADING_ERROR);
        }

        const processCancellation = async () => {
            const tracksToProcess = tracksList.filter(x => x.status === UPLOADING_SUCCESS);
            await Promise.each(tracksToProcess, async (track) => {
                await processRemoveTrack(track);
            });
        }

        await Promise.each(tracksList, async (t) => {
            if (t.status !== UPLOADING_CANCELLED) {
                setTrackStatus(t, UPLOADING_PROCESSING);
                var response = await dispatch(uploadApprovedTracks(data, [t.file]));
                t.metaData = {...response.ComposerTracks[0], Notes: '', Filename: t.file.name};
                setTrackStatus(t, UPLOADING_SUCCESS);

                if (isUploadCancelled) {
                    processRemoveTrack(t);
                }
            }
        });

        if (!isUploadCancelled) {
            const composerTracks = tracksList.map(x => x.metaData);
            setIsUploading(false);
            setComposerGuid(composerTracks[0].ComposerGuid);
            setComposerTracks(composerTracks);
            goToNextStep();
        } else {
            await processCancellation();
        }
    }
    
    const renderUploadTrackComponents = () => {
        const uploadingDialog = isUploading
            ? renderUploadingModal()
            : null;

        const uploadingErrorDialog = isErrorUploading
            ? renderTrackUploadErrorModal()
            : null;

        return (
            <div className="upload-track-component">
                {uploadingDialog}
                {uploadingErrorDialog}
                <div>
                    <div className="row-container-row">
                        <h3 className="title is-3">Upload Approved Tracks</h3>
                    </div>
                    <div className="progress-img mg-top-1">
                        <Image
                            url={require("../../../../appearance/images/Composer-Uploads-Step1.png")}
                            height={200}
                            width={600}
                        />
                    </div>
                    <div className="mg-top-2">
                        <div className="composer-selection">
                            <Select
                                label="*What type of submission is this?"
                                onSelect={handleOnSelect}
                                selectedOption={selectedSubmissionType}
                                options={getOptions()}
                            />
                            <div className="composer-option ">
                                <Select
                                    label="Composer"
                                    onSelect={handleComposerOnSelect}
                                    selectedOption={selectedComposer}
                                    options={composerOption}
                                />
                            </div>
                        </div>
                        <p style={{paddingTop:10}}>
                            All submissions need to be for exclusive representation unless otherwise approved.  Contact anr@scorekeepersmusic.com
                        </p>

                        <UploadComposer
                            uploadButtonDivStyle="mg-lft-rgt-3"
                            message="UPLOAD ONLY APPROVED TRACK"
                            message={<div className='upload-message'>UPLOAD ONLY APPROVED TRACKS, PLEASE INCLUDE THE FULL OR 'A' VERSION OF ALL TRACKS. <span className='alert'>* Please use the Chrome browser, otherwise your upload may fail.</span></div>}
                            messageClassName="message-style"
                            comment="Wav format only, 48kHz, 16 bit files only (no zipped files or folders)"
                            fileSelectTitle="SELECT TRACKS"
                            bottomNote1="*if you have different submission types, please do so in separate uploads. For example, do not include exclusive and custom cue tracks in the same upload."
                            note={notes}
                            files={files}
                            onUpload={handleUpload}
                            onFileSelect={handleFileSelect}
                            isShowNote={false}
                            onFileDelete={handleFileDelete}
                            notesWordCount={notesWordCount}
                            notesMaxLength={notesMaxLength}
                            onNoteChange={handleNoteChange}
                        />
                    </div>
                </div>
            </div>
        )
    }

    const downloadSignScheduleAction = (fileName) => {
        setDownloadSignScheduleFileName(fileName)
    }

    const renderMetaDataForm = () => {
        return (
            <MetaDataForm
                onBackClick={handleBackClick}
                goToNext={goToNextStep}
                uploadedTracks={composerTracks}
                composerGuid={composerGuid}
                submissionType={selectedSubmissionType ? selectedSubmissionType.label : ""}
            />
        )
    }

    const renderSignScheduleForm = () => {
        return <SignSchedule
            submissionType={selectedSubmissionType ? selectedSubmissionType.label : ""}
            tracks={composerTracks}
            composerGuid={composerGuid}
            composer={selectedComposer}
            goToNext={goToNextStep}
            downloadFileName={downloadSignScheduleAction}
            onBackClick={handleBackClick}
        />
    }

    const renderScheduleFinalPage = () => {
        return (<Redirect to={{
            pathname: '/approved-tracks/schedule',
            state: { downloadSignScheduleFileName: downloadSignScheduleFileName, composerTracks: composerTracks }
        }
        }
        />)
    }

    const renderUploadingModal = () => {
        const errorTracks = tracksToUpload.filter(x => x.status === UPLOADING_ERROR);
        const successTracks = tracksToUpload.filter(x => x.status === UPLOADING_SUCCESS);
        const cancelledTracks = tracksToUpload.filter(x => x.status === UPLOADING_CANCELLED);

        const isFinished = (errorTracks.length + successTracks.length + cancelledTracks.length) === tracksToUpload.length;
        const isCancelling = (isUploadCancelled && tracksToUpload.filter(x => x.status === UPLOADING_CANCELLED).length !== tracksToUpload.length);

        const getStatus = (status) => {
            switch (status) {
                case isUploadCancelled && UPLOADING_PROCESSING: return (<img className='upload-cancelling-animation' alt="Cancelling" />);
                case isUploadCancelled && UPLOADING_SUCCESS: return 'Cancelling';
                case UPLOADING_QUEUED: return 'Queued';
                case UPLOADING_PROCESSING: return (<img className='upload-processing-animation' alt="Processing" />);
                case UPLOADING_SUCCESS: return 'Done';
                case UPLOADING_ERROR: return 'Error';
                case UPLOADING_CANCELLING: return (<img className='upload-cancelling-animation' alt="Cancelling" />);
                case UPLOADING_CANCELLED: return 'Cancelled';
            }
        }

        const rows = tracksToUpload.map((t, i) => {
            return (
                <tr key={i} className={`status-${t.status}`}>
                    <td width={'80%'}>{t.file.name}</td>
                    <td>{(t.file.size / 1024 / 1024).toFixed(2)} MB</td>
                    <td>{getStatus(t.status)}</td>
                </tr>
            )
        });

        const handleCloseDialog = () => {
            setTracksToUpload([]);
            setComposerTracks([]);
            setIsUploading(false);
        }

        const dialogTitle = !isUploadCancelled
            ? 'UPLOADING TRACKS'
            : isCancelling
                ? 'CANCELLING UPLOAD...'
                : 'UPLOAD CANCELLED';

        const continueButton = isFinished && tracksToUpload.filter(x => x.status === UPLOADING_SUCCESS).length > 0
            ? <Button onClick={() => { goToNextStep() }} title={'Continue'} />
            : null;

        const cancelButton = isFinished && tracksToUpload.filter(x => x.status === UPLOADING_CANCELLED).length > 0
            ? <Button onClick={() => { handleCloseDialog() }} title={'Close'} />
            : <Button disabled={isCancelling} onClick={() => { handleCancelUpload() }} title={(isCancelling ? 'Cancelling' : 'Cancel')} />;

        return (
            <div className='uploading-modal'>
                <div className='uploading-modal-dialog'>
                    <div className='uploading-modal-header'>
                        <div className='uploading-modal-title'>
                            {dialogTitle}
                        </div>
                    </div>
                    <div className='uploading-modal-counts'>
                        <span>Processed: {successTracks.length + errorTracks.length + cancelledTracks.length} / {tracksToUpload.length}</span>
                        <span>Success: {successTracks.length}</span>
                        <span>Errors: {errorTracks.length}</span>
                        <span>Cancelled: {cancelledTracks.length}</span>
                    </div>
                    <div className='uploading-modal-content'>
                        <table className='uploading-modal-track-table'>
                            <thead>
                                <tr>
                                    <td>Filename</td>
                                    <td>Size</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                    <div className='uploading-modal-footer'>
                        {continueButton}
                        {cancelButton}
                    </div>
                </div>
            </div>
        )
    }

    const renderTrackUploadErrorModal = () => {
        const handleCloseDialog = () => {
            setIsErrorUploading(false);
        }

        const rows = tracksErrors.filter(x => x.Success === false).map((t, i) => {
            return (
                <tr key={i} className="status-4">
                    <td style={{width: '60%'}}>{t.Title}</td>
                    <td>{t.Message}</td>
                </tr>
            )
        });

        return (
            <div className='uploading-modal'>
                <div className='uploading-modal-dialog'>
                    <div className='uploading-modal-header'>
                        <div className='uploading-modal-title'>
                            Please resolve the below file upload error(s):
                        </div>
                    </div>
                    <div className='uploading-modal-content'>
                        <table className='uploading-modal-track-table'>
                            <thead>
                                <tr>
                                    <td style={{width: '60%'}}>Filename</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {rows}
                            </tbody>
                        </table>
                    </div>
                    <div className='uploading-modal-footer'>
                        <Button onClick={() => { handleCloseDialog() }} title={'Close'} />
                    </div>
                </div>
            </div>
        )
    }

    const renderComponents = (value) => {
        switch (value) {
            case 1:
                return renderUploadTrackComponents()

            case 2:
                return renderMetaDataForm()

            case 3:
                return renderSignScheduleForm()

            case 4:
                return renderScheduleFinalPage()

            default:
                return renderUploadTrackComponents()
        }
    }

    const loadComposerGroup = () => {
        dispatch(GetComposerGroups()).then(res => {
            const composerList = res.Composers.map(a => {
                return {
                    id: a.Id,
                    label: a.ComposerGroupName,
                    guid: a.Guid
                }
            });

            composerList.push({
                id: -1,
                label: (<NavLink to="/co-writers">Create new Composer Group / adjust percentages...</NavLink>)
            });

            setComposerOption(composerList);

            if (res.Composers.length === 2) {
                let data = {
                    id: res.Composers[0].Id,
                    label: res.Composers[0].ComposerGroupName,
                    guid: res.Composers[0].Guid

                }
                setSelectedComposer(data)
            }
        })
    }

    return (
        <div className="approved-tracks-container">
            {renderComponents(step)}
            <Spinner isLoading={isLoading} />
            <Modal isVisible={toggleConfirmationModal} onClose={handleModalClose}>
                <ConfirmationModal
                    title="Warning!"
                    message="Do you want to go back?"
                    comment="You will loose your changes."
                    onConfirm={handleConfirmation}
                    onCancel={handleModalClose}
                />
            </Modal>

            <Modal isVisible={toggleNotificationModal} onClose={handleNotificationClose}>
                <ConfirmationModal
                    title="Warning!"
                    message="Upload file(s) without special case characters like /, \\, : or ?"
                    comment=""
                    onCancel={handleNotificationClose}
                    onConfirm={handleNotificationClose}
                    isCancelButton="false"
                />
            </Modal>

            <Modal isVisible={toggleUploadNotificationModal} onClose={handleUploadNotificationCancel}>
                <ConfirmationModal
                    title="Upload Approved Tracks"
                    comment='Please only upload tracks that have been approved by ScoreKeepers for Final Upload.  If you are a new contributor, your initial audition submission tracks were not considered "approved", please submit all tracks from your Composer Account on the "Submit Tracks For Audition" page.  Thanks!'
                    anotherComment='Please have your final upload track(s) match the same filename as your submission mp3.'
                    onConfirm={handleUploadNotificationOk}
                    onCancel={handleUploadNotificationCancel}
                    isCancelButton="true"
                />
            </Modal>
        </div>
    )
}

export default ApprovedTrack;