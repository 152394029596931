import React from 'react';
import { BrowserRouter as Routers, Route, Switch, useParams } from "react-router-dom";
import { hot } from 'react-hot-loader/root';

import './appearance/App.scss';
import Home from './components/views/home/Home';
import CreateAccount from './components/views/account/create-account/CreateAccount';
import Account from './components/views/account/Account';
import Login from './components/views/login/Login';
import ChangePassword from './components/views/change-password/ChangePassword';
import Agreement from './components/views/agreement/Agreement';
import AccountSetup from './components/views/account-setup/AccountSetup';
import ComposerTracks from './components/views/composer-tracks/ComposerTracks';
import ComposerDocuments from './components/views/composer-documents/ComposerDocuments';

const AgreementRoot = (props) => {
    return <Routers>
        <div>Agreement</div>
        <Switch>
            <Route path="/agreement/:test?" component={Home}></Route>
            <Route exact path="/agreement" component={Agreement}></Route>
        </Switch>
    </Routers>
}

const App = () => {
    return (
        <Routers>
            <div className='main-page'>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/new-composer" component={CreateAccount} />
                    <Route path="/edit-info" component={Account} />
                    <Route path="/submit-tracks" component={Account} />
                    <Route path="/approved-tracks" component={Account} />
                    <Route path="/faq" component={Account} />
                    <Route path="/current-music-needs" component={Account} />
                    <Route path="/production-guidelines" component={Account} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/agreement/:token?/:token2?" component={Agreement} />
                    <Route path="/co-writers" component={Account} />
                    <Route exact path="/" component={Home} />
                    <Route path="/composer/:token" component={AccountSetup} />
                    <Route path="/composer-tracks" component={ComposerTracks} />
                    <Route path="/composer-documents" component={ComposerDocuments} />
                </Switch>
                <div className='notification-center'></div>
            </div>
        </Routers>
    )
}

export default hot(App);