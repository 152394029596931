import React, { Fragment, useState, useEffect } from "react";
import Header from "../../common/header/Header";
import Footer from "../../common/footer/Footer";
import Button from "../../ui/button/Button";
import Image from "../../ui/image/Image";
import { useDispatch } from "react-redux";
import "./Agreement.scss";
import Review from "./review/Review";
import CreateAccount from "../account/create-account/CreateAccount";
import AdoptSignature from "./adoptSignature/AdoptSignature";
import SignOnline from "./signOnline/SignOnline";
import { showAgreementPdfFile, DownloadAgreement, uploadSignature, LoadAgreement, GetComposer } from "../../../store/composer/actions";
import FinalPage from "./finalPage/FinalPage";
import { ShowErrorNotification } from "../../ui/snackbar/Snackbar";
import Modal from "../../common/modal/Modal";
import PrivacyModal from "../../common/modal/modal-content/privacy/Privacy";

const Agreement = ({ match }) => {
  const dispatch = useDispatch();
  const [btn1Title, setBtn1Title] = useState("READY! GO TO STEP 2");
  const [btn2Title, setBtn2Title] = useState("DOWNLOAD AGREEMENT");
  const [step, setSteps] = useState(1);
  const [agreementUrl, setAgreementUrl] = useState("");
  const [agreementFileName, setAgreementFileName] = useState("");
  const [agreementTemplateType, setAgreementTemplateType] = useState("");
  const [goFromComposerPage, setGoFromComposerPage] = useState(false)
  const [showDownloadButton, setDownloadButton] = useState(true)
  const [composerName, setComposerName] = useState(undefined)
  const [isAuthorisedsignature, setAuthorisedsignature] = useState(false)
  const [signature, setSignatureData] = useState(undefined)
  const [guID, setguIDData] = useState(undefined)
  const [showAdoptValidations, setShowAdoptValidations] = useState(false)
  const [showSignOnlineValidations, setShowSignOnlineValidations] = useState(false)
  const [isAuthorisedSignOnline, setAuthorisedSignOnline] = useState(false)
  const [showStepBtn, setShowStepBtn] = useState(true)
  const [pageTitle, setPageTitle] = useState("REVIEW AGREEMENT")
  const [toggleForgotModal, setToggleForgotModal] = useState(false);
  const [composer, setComposer] = useState(undefined)

  useEffect(() => {
    // Check whether the user comes from an email link
    if (match.params.token2) {
      setSteps(0)
      setDownloadButton(false)
      setShowStepBtn(false)
      fetchComposer()
    }
    fetchAgreement();
  }, [])

  const fetchComposer = () => {
    if (!match.params.token2) {
      return
    }

    dispatch(GetComposer(match.params.token2)).then(res => {
      if (res.Success) {
        setComposer(res.Composers)
        setComposerName(res.Composers)
        if (res.Composers.ComposerSignature === null) {
          setSteps(3)
          setDownloadButton(true)
          setShowStepBtn(true)
        }
        else {
          setAuthorisedSignOnline(true)
          setDownloadButton(false)
          setShowStepBtn(false)
          setSteps(4)
        }
        setShowAdoptValidations(true)
      }
    })
  }

  const fetchAgreement = () => {
    if (!match.params.token) {
      fetchPdfUrl("ScoreKeepers_EX5_N.pdf")
      return
    }

    dispatch(LoadAgreement(match.params.token)).then(res => {
      if (res.Success) {
        if (res.Agreements && res.Agreements.AgreementUrl) {
          setAgreementTemplateType(res.Agreements.DisplayName)
          fetchPdfUrl(res.Agreements.AgreementUrl)
        }
      }
    })
  }

  const fetchPdfUrl = (fileName) => {
    dispatch(showAgreementPdfFile(fileName, "agreement")).then(res => {
      if (res.Success) {
        setAgreementUrl(res.url)
        setAgreementFileName(res.fileName)
      }
    }).catch(err => {
      console.error(err);
    });
  }

  const handleReadyToGo = () => {

    if (step < 1) {
      setDownloadButton(true)
    } else {
      setDownloadButton(false)
    }

    if (step <= 4) {
      // setSteps(step + 1);
      if (step === 2) {
        if (goFromComposerPage) {
          setSteps(step + 1);
          setBtn1Title("GO TO STEP " + (step + 3))
        } else {
          handleComposerPageButton()
        }
      } else {
        if (step == 3) {
          if (isAuthorisedsignature === true) {
            // setSteps(step + 1);
            if (step == 3) {
              uploadSignatureAPICall()
            }
          }
          else {
            setShowAdoptValidations(true)
          }
        } else if (step == 4) {

          if (isAuthorisedSignOnline) {
            setSteps(step + 1);
            setShowStepBtn(false)
            setDownloadButton(false)
          } else {
            ShowErrorNotification("Please Click on Sign Here!", 3000)
            setShowSignOnlineValidations(true)
          }
        }

        else {
          setSteps(step + 1);
          setBtn1Title("GO TO STEP " + (step + 2))
        }
      }
    }

    // setTitle(step)
  };

  const getTitle = (step) => {
    switch (step) {
      case 0:
        return "LOADING..."

      case 1:
        return "REVIEW AGREEMENT"

      case 2:
        return "CREATE ACCOUNT"

      case 3:
        return "ADOPT SIGNATURE"

      case 4:
        return "SIGN ONLINE"

      case 5:
        return "DONE!"

      default:
        return "REVIEW AGREEMENT"
    }
  }

  const uploadSignatureAPICall = () => {
    const data = {
      "ImageData": signature,
      "ComposerGuid": guID
    }
    dispatch(uploadSignature(data)).then(res => {
      if (res.Success) {
        setSteps(step + 1)
        setBtn1Title("Done ")
      }
    }).catch(error => {
    })
  }

  const readyToDownload = () => {
    if (step === 1 || step === 3 ||step === 4) {
      dispatch(DownloadAgreement(agreementFileName, "agreement"))
    }

    // if (step >= 2) {
    //   setSteps(step - 1);
    // }
  };

  const handleComposerPageButton = () => {
    document.querySelector("#new-composer-ready-to-rock").click()
  }

  const handleGoToPage = (isGoTo) => {
    if (isGoTo) {
      setSteps(step + 1)
      setBtn1Title("GO TO STEP " + (step + 2))
    }
  }

  const username = (name) => {
    setComposerName(name)
  }

  const renderImage = value => {
    switch (value) {
      case 0:
        return (
          <div className="padding-Image">
          </div>
        );
      case 1:
        return (
          <div className="padding-Image">
            <Image
              url={require("../../../appearance/images/Agreement-Step1.png")}
            />
          </div>
        );
      case 2:
        return (
          <div className="padding-Image">
            <Image
              url={require("../../../appearance/images/Agreement-Step2.png")}
            />
          </div>
        );
      case 3:
        return (
          <div className="padding-Image">
            <Image
              url={match.params.token2 ? require("../../../appearance/images/Sign-Agreement-Step1.png") : require("../../../appearance/images/Agreement-Step3.png")}
            />
          </div>
        );
      case 4:
        return (
          <div className="padding-Image">
            <Image
              url={match.params.token2 ? require("../../../appearance/images/Sign-Agreement-Step2.png") : require("../../../appearance/images/Agreement-Step4.png")}
            />
          </div>
        );
      case 5:
        return (
          ""
        );
      default:
        return null;
    }
  };

  const renderPdfReder = () => {
    if (step == 1) {
      if (!agreementUrl)
        return null

      return (
        <div className="agreement-pdf-view">
          <iframe
            src={agreementUrl + "#toolbar=0&amp;navpanes=0&amp;zoom=none&amp;scrollbar=0&amp;statusbar=1&amp;view=FitH"}
            width="100%"
          ></iframe>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderButtons = () => {
    return (
      <div className="button-container-body">
        {renderDownloadButton()}
        {renderPrivacyLink()}
        {showStepBtn && (step === 1 || step ===  2 || step === 3) ? (
          <Button title={btn1Title} buttonStyle="ready-btn" onClick={handleReadyToGo} />
        ) : null}
      </div>
    );
  };

  const renderDownloadButton = () => {
    if (showDownloadButton == true) {
      return (
        <div>
          <Button
            title={btn2Title}
            buttonStyle="download-btn"
            onClick={readyToDownload}
          />
        </div>
      )
    } else {
      return null
    }
  }

  const renderPrivacyLink = () => {
    if (step === 2) {
      return (
          <div className="privacy-link">
            <a onClick={handlePrivacyLink}>Privacy</a>
          </div>
      )
    }
    else {
      return null
    }
  }

  const handlePrivacyLink = () => {
      setToggleForgotModal(!toggleForgotModal);
  }

  const handleModalClose = () => {
      setToggleForgotModal(false);
    }

  const signatureSelectedAction = (signatureData, isAuthorized) => {
    if (signatureData.id != "" && isAuthorized != false) {
      setSignatureData(signatureData.signature)
      setguIDData(signatureData.id)
      setAuthorisedsignature(true)
    } else {
      setAuthorisedsignature(false)
    }
  }

  const signOnlineSelectedAction = (isAuthorized, downloadFileName) => {
    if (isAuthorized) {
      setAuthorisedSignOnline(true)
      // After click sign image go to final step
      setSteps(step + 1)

      // if (!match.params.token2) {
      //   // setBtn2Title("Download Signed Agreement")
      //   // setDownloadButton(true)
      //   setSteps(step + 1)
      // }
      // else {
      //   setSteps(step + 1)
      //   //setBtn2Title("Download Signed Agreement")
      //   //setDownloadButton(true)
      // }
      setAgreementFileName(downloadFileName)
    }
    else {
      setAuthorisedSignOnline(false)
    }
  }

  const renderSection = renderValue => {
    switch (renderValue) {
      case 0:
        return (
          <div>
          </div>
        );
      case 1:
        return (
          <div>
            <Review />
          </div>
        );
      case 2:
        return (
          // <div>
          <CreateAccount showHeader={false} showFooter={false} displayButton={false} gotoNextPage={handleGoToPage} username={username}
            agreementId={match.params.token}
          />
          // </div>
        );
      case 3:
        return (
          <div>
            <AdoptSignature composerName={composerName} isSegnatureSelected={signatureSelectedAction} showAdoptValidations={showAdoptValidations} />
          </div>
        );
      case 4:
        return (
          // <div>
          <SignOnline composerName={composerName} templateType={agreementTemplateType} isSignOnlineSelected={signOnlineSelectedAction} showSignOnlineValidations={showSignOnlineValidations} />
          // </div>
        );
      case 5:
        return (
          // <div>
          <FinalPage composerName={composerName} showDownloadButton={match.params.token2} agreementFileName={agreementFileName}></FinalPage>
          // </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="agreement-container">
      <Header title={getTitle(step)} />
      <div className="agreement-container-body">
        <div className="agreement-body-parent">
          <div className="agreement-child-column">
            <div className="agreement-child-column">
              {renderImage(step)}
              <div className="agreement-child-row">
                {renderSection(step)}
                {renderPdfReder()}
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderButtons()}
      <Footer />
      <Modal isVisible={toggleForgotModal} onClose={handleModalClose}>
          <PrivacyModal />
      </Modal>
    </div>
  );
};

export default Agreement;
