import React, { useEffect, useState } from "react";
import className from "classnames";
import "./MetaDataForm.scss";
import {
  ContextMenuList,
  GetSelectedItemsElement,
} from "../../../ui/context-menu/context-menu";
import { FontIcon } from "../../../ui/font-icon/font-icon";
import Button from "../../../ui/button/Button";
import TextInput from "../../../ui/text-input/TextInput";
import Checkbox from "../../../ui/checkbox/Checkbox";
import Textarea from "../../../ui/textarea/Textarea";
import SelectContextComponents from "../../../ui/select/SelectContextComponent";
import Image from "../../../ui/image/Image";
import {
  LoadContextMenuData,
  SaveTracksMetaData,
} from "../../../../store/tracks/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  ShowErrorNotification,
  ShowSuccessNotification,
  ShowWarningNotification,
} from "../../../ui/snackbar/Snackbar";
import { WaveformPlayer } from "../../../ui/waveform-player/waveform-player";

import ReactTooltip from "react-tooltip";
import Modal from "../../../common/modal/Modal";
import ConfirmationModal from "../../../common/modal/modal-content/confirmation-modal/ConfirmationModal";

function editContextMenu() {
  return [
    {
      Label: "15",
      Value: {
        Id: 1,
        Name: "15",
      },
    },
    {
      Label: "30",
      Value: {
        Id: 2,
        Name: "30",
      },
    },
    {
      Label: "60",
      Value: {
        Id: 3,
        Name: "60",
      },
    },
  ];
}

const defaultMultipleValueObj = {
  Label: "[Multiple Values]",
  Value: { Id: 0, Name: "[Multiple Values]" },
};

const MetaDataForm = ({
  onBackClick = () => {},
  goToNext = () => {},
  uploadedTracks = [],
  submissionType = "",
}) => {
  const dispatch = useDispatch();
  const [genreContextMenu, setGenreContextMenu] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState(undefined);
  const [moodContextMenu, setMoodContextMenu] = useState([]);
  const [selectedMood, setSelectedMood] = useState(undefined);
  const [energyContextMenu, setEnergyContextMenu] = useState([]);
  const [selectedEnergy, setSelectedEnergy] = useState(undefined);
  const [soundsLikeContextMenu, setSoundsLikeContextMenu] = useState([]);
  const [selectedSoundsLike, setSelectedSoundsLike] = useState([]);
  const [instrumentationContextMenu, setInstrumentationContextMenu] = useState(
    []
  );
  const [selectedInstrumentation, setSelectedInstrumentation] = useState([]);
  const [beatTypeContextMenu, setBeatTypeContextMenu] = useState([]);
  const [selectedBeatType, setSelectedBeatType] = useState([]);
  const [selectedEdit, setSelectedEdit] = useState(undefined);
  const [cells, setCells] = useState([]);
  const [Alt, setAlt] = useState("");
  const [Mix, setMix] = useState("");
  const [selectedTrackTitle, setSelectedTrackTitle] = useState("");
  const [TrackDescription, setTrackDescription] = useState("");
  const [Notes, setNotes] = useState("");
  const [ExclusiveFlag, setExclusiveFlag] = useState(false);
  const [ExclusiveFlagDisabled, setExclusiveTrackDisabled] = useState(false);
  const [IsPublicDomain, setIsPublicDomain] = useState(false);
  const [PublicDomainArrangement, setPublicDomainArrangement] = useState(false);
  const [BPM, setBPM] = useState("");
  const [trackDuration, setTrackDuration] = useState(0);
  const [selectedTrack, setSelectedTrack] = useState(undefined);
  const [isSoundsLikeMultiple, setIsSoundsLikeMultiple] = useState(false);
  const [isInstrumentationMultiple, setIsInstrumentationMultiple] =
    useState(false);
  const [isBeatTypeMultiple, setIsBeatTypeMultiple] = useState(false);
  const [errorMix, setErrorMix] = useState(null);
  const [errorBPM, setErrorBPM] = useState(null);
  const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false);
  const [selectedTracks, setSelectedTracks] = useState([]);

  const [selectedEditItem, setSelectedEditItem] = useState([]);
  const [selectedGenreItem, setSelectedGenreItem] = useState([]);
  const [selectedMoodItem, setSelectedMoodItem] = useState([]);
  const [selectedEnergyItem, setSelectedEnergyItem] = useState([]);

  const tracks = uploadedTracks;

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    setSubmissionType();
    loadUploadedTracks();
    loadContextMenusData();
  };

  const setSubmissionType = () => {
    if (
      submissionType &&
      (submissionType.toLowerCase() === "exclusive" ||
        submissionType.toLowerCase() === "custom cues")
    ) {
      setExclusiveFlag(true);
      setExclusiveTrackDisabled(true);
    } else {
      setExclusiveFlag(false);
      setExclusiveTrackDisabled(false);
    }
  };

  const loadUploadedTracks = () => {
    setCellsData(uploadedTracks);
  };

  const loadContextMenusData = () => {
    loadGenreContextMenuData();
    loadMoodContextMenuData();
    loadEnergyContextMenuData();
    loadSoundsLikeContextMenuData();
    loadInstrumentationContextMenuData();
    loadBeatTypeContextMenuData();
  };

  const loadGenreContextMenuData = () => {
    dispatch(LoadContextMenuData("Genre")).then((res) => {
      setGenreContextMenu(res);
    });
  };

  const loadMoodContextMenuData = () => {
    dispatch(LoadContextMenuData("Mood")).then((res) => {
      setMoodContextMenu(res);
    });
  };

  const loadEnergyContextMenuData = () => {
    dispatch(LoadContextMenuData("Energy")).then((res) => {
      setEnergyContextMenu(res);
    });
  };

  const loadSoundsLikeContextMenuData = () => {
    dispatch(LoadContextMenuData("SoundsLike")).then((res) => {
      setSoundsLikeContextMenu(res);
    });
  };

  const loadInstrumentationContextMenuData = () => {
    dispatch(LoadContextMenuData("Instrumentation")).then((res) => {
      setInstrumentationContextMenu(res);
    });
  };

  const loadBeatTypeContextMenuData = () => {
    dispatch(LoadContextMenuData("BeatType")).then((res) => {
      setBeatTypeContextMenu(res);
    });
  };

  const setCellsData = (tracks = []) => {
    const mappedCells = tracks.map((track) => [false, { ...track }]);
    mappedCells.unshift([false, 0]);
    setTimeout(() => {
      setCells([...mappedCells]);
      //setTracks([...tracks]);
    }, 0);
  };

  function handleContextMenuSelect(type, items = [], checked = true) {
    switch (type) {
      case "Edit":
        if (items && selectedEdit) {
          if (items.Label === selectedEdit.Label) {
            setSelectedEdit(undefined);
            setSelectedEditItem(undefined);
            updateTracksAndCells(type, null);
          } else {
            setSelectedEditItem({ items: [{ ...items }] });
            setSelectedEdit(items);
            updateTracksAndCells(type, items);
          }
        } else {
          setSelectedEditItem({ items: [{ ...items }] });
          setSelectedEdit(items);
          updateTracksAndCells(type, items);
        }
        break;

      case "Genre":
        if (items && selectedGenre) {
          if (items.Label === selectedGenre.Label) {
            setSelectedGenre(undefined);
            setSelectedGenreItem(undefined);
            updateTracksAndCells(type, null);
          } else {
            setSelectedGenreItem({ items: [{ ...items }] });
            setSelectedGenre(items);
            updateTracksAndCells(type, items);
          }
        } else {
          setSelectedGenreItem({ items: [{ ...items }] });
          setSelectedGenre(items);
          updateTracksAndCells(type, items);
        }
        break;

      case "Mood":
        if (items && selectedMood) {
          if (items.Label === selectedMood.Label) {
            setSelectedMood(undefined);
            setSelectedMoodItem(undefined);
            updateTracksAndCells(type, null);
          } else {
            setSelectedMoodItem({ items: [{ ...items }] });
            setSelectedMood(items);
            updateTracksAndCells(type, items);
          }
        } else {
          setSelectedMoodItem({ items: [{ ...items }] });
          setSelectedMood(items);
          updateTracksAndCells(type, items);
        }
        break;

      case "Energy":
        if (items && selectedEnergy) {
          if (items.Label === selectedEnergy.Label) {
            setSelectedEnergy(undefined);
            setSelectedEnergyItem(undefined);
            updateTracksAndCells(type, null);
          } else {
            setSelectedEnergyItem({ items: [{ ...items }] });
            setSelectedEnergy(items);
            updateTracksAndCells(type, items);
          }
        } else {
          setSelectedEnergyItem({ items: [{ ...items }] });
          setSelectedEnergy(items);
          updateTracksAndCells(type, items);
        }
        break;

      case "SoundsLike":
        setIsSoundsLikeMultiple(false);
        setSelectedSoundsLike({ items });
        updateTracksAndCells(type, items);
        break;

      case "Instrumentation":
        setIsInstrumentationMultiple(false);
        setSelectedInstrumentation({ items });
        updateTracksAndCells(type, items);
        break;

      case "BeatType":
        setIsBeatTypeMultiple(false);
        setSelectedBeatType({ items });
        updateTracksAndCells(type, items);
        break;

      default:
        break;
    }
  }

  const handleRemoveSelectedItem = (type, index) => {
    switch (type) {
      case "SoundsLike":
        const soundslikes = { items: [...selectedSoundsLike.items] };
        soundslikes.items.splice(index, 1);
        setSelectedSoundsLike({ items: [...soundslikes.items] });
        updateTracksAndCells("SoundsLike", [...soundslikes.items]);
        break;

      case "Instrumentation":
        const instrumentation = { items: [...selectedInstrumentation.items] };
        instrumentation.items.splice(index, 1);
        setSelectedInstrumentation({ items: [...instrumentation.items] });
        updateTracksAndCells("Instrumentation", [...instrumentation.items]);
        break;

      case "BeatType":
        const beatType = { items: [...selectedBeatType.items] };
        beatType.items.splice(index, 1);
        setSelectedBeatType({ items: [...beatType.items] });
        updateTracksAndCells("BeatType", [...beatType.items]);
        break;

      default:
        break;
    }
  };

  const setSelectedCellData = (track) => {
    setSelectedTrackTitle(track.TrackTitle);
    setAlt(track.Alt ? track.Alt : "");
    setIsPublicDomain(track.IsPublicDomain);
    setPublicDomainArrangement(track.PublicDomainArrangement);
    setMix(track.Mix ? track.Mix : "");
    setBPM(track.BPM ? track.BPM : "");
    setSelectedEdit(track.Edit);
    setSelectedGenre(track.Genre);
    setSelectedMood(track.Mood);
    setSelectedEnergy(track.Energy);
    setSelectedSoundsLike({ items: track.SoundsLike ? track.SoundsLike : [] });
    setSelectedInstrumentation({
      items: track.Instrumentation ? track.Instrumentation : [],
    });
    setSelectedBeatType({ items: track.BeatType ? track.BeatType : [] });
    setIsSoundsLikeMultiple(false);
    setIsInstrumentationMultiple(false);
    setIsBeatTypeMultiple(false);
    setTrackDescription(track.TrackDescription ? track.TrackDescription : "");
    setNotes(track.Notes || "");
    if (!submissionType || submissionType !== "Exclusive") {
      setExclusiveFlag(track.ExclusiveFlag);
    }
  };

  const setSelectedMultipleValue = (selectedTracks) => {
    setSelectedTrackTitle("[Multiple Values]");

    if (!submissionType || submissionType !== "Exclusive") {
      var exclusiveFlag = isUniqueValue("ExclusiveFlag", false, selectedTracks);
      setExclusiveFlag(exclusiveFlag === true);
    }

    var isPublicDomainFlag = isUniqueValue(
      "IsPublicDomain",
      false,
      selectedTracks
    );
    setIsPublicDomain(isPublicDomainFlag === true);

    var publicDomainArrangement = isUniqueValue(
      "PublicDomainArrangement",
      false,
      selectedTracks
    );
    setPublicDomainArrangement(publicDomainArrangement === true);

    var alt = isUniqueValue("Alt", "[*]", selectedTracks);
    setAlt(alt ? alt : "");

    var mix = isUniqueValue("Mix", "[*]", selectedTracks);
    setMix(mix ? mix : "");

    var bpm = isUniqueValue("BPM", "[*]", selectedTracks);
    setBPM(bpm ? bpm : "");

    var trackDescription = isUniqueValue(
      "TrackDescription",
      "[Multiple Values]",
      selectedTracks
    );
    setTrackDescription(trackDescription ? trackDescription : "");

    var notes = isUniqueValue("Notes", "[Multiple Values]", selectedTracks);
    setNotes(notes || "");

    var edit = isUniqueValueOfObjectType(
      "Edit",
      defaultMultipleValueObj,
      selectedTracks
    );
    setSelectedEdit(edit);

    var genre = isUniqueValueOfObjectType(
      "Genre",
      defaultMultipleValueObj,
      selectedTracks
    );
    setSelectedGenre(genre);

    var mood = isUniqueValueOfObjectType(
      "Mood",
      defaultMultipleValueObj,
      selectedTracks
    );
    setSelectedMood(mood);

    var energy = isUniqueValueOfObjectType(
      "Energy",
      defaultMultipleValueObj,
      selectedTracks
    );
    setSelectedEnergy(energy);

    var soundsLike = isUniqueValueOfArrayType(
      "SoundsLike",
      true,
      selectedTracks
    );
    setIsSoundsLikeMultiple(soundsLike === true);
    setSelectedSoundsLike({ items: soundsLike ? soundsLike : [] });

    var instrumentation = isUniqueValueOfArrayType(
      "Instrumentation",
      true,
      selectedTracks
    );
    setIsInstrumentationMultiple(instrumentation === true);
    setSelectedInstrumentation({
      items: instrumentation ? instrumentation : [],
    });

    var beatType = isUniqueValueOfArrayType("BeatType", true, selectedTracks);
    setIsBeatTypeMultiple(beatType === true);
    setSelectedBeatType({ items: beatType ? beatType : [] });
  };

  const isUniqueValue = (key, multiText, selectedTracks) => {
    if (selectedTracks.length > 0) {
      var sets = new Set();
      var firstElm = selectedTracks[0][key];
      selectedTracks.forEach((t, index) => {
        sets.add(t[key]);
      });
      return sets.size > 1 ? multiText : firstElm;
    }

    return null;
  };

  const isUniqueValueOfObjectType = (key, multiText, selectedTracks) => {
    if (selectedTracks.length > 0) {
      var sets = [];
      var firstElm = selectedTracks[0][key];
      selectedTracks.forEach((t, index) => {
        let result = sets.some((v) => {
          if (v && t[key]) return v.Value.Id === t[key].Value.Id;
          else {
            return v === t[key];
          }
          return false;
        });

        if (!result) sets.push(t[key]);
      });

      return sets.length > 1 ? multiText : firstElm;
    }

    return "";
  };

  const isUniqueValueOfArrayType = (key, multiText, selectedTracks) => {
    if (selectedTracks.length > 0) {
      var sets = [];
      var firstElm = selectedTracks[0][key];
      selectedTracks.forEach((t, index) => {
        const values = t[key];
        if (values && values.length > 0) {
          const ids = values.map((a) => a.Value.Id);
          sets.push(ids.toString());
        } else {
          sets.push(null);
        }
      });

      if (sets.length > 0) {
        let d = sets[0];
        const isMatched = sets.every((s) => s === d);
        return isMatched ? firstElm : multiText;
      }
    }

    return null;
  };

  const clearMetaDataEntries = () => {
    setSelectedTrackTitle("");
    setAlt("");
    setIsPublicDomain(false);
    setPublicDomainArrangement(false);
    setMix("");
    setBPM("");
    setSelectedEdit(undefined);
    setSelectedGenre(undefined);
    setSelectedMood(undefined);
    setSelectedEnergy(undefined);
    setSelectedSoundsLike([]);
    setSelectedInstrumentation([]);
    setSelectedBeatType([]);
    setIsSoundsLikeMultiple(false);
    setIsInstrumentationMultiple(false);
    setIsBeatTypeMultiple(false);
    setTrackDescription("");
    setNotes("");

    if (!submissionType || submissionType !== "Exclusive") {
      setExclusiveFlag(false);
    }
  };

  const getSelectedCells = () => {
    let selectedRows = [];
    cells.forEach((row, i) => {
      if (row[0]) {
        selectedRows.push(row[1]);
      }
    });
    return selectedRows;
  };

  const updateTracksAndCells = (key, value) => {
    selectedTracks.map((t, i) => {
      tracks.find((x) => x.Id === t.Id)[key] = value;
      t[key] = value;
      return t;
    });
  };

  const handleValueChange = (type, value) => {
    if (value === "[Multiple Values]" || value === "[*]") return;

    updateTracksAndCells(type, value);
  };

  const getExclusiveFlag = (exclusiveFlag) => {
    if (submissionType && submissionType === "Exclusive") {
      return true;
    } else {
      return exclusiveFlag;
    }
  };

  const getEditData = (editData) => {
    if (!editData) {
      return {
        IsEditTrack: false,
        AdTrackTime: null,
      };
    }

    return {
      IsEditTrack: true,
      AdTrackTime: editData.Label,
    };
  };

  const getSoundsLikeData = (ComposerTracksMetaDataId, soundsLikeData) => {
    if (!soundsLikeData || soundsLikeData === 0) {
      return [];
    }

    return soundsLikeData.map((sounds) => ({
      SoundsLikeId: sounds.Value.Id,
      ComposerTracksMetaDataId,
    }));
  };

  const getInstrumentationData = (instrumentationData) => {
    if (!instrumentationData || instrumentationData === 0) {
      return "";
    }

    const strArray = [];

    instrumentationData.forEach((instrumentation) => {
      strArray.push(instrumentation.Label);
    });

    return strArray.join(", ");
  };

  const getBeatTypeData = (beatTypeData) => {
    if (!beatTypeData || beatTypeData === 0) {
      return "";
    }

    const strArray = [];

    beatTypeData.forEach((beatType) => {
      strArray.push(beatType.Label);
    });

    return strArray.join(", ");
  };

  const getContextMenuId = (data) => {
    if (!data) return null;

    return data.Value.Id;
  };

  const generateRequestData = () => {
    const data = tracks;
    let selectedTrackForUnsaved = getSelectedCells();

    // // Note from Jason - According to Eric, we should NOT be allowing tracks to process when tracks aren't selected
    // if (selectedTrackForUnsaved.length === 0) {
    //     return selectedTrackForUnsaved;
    // }

    if (selectedTrackForUnsaved.length > 0) {
      if (data.length > 0) {
        return data.map((track) => {
          return {
            ...track,
            ...getEditData(track.Edit),
            ExclusiveFlag: getExclusiveFlag(track.ExclusiveFlag),
            GenreId: getContextMenuId(track.Genre),
            Genre: null,
            EnergyId: getContextMenuId(track.Energy),
            Energy: null,
            MoodId: getContextMenuId(track.Mood),
            Mood: null,
            SoundsLike: getSoundsLikeData(track.Id, track.SoundsLike),
            Instrumentation: getInstrumentationData(track.Instrumentation),
            BeatType: getBeatTypeData(track.BeatType),
          };
        });
      }
    }
    else if (data.length > 0) {
      return data.map((track) => {
        return {
          ...track,
          ...getEditData(track.Edit),
          ExclusiveFlag: getExclusiveFlag(track.ExclusiveFlag),
          GenreId: getContextMenuId(track.Genre),
          Genre: null,
          EnergyId: getContextMenuId(track.Energy),
          Energy: null,
          MoodId: getContextMenuId(track.Mood),
          Mood: null,
          SoundsLike: getSoundsLikeData(track.Id, track.SoundsLike),
          Instrumentation: getInstrumentationData(track.Instrumentation),
          BeatType: getBeatTypeData(track.BeatType),
        };
      });
    }

    return [];
  };

  const isTrackValid = (track) => {
    return (track.Mix || "").trim() !== "" && (track.BPM || "").trim() !== "";
  };

  const isMetaDataValid = () => {
    let isValid = true;

    tracks.map((track) => {
      isValid = isValid && isTrackValid(track);
    });

    return isValid;
  };

  const saveTrackMetaData = () => {
    const data = generateRequestData();

    dispatch(SaveTracksMetaData(data))
      .then((res) => {
        if (res.Success) {
          ShowSuccessNotification("Save successful!", 3000);
          goToNext();
        }
      })
      .catch((error) => {
        ShowErrorNotification("Unable to save the meta data.", 3000);
      });
  };

  const handleNextButtonClick = () => {
    saveTrackMetaData();
  };

  const handleModalClose = () => {
    setToggleConfirmationModal(false);
  };

  const onTrackPlayed = (trackId) => {
    showTrackDuration(trackId);
  };

  const showTrackDuration = (trackId) => {
    setTrackDuration(0);
  };

  const updateSelectedMetaData = (selectedTracks) => {
    if (selectedTracks.length > 0) {
      if (selectedTracks.length > 1) {
        clearMetaDataEntries();
        setSelectedMultipleValue(selectedTracks);
        setSelectedTrack(undefined);
      } else {
        // setSelectedTrackTitle(selectedTracks[0].TrackTitle)
        setSelectedCellData(selectedTracks[0]);
        setSelectedTrack(selectedTracks[0]);
      }
    } else {
      clearMetaDataEntries();
      setSelectedTrack(undefined);
    }
  };

  const renderSelectTracksNotice = () => {
    return (
      <div className="form-container metadata-select-tracks-notice">
        <h1>No tracks selected</h1>
        <h3>Please select a track or multiple tracks to update metadata.</h3>
      </div>
    );
  };

  const renderMetaForm = () => {
    return (
      <div>
        <div className="player-div">
          {selectedTrack ? (
            <WaveformPlayer
              track={selectedTrack}
              source=""
              onTrackPlayed={onTrackPlayed}
              onPlaying={() => {}}
            />
          ) : null}
        </div>
        <div className="column-in-row">
          <div className="column-in-column">
            <div className="inner-column-row">
              <p className="label-style">TITLE:</p>
              <p className="regular-text">{selectedTrackTitle}</p>
            </div>

            <div className="inner-column-row">
              <div className="input-div">
                <TextInput
                  label="ALT SUFFIX:"
                  type="text"
                  labelClassName="label-style"
                  value={Alt}
                  onChange={(value) => {
                    setAlt(value.toUpperCase());
                    handleValueChange("Alt", value.toUpperCase());
                  }}
                  // onBlur={(value) => handleValueChange("Alt", value)}
                  maxLength={1}
                />
              </div>
              <span data-tip data-for="altinfo" className="approved-info">
                ?
              </span>
              <ReactTooltip
                id="altinfo"
                place="bottom"
                className="tooltip-background"
                multiline={true}
                effect="solid"
              >
                This is a single letter input such as A which will designate
                this mix from other alternate mixes. Use ascending letters as
                the mixes get sparser. For example, A will be for the full mix,
                B the no melody mix and so forth.
              </ReactTooltip>
            </div>

            <div className="inner-column-row">
              <div className="input-div">
                <TextInput
                  label="MIX:"
                  type="text"
                  value={Mix}
                  isRequired={true}
                  labelClassName="label-style"
                  onChange={(value) => {
                    setMix(value);
                    handleValueChange("Mix", value);
                    value === ""
                      ? setErrorMix("Mix is required")
                      : setErrorMix("");
                  }}
                  // onBlur={(value) => handleValueChange("Mix", value)}
                  // errorMsg={errorMix}
                />
              </div>
              <span data-tip data-for="mixinfo" className="approved-info">
                ?
              </span>
              <ReactTooltip
                id="mixinfo"
                place="bottom"
                className="tooltip-background"
                multiline={true}
                effect="solid"
              >
                This is an abbreviated description of the mix. Eliminate spaces
                between words and begin each new abbreviated word with an
                uppercase letter. For example, NoMel for no melody, GtrPno for
                guitar and piano. NoDrums for no drums.
              </ReactTooltip>
            </div>
          </div>

          <div className="column-in-column">
            <div className="inner-column-row vertical-center">
              <p className="checkbox-label-style">EXCLUSIVE:</p>
              <Checkbox
                disabled={ExclusiveFlagDisabled}
                onChange={(value) => {
                  setExclusiveFlag(value);
                  updateTracksAndCells("ExclusiveFlag", value);
                }}
                value={ExclusiveFlag}
              />
            </div>

            <div className="inner-column-row vertical-center">
              <p className="checkbox-label-style">PUBLIC DOMAIN:</p>
              <Checkbox
                onChange={(value) => {
                  setIsPublicDomain(value);
                  updateTracksAndCells("IsPublicDomain", value);
                }}
                value={IsPublicDomain}
              />

              <p className="checkbox-label-style">PD ARRANGEMENT:</p>
              <Checkbox
                onChange={(value) => {
                  setPublicDomainArrangement(value);
                  updateTracksAndCells("PublicDomainArrangement", value);
                }}
                value={PublicDomainArrangement}
              />              
            </div>

            <div className="inner-column-row">
              <div className="input-div">
                <TextInput
                  label="BPM:"
                  num
                  type="number"
                  value={BPM}
                  maxlength={3}
                  isRequired={true}
                  labelClassName="label-style"
                  inputClassName="bpm-input"
                  onChange={(value) => {
                    setBPM(value);
                    handleValueChange("BPM", value);
                    value === ""
                      ? setErrorBPM("BPM is required")
                      : setErrorBPM("");
                  }}
                  onBlur={(value) => handleValueChange("BPM", value)}
                  // errorMsg={errorBPM}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="column-in-row">
          <p className="label-style">EDIT:</p>
          <div className="context-menu-div">
            <ContextMenuList
              title={"Edit"}
              toggled={0}
              contextMenuName={"Edit"}
              contextMenuData={editContextMenu()}
              multiSelect={false}
              selectedItems={selectedEditItem}
              onSelect={(items) => handleContextMenuSelect("Edit", items)}
              selectElement={
                <SelectContextComponents
                  value={selectedEdit ? selectedEdit.Label : "Select"}
                />
              }
            />
          </div>
          <span data-tip data-for="editinfo" className="approved-info">
            ?
          </span>
          <ReactTooltip
            id="editinfo"
            place="bottom"
            className="tooltip-background"
            multiline={true}
            effect="solid"
          >
            Select one of these options if your track has been edited to match a
            precise ad timing format. (:15, :30, :60)
          </ReactTooltip>
        </div>

        <div className="column-in-row">
          <p className="label-style">GENRE:</p>
          <div className="context-menu-div">
            <ContextMenuList
              title={"Genre"}
              toggled={0}
              contextMenuName={"Genre"}
              contextMenuData={genreContextMenu}
              multiSelect={false}
              selectedItems={selectedGenreItem}
              onSelect={(items) => handleContextMenuSelect("Genre", items)}
              selectElement={
                <SelectContextComponents
                  value={selectedGenre ? selectedGenre.Label : "Select"}
                  width={200}
                />
              }
            />
          </div>
        </div>
        <div className="column-in-row">
          <p className="label-style">MOOD:</p>
          <div className="context-menu-div">
            <ContextMenuList
              title={"Mood"}
              toggled={0}
              contextMenuName={"Mood"}
              contextMenuData={moodContextMenu}
              multiSelect={false}
              selectedItems={selectedMoodItem}
              onSelect={(items) => handleContextMenuSelect("Mood", items)}
              selectElement={
                <SelectContextComponents
                  value={selectedMood ? selectedMood.Label : "Select"}
                  width={200}
                />
              }
            />
          </div>
        </div>
        <div className="column-in-row">
          <p className="label-style">ENERGY:</p>
          <div className="context-menu-div">
            <ContextMenuList
              title={"Energy"}
              toggled={0}
              contextMenuName={"Energy"}
              contextMenuData={energyContextMenu}
              multiSelect={false}
              selectedItems={selectedEnergyItem}
              onSelect={(items) => handleContextMenuSelect("Energy", items)}
              selectElement={
                <SelectContextComponents
                  value={selectedEnergy ? selectedEnergy.Label : "Select"}
                  width={200}
                />
              }
            />
          </div>
        </div>

        <div className="column-in-column">
          <div className="column-in-row">
            <p className="label-style">SOUNDS LIKE:</p>
            <div className="context-menu-div">
              <ContextMenuList
                title={"SoundsLike"}
                toggled={0}
                contextMenuName={"SoundsLike"}
                contextMenuData={soundsLikeContextMenu}
                multiSelect={true}
                onSelect={(items) =>
                  handleContextMenuSelect("SoundsLike", items)
                }
                selectedItems={selectedSoundsLike}
                selectElement={
                  <SelectContextComponents
                    value="Select - Choose only one from the categorie(s)"
                    width={200}
                  />
                }
              />
            </div>
            <span data-tip data-for="soundsLikeinfo" className="approved-info">
              ?
            </span>
            {/* <span style={{ color: "#FF0000" }}>
              *Please only tag your 'A' or “Full” version. <br /> Do not
              duplicate selection to all alternate mixes.
            </span> */}
            <ReactTooltip
              id="soundsLikeinfo"
              className="tooltip-background"
              place="bottom"
              type="info"
              multiline={true}
              effect="solid"
              style={{ width: "50px" }}
            >
              Select one of these if your track sounds like a particular band,
              movie, TV show, etc. Do not select more than one and only do so if
              the music is reminiscent of the actual reference, rather than
              simply something in the same genre.
            </ReactTooltip>
          </div>
          <div className="context-selected-items-div item-selected-div-width">
            {isSoundsLikeMultiple ? (
              <p>[Multiple Values]</p>
            ) : (
              <GetSelectedItemsElement
                selectedItems={selectedSoundsLike}
                onRemoveSelectedItem={(items) =>
                  handleRemoveSelectedItem("SoundsLike", items)
                }
                itemType="pills"
              />
            )}
          </div>
        </div>

        <div className="column-in-column">
          <div className="column-in-row">
            <p className="label-style">INSTRUMENTATION:</p>
            <div className="context-menu-div">
              <ContextMenuList
                title={"Instrumentation"}
                toggled={0}
                contextMenuName={"Instrumentation"}
                contextMenuData={instrumentationContextMenu}
                multiSelect={true}
                onSelect={(items) =>
                  handleContextMenuSelect("Instrumentation", items)
                }
                selectedItems={selectedInstrumentation}
                selectElement={
                  <SelectContextComponents value="Select" width={200} />
                }
              />
            </div>
            <span
              data-tip
              data-for="instrumentationInfo"
              className="approved-info"
            >
              ?
            </span>
            <ReactTooltip
              id="instrumentationInfo"
              className="tooltip-background"
              place="bottom"
              type="info"
              multiline={true}
              effect="solid"
              style={{ width: "50px" }}
            >
              Limit your selections to one, or in rare cases two per instrument
              group. Please do not "over tag" your track with redundant
              instrument tags. If, as an example, your track contains heavy
              guitars, selecting the instrument "heavy guitars" is sufficient.
              Do not also select "distorted guitars" and "thrashy guitars" and
              "shredding guitars". Please select the one guitar option that most
              closely describes the guitars in your track. If your track also
              contains lead guitar, then a second guitar option, such as
              "electric guitar melody" would be appropriate.
            </ReactTooltip>
          </div>
          <div className="full-width context-selected-items-div">
            {isInstrumentationMultiple ? (
              <p>[Multiple Values]</p>
            ) : (
              <GetSelectedItemsElement
                selectedItems={selectedInstrumentation}
                onRemoveSelectedItem={(items) =>
                  handleRemoveSelectedItem("Instrumentation", items)
                }
                itemType="pills"
              />
            )}
          </div>
        </div>

        <div className="column-in-column">
          <div className="column-in-row">
            <p className="label-style">BEAT TYPE:</p>
            <div className="context-menu-full-width-div">
              <ContextMenuList
                title={"BeatType"}
                toggled={0}
                contextMenuName={"BeatType"}
                contextMenuData={beatTypeContextMenu}
                multiSelect={true}
                onSelect={(items) => handleContextMenuSelect("BeatType", items)}
                selectedItems={selectedBeatType}
                selectElement={
                  <SelectContextComponents
                    value="Select"
                    width={200}
                    extraLabel={"(only if applicable)"}
                  />
                }
              />
            </div>
          </div>
          <div className="full-width context-selected-items-div">
            {isBeatTypeMultiple ? (
              <p>[Multiple Values]</p>
            ) : (
              <GetSelectedItemsElement
                selectedItems={selectedBeatType}
                onRemoveSelectedItem={(items) =>
                  handleRemoveSelectedItem("BeatType", items)
                }
                itemType="pills"
              />
            )}
          </div>
        </div>

        <div className="column-in-row">
          <TextInput
            label="TRACK DESCRIPTION:"
            type="text"
            value={TrackDescription}
            onChange={(value) => setTrackDescription(value)}
            onBlur={(value) => handleValueChange("TrackDescription", value)}
            labelClassName="label-style"
          />
          <span
            data-tip
            data-for="trackDescriptioninfo"
            className="approved-info approved-info-mg"
          >
            ?
          </span>
          <ReactTooltip
            id="trackDescriptioninfo"
            place="bottom"
            className="tooltip-background"
            multiline={true}
            effect="solid"
          >
            A few words, or a sentence that will offer additional insight that
            is not already covered in the other fields. Examples: “A redneck
            version of Jingle Bells”, “Smokey blues club”, “Waltz”, “Starts
            simple then grows into an epic finish”, “hard hitting”, “delicate”,
            “Begins tense then resolves”, “simple”, “catchy melody”, “vocal
            aahs”
          </ReactTooltip>
        </div>

        <div className="column-in-row">
          <div className="textarea-div">
            <p className="label-style">NOTE:</p>
            <Textarea
              className="textarea"
              placeholder="Have a note for us about this track?  (i.e. I used an instrument that’s not in your instrumentation menu, it’s a Hurdy Gurdy.)"
              onChange={(value) => {
                setNotes(value);
              }}
              value={Notes}
              onBlur={(value) => handleValueChange("Notes", value)}
            />
          </div>
        </div>
        <div>
          <div className="column has-text-danger has-text-weight-bold">
            *Required Fields
          </div>
        </div>
      </div>
    );
  };

  const renderedForm =
    selectedTracks.length > 0 ? renderMetaForm() : renderSelectTracksNotice();

  return (
    <div>
      <div className="meta-form-container">
        <div className="row-container-row">
          <h3 className="title is-3">TAG METADATA TO YOUR TRACK(S)</h3>
          <p className="row-container-Cancel" onClick={onBackClick}>
            {"< CANCEL, RETURN TO BEGINING"}{" "}
          </p>
        </div>
        <div className="progress-img">
          <Image
            url={require("../../../../appearance/images/Composer-Uploads-Step2.png")}
            height={200}
            width={600}
          />
        </div>

        <div className="row-container-row">
          <div className="row-container">
            <div>
              <h6 className="mg-top-2 bold-red">
                Select a track below before entering metadata.
                <span
                  style={{
                    fontWeight: "normal",
                    color: "black",
                    marginLeft: "0.25em",
                  }}
                >
                  You can select multiple tracks to make an entry to more than
                  one track a time by holding down the command key + mouse
                  click.
                </span>
              </h6>
              <h6 className="mg-top-1">
                While some of the data entry fields are optional, we encourage
                you to fill them out so that your music is presented in a way
                that most accurately reflects your creative intention.
              </h6>
            </div>
            {/* {renderTrackTable()} */}
            <UploadedTracksTable
              tracks={tracks}
              selectedTracks={selectedTracks}
              onSelectedRowsUpdate={(tracks) => {
                setSelectedTracks(tracks);
                updateSelectedMetaData(tracks);
              }}
            />
          </div>

          <div className="form-container">{renderedForm}</div>
        </div>

        <div className="row-container-row">
          <div className="row-container"></div>
          <div className="form-ready-container">
            <div className="goToButton-container-body">
              <Button
                title="GO TO NEXT STEP"
                disabled={!isMetaDataValid()}
                buttonStyle="ready-btn"
                onClick={handleNextButtonClick}
              />
            </div>
          </div>

          <Modal isVisible={toggleConfirmationModal} onClose={handleModalClose}>
            <ConfirmationModal
              title="Wait!"
              message="Please enter Mix and BPM for all the tracks."
              onConfirm={handleModalClose}
              isCancelButton="false"
            />
          </Modal>
        </div>
      </div>
    </div>
  );
};

const UploadedTracksTable = ({
  tracks,
  selectedTracks,
  onSelectedRowsUpdate = () => {},
}) => {
  const [initialRow, setInitialRow] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [approvedTracks, setApprovedTracks] =
    State.useGlobalState("approvedTracks");

  const handleRowSelect = (rowIndex, event) => {
    let newSelectedRows = selectedRows;

    if (event.shiftKey) {
      if (initialRow == null) {
        setInitialRow(selectedRow);
        newSelectedRows = [rowIndex];
      } else {
        newSelectedRows = [];
        for (
          let i = Math.min(initialRow, rowIndex);
          i <= Math.max(initialRow, rowIndex);
          i++
        ) {
          newSelectedRows.push(i);
        }
      }
    } else if (event.ctrlKey) {
      setInitialRow(rowIndex);
      if (newSelectedRows.find((x) => x === rowIndex) == null) {
        newSelectedRows.push(rowIndex);
      } else {
        newSelectedRows = newSelectedRows.filter((x) => x !== rowIndex);
      }
    } else {
      setInitialRow(rowIndex);
      newSelectedRows = [rowIndex];
    }

    setSelectedRows(newSelectedRows);
    onSelectedRowsUpdate(newSelectedRows.map((sr) => tracks[sr]));
  };

  const handleSelectAll = () => {
    setSelectedRows(tracks.map((t, i) => i));
    onSelectedRowsUpdate(tracks);
  };

  const handleUnselectAll = () => {
    setSelectedRows([]);
    onSelectedRowsUpdate([]);
  };

  const rows = tracks.map((t, i) => {
    const rowClass = className("row track-row", {
      selected: selectedTracks.find((x) => x.Id === t.Id),
    });

    const validIcon =
      (t.Mix || "") != "" && (t.BPM || "") != "" ? (
        <FontIcon className="track-is-valid" name="checkmark" />
      ) : (
        <FontIcon className="track-is-not-valid" name="close" />
      );

    return (
      <div key={i} className={rowClass} onClick={(e) => handleRowSelect(i, e)}>
        <div className="track-title">{t.TrackTitle}</div>
        <div className="track-mix">{t.Mix}</div>
        <div className="track-bpm">{t.BPM}</div>
        <div className="track-valid">{validIcon}</div>
      </div>
    );
  });

  return (
    <div className="tracks-table">
      <div className="header row action-bar">
        <button className="box-Button" onClick={() => handleSelectAll()}>
          Select All
        </button>
        <button className="box-Button" onClick={() => handleUnselectAll()}>
          Deselect All
        </button>
      </div>
      <div className="header row">
        <div className="track-title">UPLOADED TRACKS</div>
        <div className="track-mix">MIX</div>
        <div className="track-bpm">BPM</div>
        <div className="track-valid">STATUS</div>
      </div>
      {rows}
    </div>
  );
};

export default MetaDataForm;
