import React, { Fragment } from 'react';
import "./ForgotModal.scss"
import Title from '../../../../ui/title/Title';
import TextInput from '../../../../ui/text-input/TextInput';
import Button from '../../../../ui/button/Button';

const ForgotModal = ({
    onClick = () => { },
    username = "",
    onUsernameChange = () => { },
    usernameError = ""
}) => {
    return (
        <Fragment>
            <div className="forgot-modal-cotainer">
                <Title title="FORGOT PASSWORD ?" />
                <div className="columns mg-top-1 mg-lft-rgt-1 is-vcentered">
                    <div className="column is-8">
                        <TextInput id="username" label="Email Address:" type="text" value={username} onChange={onUsernameChange} style={{height: 45}} />
                        <span className="error-message">{usernameError}</span>
                    </div>
                    <div className="column is-4">
                        <Button title="RESET PASSWORD" onClick={onClick} buttonStyle="blue-large-button" />
                    </div>
                </div>
                <label>A temporary password will be sent to your email address on file with ScoreKeepers Music.</label>
                <p className="forgot-username">
                    <b>Forgot your Email Address?</b>  Please contact ScoreKeepers Music to retrieve your email on file: admin@scorekeepersmusic.com
                </p>
            </div>
        </Fragment>
    )
}

export default ForgotModal;