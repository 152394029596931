import React from "react";
import ReactDOM from "react-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import "./sk-combobox.scss";

const getElementPaths = (target) => {
  let paths = [];

  let node = target;
  while (node != document.body) {
    paths.push(node);

    if (!node) break;

    node = node.parentNode;
  }

  return paths;
};

class SKComboBox extends React.Component {
  constructor() {
    super(...arguments);

    this.componentElement = React.createRef();
    this.filterElement = React.createRef();

    this.state = {
      filter: "",
      showDropDown: false,
    };
  }

  handleBackgroundClick = (e) => {
    const paths = getElementPaths(e.target);
    const isComboBox =
      paths.find((x) => x == this.componentElement.current) != null;

    if (!isComboBox) {
      this.hideDropDownMenu();
    }
  };

  handleBlur = (e) => {
    if (e.nativeEvent.relatedTarget != null) {
      this.hideDropDownMenu();
    }
  };

  handleKeyDown = (e) => {
    switch (e.which) {
      case 27: // ESC
        this.hideDropDownMenu();
        break;
    }
  };

  handleOptionClicked = (option) => {
    const { onChange = () => {} } = this.props;

    this.hideDropDownMenu();
    onChange(option.value);
  };

  handleShowDropDown = () => {
    this.showDropDownMenu();
    setTimeout(() => this.filterElement.current.focus(), 30);
  };

  showDropDownMenu = () => {
    document.addEventListener("click", this.handleBackgroundClick);
    this.setState({ showDropDown: true });
  };

  hideDropDownMenu = () => {
    document.removeEventListener("click", this.handleBackgroundClick);
    this.setState({ filter: "", showDropDown: false });
  };

  renderDropDown = () => {
    if (this.state.showDropDown) {
      const searchRegexStr =
        "(.*)" +
        this.state.filter
          .split(" ")
          .map((s) => `(${s})`)
          .join("(.*?)") +
        "(.*)";
      const searchRegex = new RegExp(searchRegexStr, "i");
      const filteredOptions = this.props.options.filter((o) =>
        searchRegex.test(o.label)
      );

      if (this.props.newEntryOption) {
        filteredOptions.push(this.props.newEntryOption);
      }

      const Row = ({ index, style }) => {
        const option = filteredOptions[index];
        return (
          <div
            className="sk-combobox-drop-down-option"
            style={style}
            onClick={() => this.handleOptionClicked(option)}
          >
            {option.label}
          </div>
        );
      };

      const componentHeight =
        filteredOptions.length < 10 ? filteredOptions.length * 35 : 350;

      return (
        <div
          className="sk-combobox-drop-down"
          style={{ height: componentHeight }}
        >
          <AutoSizer>
            {({ height, width }) => {
              return (
                <List
                  height={height}
                  itemCount={filteredOptions.length}
                  itemSize={35}
                  width={width}
                >
                  {Row}
                </List>
              );
            }}
          </AutoSizer>
        </div>
      );
    }
  };

  render() {
    const { newEntryLabel = null, noSelectionLabel = null } = this.props;

    let placeholderText =
      this.props.selectedOption &&
      this.props.newEntryOption &&
      this.props.selectedOption == "00000000-0000-0000-0000-000000000001"
        ? newEntryLabel
        : noSelectionLabel || "";

    const selectedOption = this.props.options.find(
      (x) => x.value == this.props.selectedOption
    );
    const inputElement = this.state.showDropDown ? (
      <input
        ref={this.filterElement}
        className="sk-combobox-input"
        type="text"
        value={this.state.filter}
        onKeyDown={this.handleKeyDown}
        onBlur={this.handleBlur}
        onChange={(e) => this.setState({ filter: e.target.value })}
      ></input>
    ) : (
      <div className="sk-combobox-input" onClick={this.handleShowDropDown}>
        {selectedOption ? selectedOption.label : placeholderText}
      </div>
    );

    return (
      <div ref={this.componentElement} className="sk-combobox">
        {inputElement}
        {this.renderDropDown()}
      </div>
    );
  }
}

export { SKComboBox };
