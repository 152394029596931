import React, { Fragment, useState, useEffect } from "react";
import { SKComboBox } from "./sk-combobox";
import { SKTextBox } from "./sk-textbox";
import { useDispatch } from "react-redux";

import "./NewCoWriterGroupModal.scss";
import Title from "../../ui/title/Title";
import {
  ShowErrorNotification,
  ShowSuccessNotification,
} from "components/ui/snackbar/Snackbar";
import { SaveComposerGroup } from "../../../store/composer/actions";

/*****************************************************************************************************
 * Gets the composer name in a "FirstName MiddleName LastName, Pro" format
 *****************************************************************************************************/
const GetComposerName = (composer) => {
  return `${composer.FirstName} ${composer.MiddleName} ${composer.LastName}, ${composer.PRO}`.replace(
    /  /g,
    " "
  );
};

/*****************************************************************************************************
 * Creates an empty composer entry
 *****************************************************************************************************/
const CreateEmptyComposer = () => {
  return {
    Guid: null,
    IsCustom: false,
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Share: null,
    PRO: "",
    USPro: "",
    IpicaeNumber: "",
    ProMembershipNo: "",
  };
};

/*****************************************************************************************************
 * Main Dialog Entry
 *****************************************************************************************************/
const NewCoWriterGroupModal = ({
  onClose,
  composer = {},
  composers = [],
  onDone = () => {},
}) => {
  const dispatch = useDispatch();
  const [coWriters, setCoWriters] = useState([
    {
      Guid: composer.Guid,
      IsCustom: false,
      FirstName: composer.FirstName,
      MiddleName: composer.MiddleName,
      LastName: composer.LastName,
      Share: 100.0,
      PRO: composer.PRO,
      USPro: composer.USPro || composer.PRO,
      IpicaeNumber: composer.IpicaeNumber,
      ProMembershipNo: composer.ProMembershipNo,
      OrderSequence: 1,
    },
  ]);
  const handleAddCoWriter = (e) => {
    setCoWriters([...coWriters, CreateEmptyComposer()]);
  };

  const handleUpdateCoWriter = (index, cowriter) => {
    const newComposers = [...coWriters];
    newComposers[index] = cowriter;
    setCoWriters(newComposers);
  };

  const handleRecalculate = () => {
    let newCoWriters = [...coWriters];
    let splitPercentage = [100];

    switch (newCoWriters.length) {
      case 1:
        splitPercentage = [100];
        break;
      case 2:
        splitPercentage = [50, 50];
        break;
      case 3:
        splitPercentage = [34, 33, 33];
        break;
      case 4:
        splitPercentage = [25, 25, 25, 25];
        break;
      case 5:
        splitPercentage = [20, 20, 20, 20, 20];
        break;
      case 6:
        splitPercentage = [20, 16, 16, 16, 16, 16];
        break;
      case 7:
        splitPercentage = [16, 14, 14, 14, 14, 14, 14];
        break;
      case 8:
        splitPercentage = [16, 12, 12, 12, 12, 12, 12, 12];
        break;
      case 9:
        splitPercentage = [12, 11, 11, 11, 11, 11, 11, 11, 11];
        break;
      case 10:
        splitPercentage = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10];
        break;
    }

    splitPercentage.forEach((v, i) => {
      newCoWriters[i].Share = v;
    });

    setCoWriters(newCoWriters);
  };

  const handleRemoveCoWriter = (index, cowriter) => {
    setCoWriters([...coWriters.filter((x, i) => i !== index)]);
  };

  const handleSaveCoWriterGroup = () => {
    const isValid = validate();

    if (isValid) {
      const coWriterGroup = {
        CoWriters: coWriters,
      };

      dispatch(SaveComposerGroup(coWriterGroup))
        .then(() => {
          onDone();
        })
        .catch(() => {
          ShowErrorNotification(
            "Unable to save the CoWriter Group at this time.",
            3000
          );
        });
    }
  };

  const validate = () => {
    let isValid = true;
    let totalShare = 0;
    let missingShares = [];

    coWriters.forEach((c, i) => {
      if (c.IsCustom) {
        if (
          c.FirstName.trim() == "" ||
          c.LastName.trim() == "" ||
          c.PRO.trim() == "" ||
          c.IpicaeNumber.trim() == "" ||
          parseFloat(c.Share || 0) == 0 ||
          c.ProMembershipNo.trim() == ""
        ) {
          isValid = false;
          ShowErrorNotification(
            "CoWriter #" +
              (i + 1) +
              " is not valid.  Custom entries must have First Name, Last Name, Share, IPI/CAE, Pro Membership # filled out.",
            6000
          );
        }
      }

      if (c.Share == null || c.Share.toString().trim() == "") {
        isValid = false;
        ShowErrorNotification(
          "CoWriter #" +
            (i + 1) +
            " is missing a Share %.  Please enter the Share % for this composer.",
          6000
        );
      }

      totalShare += parseFloat(c.Share || 0);
    });

    if (totalShare != 100) {
      isValid = false;
      ShowErrorNotification(
        "The total share for all composers must equal 100%",
        6000
      );
    }

    return isValid;
  };

  const entries = coWriters.map((cowriter, i) => {
    return (
      <CoWriterEntry
        key={i}
        index={i}
        cowriter={cowriter}
        composers={composers}
        onAdd={handleAddCoWriter}
        onRemove={handleRemoveCoWriter}
        onChange={handleUpdateCoWriter}
      />
    );
  });

  return (
    <div className="new-co-writer-group-container">
      <Title title="Create New Co-Writer Group" />

      <div className="co-writers">{entries}</div>

      <div className="co-writers-footer">
        <button className="default-button" onClick={handleRecalculate}>
          RE-CALCULATE %
        </button>
        <button className="green-button" onClick={handleSaveCoWriterGroup}>
          DONE
        </button>
      </div>
    </div>
  );
};

/*****************************************************************************************************
 * Co-Writer Entry Component
 *****************************************************************************************************/
const CoWriterEntry = ({
  index,
  cowriter,
  composers = [],
  onAdd = () => {},
  onRemove = () => {},
  onChange = () => {},
}) => {
  /// Renders the remove button used to remove a Composer entry
  const renderRemoveButton = () => {
    return index !== 0 ? (
      <button className="red-button" onClick={() => onRemove(index, cowriter)}>
        REMOVE
      </button>
    ) : (
      <button className="aqua-button" onClick={onAdd}>
        + ADD CO-WRITER
      </button>
    );
  };

  /// Renders the CoWriter selection/edit form
  const renderCoWriter = () => {
    return index == 0 ? (
      <PrimaryWriterEntry
        index={index}
        cowriter={cowriter}
        onChange={onChange}
      />
    ) : (
      <CoWriterSelection
        index={index}
        cowriter={cowriter}
        composers={composers}
        onChange={onChange}
      />
    );
  };

  return (
    <div className="co-writer-entry">
      <div className="co-writer-type">{index === 0 ? "YOU" : "CO-WRITER"}</div>
      <div className="co-writer-selection">{renderCoWriter()}</div>
      <div className="co-writer-action">{renderRemoveButton()}</div>
    </div>
  );
};

const PrimaryWriterEntry = ({ cowriter, onChange = () => {} }) => {
  const handleShareChange = (value) => {
    onChange(0, { ...cowriter, Share: value });
  };

  return (
    <>
      <div className="primary-writer">{GetComposerName(cowriter)}</div>
      <Share cowriter={cowriter} onChange={handleShareChange} />
    </>
  );
};

/*****************************************************************************************************
 * Co-Writer Entry Component - Drop Down Selection
 *****************************************************************************************************/
const CoWriterSelection = ({
  index,
  cowriter,
  composers = [],
  onChange = () => {},
}) => {
  // TODO: Add "New Composer" entry
  const options = [
    ...composers.map((c) => ({ label: GetComposerName(c), value: c.Guid })),
  ];
  const newEntryOption = {
    label: <i>New Composer Entry...</i>,
    value: "00000000-0000-0000-0000-000000000001",
  };
  const customEntry = cowriter.IsCustom ? (
    <CoWriterEditForm
      cowriter={cowriter}
      onChange={(c) => onChange(index, c)}
    />
  ) : null;

  const handleComposerSelectionChanged = (value) => {
    const composer = composers.find((x) => x.Guid == value);

    if (composer) {
      onChange(index, {
        Guid: composer.Guid,
        IsCustom: false,
        FirstName: composer.FirstName,
        MiddleName: composer.MiddleName,
        LastName: composer.LastName,
        PRO: composer.PRO,
        IpicaeNumber: composer.IpicaeNumber,
        ProMembershipNo: composer.ProMembershipNo,
        Share: cowriter.Share,
        OrderSequence: index + 1,
      });
    } else {
      onChange(index, {
        Guid: "00000000-0000-0000-0000-000000000001",
        IsCustom: true,
        FirstName: "",
        MiddleName: "",
        LastName: "",
        PRO: "",
        IpicaeNumber: "",
        ProMembershipNo: "",
        Share: "",
        OrderSequence: index + 1,
      });
    }
  };

  const handleShareChange = (value) => {
    onChange(index, { ...cowriter, Share: value });
  };

  return (
    <div className="co-writer-container">
      <div className="co-writer-entry-selection">
        <SKComboBox
          newEntryLabel={"New Composer Entry"}
          noSelectionLabel={"Select Composer..."}
          options={options}
          selectedOption={cowriter.Guid}
          newEntryOption={newEntryOption}
          onChange={handleComposerSelectionChanged}
        />
        <Share cowriter={cowriter} onChange={handleShareChange} />
      </div>
      {customEntry}
    </div>
  );
};

/*****************************************************************************************************
 * Co-Writer Entry Component - Edit Form
 *****************************************************************************************************/
const CoWriterEditForm = ({ cowriter, onChange = () => {} }) => {
  const [selectedOption, setSelectedOption] = React.useState(undefined);
  const handleUpdateCoWriter = (fieldName, fieldValue) => {
    cowriter[fieldName] = fieldValue;
    onChange(cowriter);
  };

  const options = [
    { label: "ASCAP", value: "ASCAP" },
    { label: "BMI", value: "BMI" },
    { label: "SESAC", value: "SESAC" },
  ];

  return (
    <div className="co-writer-entry-form">
      <table style={{ width: "100%" }}>
        <tr>
          <td className="entry-form-label required">First Name:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.FirstName}
              onChange={(value) => handleUpdateCoWriter("FirstName", value)}
            />
          </td>

          <td className="entry-form-label">Middle Name:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.MiddleName}
              onChange={(value) => handleUpdateCoWriter("MiddleName", value)}
            />
          </td>

          <td className="entry-form-label required">Last Name:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.LastName}
              onChange={(value) => handleUpdateCoWriter("LastName", value)}
            />
          </td>
        </tr>
        <tr>
          <td className="entry-form-label required">PRO:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.PRO}
              onChange={(value) => handleUpdateCoWriter("PRO", value)}
            />
          </td>

          <td className="entry-form-label required">IPI/CAE:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.IpicaeNumber}
              onChange={(value) => handleUpdateCoWriter("IpicaeNumber", value)}
            />
          </td>

          <td className="entry-form-label required">Membership #:</td>
          <td className="entry-form-field">
            <SKTextBox
              value={cowriter.ProMembershipNo}
              onChange={(value) =>
                handleUpdateCoWriter("ProMembershipNo", value)
              }
            />
          </td>
        </tr>
        <tr>
          <td className="entry-form-label required">US PRO:</td>
          {/* <td className='entry-form-field'><SKTextBox value={cowriter.USPro} onChange={(value) => handleUpdateCoWriter('USPro', value)} /></td> */}
          <td className="entry-form-field">
            <SKComboBox
              options={options}
              selectedOption={cowriter.USPro}
              onChange={(value) => handleUpdateCoWriter("USPro", value)}
            />
          </td>
        </tr>
      </table>
    </div>
  );
};

/*****************************************************************************************************
 * Co-Writer Entry Component - Edit Form
 *****************************************************************************************************/
const Share = ({ cowriter, onChange = () => {} }) => {
  return (
    <div className="co-writer-share">
      <div className="co-writer-share-label">Share:</div>
      <div className="co-writer-share-input">
        <SKTextBox value={cowriter.Share} onChange={onChange} />
      </div>
      <span>%</span>
    </div>
  );
};

export default NewCoWriterGroupModal;
