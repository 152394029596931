import React, { Fragment } from "react";
import "./Menu.scss"
import logo from "../../../appearance/images/app-logo-small.png";
import { NavLink } from "react-router-dom";

const Menu = () => {
    return (
        <Fragment>
            <div className="menu-container">
                <div className="menu-logo-container">
                    <img src={logo} className="logo" />
                </div>
                <div className="menu-list-container">
                    <ul className="menu-list">
                        <li><NavLink exact to="/composer-tracks" activeClassName="menu-list-active">YOUR TRACKS</NavLink> </li>
                        <li><NavLink exact to="/submit-tracks" activeClassName="menu-list-active">SUBMIT TRACKS FOR AUDITION</NavLink> </li>
                        <li><NavLink exact to="/approved-tracks" activeClassName="menu-list-active">UPLOAD APPROVED TRACKS</NavLink> </li>
                        <li><NavLink to="/co-writers" activeClassName="menu-list-active">CO-WRITERS</NavLink> </li>
                        <li><NavLink exact to="/edit-info" activeClassName="menu-list-active">EDIT/UPDATE YOUR INFORMATION</NavLink> </li>
                        <li><NavLink exact to="/composer-documents" activeClassName="menu-list-active">DOCUMENTS</NavLink> </li>
                        <li><NavLink to="/current-music-needs" activeClassName="menu-list-active">CURRENT MUSIC NEEDS</NavLink> </li>
                        <li><NavLink to="/production-guidelines" activeClassName="menu-list-active">PRODUCTION GUIDELINES</NavLink> </li>
                        <li><NavLink to="/faq" activeClassName="menu-list-active">FAQ</NavLink> </li>
                        <li><NavLink to="/contact-us" activeClassName="menu-list-active">CONTACT US</NavLink> </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default Menu;
