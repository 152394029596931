import React, { Fragment, useState, useEffect } from 'react';
import "./SubmitTracks.scss"
import UploadComposer from "../../../common/upload-composer/UploadComposer";
import Select from '../../../ui/select/Select';
import { useDispatch } from "react-redux"
import { ShowErrorNotification, ShowSuccessNotification } from '../../../ui/snackbar/Snackbar';
import { uploadComposerTracks } from '../../../../store/composer/actions';
import Spinner from '../../../ui/spinner/Spinner';
import Image from '../../../ui/image/Image';
import Done from "../../../../appearance/images/Done.png"
import Modal from '../../../common/modal/Modal';
import ConfirmationModal from '../../../common/modal/modal-content/confirmation-modal/ConfirmationModal';

const SubmitTracks = (props) => {
    const [selectedSubmissionType, setSelectedSubmissionType] = useState(undefined);
    const [notes, setNotes] = useState("")
    const [files, setFiles] = useState([])
    const [isLoading, setIsLoading] = useState(false);
    const [submitTrackStatus, setSubmitTrackStatus] = useState(true)
    const dispatch = useDispatch();
    const [notesWordCount, setNotesWordCount] = useState(500)
    const [notesMaxLength, setNotesMaxLength] = useState(500)
    const [toggleConfirmationModal, setToggleConfirmationModal] = useState(false)

    const handleOnSelect = (option) => {
        setSelectedSubmissionType(option)
    }

    const handleFileSelect = (e) => {
        let validFileNames = true;

        if (e.target.files) {
            const selectedfiles = e.target.files;
            let isAudioFiles = true;
            for (let i = 0; i < selectedfiles.length; i++) {
                const file = selectedfiles[i];
                if (file.type !== "audio/mp3" && file.type !== "audio/mpeg") {
                    isAudioFiles = false
                    break
                }
            }

            if (!isAudioFiles) {
                ShowErrorNotification("Mp3 files only (no zipped files or folders)", 3000)
                return
            }

            const tempFiles = [];
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                tempFiles.push(file)
            }

            for (let i = 0; i < selectedfiles.length; i++) {
                const file = selectedfiles[i];
                tempFiles.push(file)
            }

            // Filename validation. Check for special case characters.
            for (let i = 0; i < selectedfiles.length; i++) {
                if (selectedfiles[i].name.includes("/") || selectedfiles[i].name.includes("\\")
                || selectedfiles[i].name.includes("?") || selectedfiles[i].name.includes(":")) {
                    validFileNames = false;
                    break;
                }
            }

            if (!validFileNames) {
                setToggleConfirmationModal(true);
                return
            }

            setFiles(tempFiles)
        }

    }

    const clearFields = () => {
        setFiles([]);
        setNotes("");
        setSelectedSubmissionType(undefined);
        clearInputFile();
    }

    const clearInputFile = () => {
        const file = document.getElementById("file")
        file.value = "";
    }

    function handleFileDelete(fileName) {
        const newFiles = [];
        files.forEach(file => {
            if (file.name !== fileName) {
                newFiles.push(file);
            }
        })
        setFiles(newFiles)
    }

    const handleUpload = () => {

        if (!selectedSubmissionType) {
            ShowErrorNotification("Please select submission type.", 3000)
            return
        }

        if ((files && files.length === 0) && notes.trim() === "") {
            ShowErrorNotification("Please either select tracks or add links on notes section", 3000)
            return
        }

        const data = {
            notes,
            submissionType: selectedSubmissionType.label
        }

        setIsLoading(true)

        dispatch(uploadComposerTracks(data, files)).then(res => {
            if(res.Success){
                setIsLoading(false)
                ShowSuccessNotification("Upload successful!", 3000)
                setSubmitTrackStatus(false)
                // clearFields();
            }
        }).catch(error => {
            setIsLoading(false)
            ShowErrorNotification("Unable to upload the tracks. Please try again", 3000)
        })
    }

    const handleNoteChange = (value) => {
        setNotes(value);
        setNotesWordCount(notesMaxLength - value.length);
    }

    function getOptions() {
        let newOptions = [{ id: 0, label: "Exclusive" }];
        if (localStorage.getItem('allowNonExclusive') === 'true') {
            newOptions.push({id: 1, label: "Non-Exclusive" });
        }
        newOptions.push({id: 2, label: "Custom Cues" });
        return newOptions;
    }

    const handleModalClose = () => {
        setToggleConfirmationModal(false);
    }

    return (
        <Fragment>
            <div className="submit-tracks-container">
               {
                submitTrackStatus ? (<div>
                 <h3 className="title is-3">Submit Tracks For Audition</h3>
                <div className="mg-top-1">

                    <Select
                        label="*What type of submission is this?"
                        onSelect={handleOnSelect}
                        selectedOption={selectedSubmissionType}
                        options={getOptions()}
                    />
                    <p style={{paddingTop:10}}>
                        All submissions need to be for exclusive representation unless otherwise approved.  Contact anr@scorekeepersmusic.com
                    </p>
                    <div className="mg-top-2">
                        <UploadComposer
                            uploadButtonDivStyle="mg-lft-rgt-3"
                            message={<div className='upload-message'> <span className='alert'>* Please use the Chrome browser, otherwise your upload may fail.</span></div>}
                            fileSelectTitle="SELECT TRACKS"
                            bottomNote1="*if you have different submission types, please do so in separate uploads. For example, do not include exclusive and custom cue tracks in the same upload."
                            note={notes}
                            files={files}
                            onUpload={handleUpload}
                            onFileSelect={handleFileSelect}
                            onFileDelete={handleFileDelete}
                            notesWordCount={notesWordCount}
                            notesMaxLength={notesMaxLength}
                            onNoteChange={handleNoteChange}
                        />
                    </div>
                </div> </div>) : (<div className="image-center">
                    <Image url={Done} />
                    <p>
                        You're tracks have been received and are in the queue for review.
                   </p>
               </div>)
               }
            </div>
            <Spinner isLoading={isLoading} />
            <Modal isVisible={toggleConfirmationModal} onClose={handleModalClose}>
                <ConfirmationModal
                    title="Warning!"
                    message="Upload file(s) without special case characters like /, \\, : or ?"
                    comment=""
                    onCancel={handleModalClose}
                    onConfirm={handleModalClose}
                    isButtonVisible="false"
                />
            </Modal>
        </Fragment>
    )
}

export default SubmitTracks;