import React, { useState } from 'react';
import "./Home.scss"

import Header from '../../common/header/Header';
import Footer from '../../common/footer/Footer';
import ComposerButton from '../../common/compose-button/ComposerButton';
import Modal from '../../common/modal/Modal';
import NewComposerModal from '../../common/modal/modal-content/submission-modal/NewComposerModal';

const Home = (props) => {

    const [toggleNewComposerModal, setToggleNewComposerModal] = useState(false);

    const handleModalClose = () => {
        setToggleNewComposerModal(false)
    }

    const handleNewComposers = () => {
        setToggleNewComposerModal(true)
    }

    const handleLogin = () => {
        props.history.push("/login");
    }

    return (
        <div className="home-container">
            <Header title="MUSIC SUBMISSIONS" />
            <div className="body-container">
                <div className="info-container ">
                    <p className="title is-3 has-text-centered">We&#8217;d love to hear your music!</p>
                    <p className="subtitle is-6 has-text-centered">(if you already have a ScoreKeepers composer account <span><a className="login-link" onClick={handleLogin}>login here</a></span>)</p>
                </div>
                <div className="columns composers-action-container is-marginless is-centered">
                    <div>
                        <ComposerButton
                            title="NEW COMPOSERS"
                            subtitle="CLICK HERE TO SUBMIT MUSIC SAMPLES"
                            infoTitle = "ScoreKeepers is interested in representing music on an exclusive basis. We may consider non-exclusive representation if the music is particularly unique, or fills an urgent need."
                            onClick={handleNewComposers}
                            imgUrl={require("../../../appearance/images/upload.png")}
                        />
                    </div>
                </div>
            </div>
            <Footer />
            <Modal isVisible={toggleNewComposerModal} onClose={handleModalClose}>
                <NewComposerModal onClose={handleModalClose} />
            </Modal>
        </div>
    )
}

export default Home;