import React, { Fragment } from 'react';
import "./ForgotPasswordSuccessModal.scss"
import Title from '../../../../ui/title/Title';
import Button from '../../../../ui/button/Button';

const ForgotPasswordEmailSentSuccessModal = ({onClick}) => {
    return (
        <Fragment>
            <div className="forgot-password-success-modal-container">
                <Title title="FORGOT PASSWORD" />
                <div id='instructions' className="mg-top-1 mg-lft-rgt-1 is-vcentered">
                    <p className="forgot-password-success">A temporary password will be sent your email address on file with ScoreKeepers Music.</p>
                    <p className="forgot-password-success">If you do not see the email in a few minutes, check your "junk mail" or "spam" folder and add "admin@scorekeepersmusic.com" to your White List or Safe Sender List.</p>
                    <p className="forgot-password-success">If you still don't receive an email, then send an email to <a href="tech@scorekeepersmusic.com">tech@scorekeepersmusic.com</a>.  We will do our best to answer your request as soon as possible.</p>
                </div>
                <div className="column mg-lft-rgt-1 text-align-right">
                    <Button title="OK" onClick={onClick} buttonStyle="blue-large-button" />
                </div>
            </div>
        </Fragment>
    )
}

export default ForgotPasswordEmailSentSuccessModal;