import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux"

import "./ComposerDocuments.scss"
import { FetchComposerDocuments } from "../../../store/composer/actions";

const ComposerDocuments = ({ }) => {
	const dispatch = useDispatch();
	const [composerDocuments, setComposerDocuments] = useState(null)
	const [filteredComposerDocuments, setFilteredComposerDocuments] = useState([])
	const [hasLoadedComposerDocuments, setHasLoadedComposerDocuments] = useState(false);

	useEffect(() => {
		if (!hasLoadedComposerDocuments) {
			getComposerDocuments();
		}
	}, [])

	const getComposerDocuments = () => {
		const composerId = localStorage.getItem('composerId');
		dispatch(FetchComposerDocuments(composerId)).then(res => {
			if (res.Success) {
                setComposerDocuments(res.ComposerDocuments);
				setFilteredComposerDocuments(res.ComposerDocuments);
			} else {
				ShowErrorNotification("Unable to fetch composer tracks", 3000)
			}
			setHasLoadedComposerDocuments(true);
		})
	}
    
    const handleDownload = (key) => {
        const url = 'https://sk-composers.s3.us-east-1.amazonaws.com/';
        const uri = superEncodeURI( url + key)
        const fileName = key.split('/')[2];

        const link = document.createElement('a');
        link.href = uri;
        link.download = fileName;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const superEncodeURI = (url) => {
        var encodedStr = '', encodeChars = ["(", ")", "+"];
        var decodedUrl = decodeURI(url);
        url = encodeURI(decodedUrl);
    
        for(var i = 0, len = url.length; i < len; i++) {
            if (encodeChars.indexOf(url[i]) >= 0) {
                var hex = parseInt(url.charCodeAt(i)).toString(16);
                encodedStr += '%' + hex;
            }
            else {
                encodedStr += url[i];
            }
        }
    
        return encodedStr;
    }

	const renderLoadingScreen = () => {
		return (
			<div className='is-loading'>
				<h3 className="title is-3">Loading Documents ...</h3>
			</div>
		);
	}

	const renderNoTracksScreen = () => {
		return (
			<div style={{position:'relative'}}>
				<h3 className="title is-3">You currently have no active tracks with ScoreKeepers.</h3>
			</div>
		)
	}

	const renderTracksScreen = () => {
		return (
			<div style={{position:'relative'}}>
				<h3 className="title is-3">Your Documents</h3>
				<div style={{paddingBottom: '25px'}}>
					<div style={{float: 'left', width: '20%', fontSize: '1.25rem', fontWeight: '600'}}>Total number of document(s): {filteredComposerDocuments.length}</div>
				</div>
				<div className="composer-tracks">
					<table id="main-table">
						<thead>
							<tr>
								<th style={{ width: '70%', textAlign: 'left' }}>Document</th>
								<th style={{ width: '20%', textAlign: 'center' }}>Upload Date</th>
								<th style={{ width: '10%', textAlign: 'center' }}>Action</th>
							</tr>
						</thead>
						<tbody>
							{
								filteredComposerDocuments.map(element =>
									<tr key={element.Id}>
										<td style={{ width: '70%', textAlign: 'left' }}>{element.Key}</td>
										<td style={{ width: '20%', textAlign: 'center' }}>{element.LastModified}</td>
										<td style={{ width: '10%', textAlign: 'center'  }}>
                                            <button className="button is-small mg-lft-pt-5 logout-button" onClick={e => handleDownload(element.Key)}>
                                                Download
                                            </button>
                                        </td>
									</tr>
								)
							}
						</tbody>
					</table>
				</div>
			</div>
		)
	}

	if (hasLoadedComposerDocuments) {
		if (composerDocuments.length > 0) {
			return renderTracksScreen();
		} else {
            return renderNoTracksScreen();
		}
	} else {
		return renderLoadingScreen();
	}
}

export default ComposerDocuments