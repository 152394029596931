import React, { Fragment, useState } from "react";
import "./SelectContextComponent.scss";
import Icon from "../icon/Icon";


const SelectContextComponent = ({
    value,
    width = 100,
    extraLabel
}) => {

    return (
        <div className="select-context-components">
            <div className="select-body-container">
                <span className="select-button-div" style={{ width }}>
                    <span className="select-button">{value ? value : "Select"}</span>
                    <Icon iconContainerStyle="icon-container" iconName="fas fa-caret-down" iconStyle="icon-style" />
                </span>
                {
                    extraLabel ? <span style={{paddingLeft: '10px'}}>{extraLabel}</span> : null
                }
            </div>
        </div>
    )
}

export default SelectContextComponent;