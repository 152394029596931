import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import "./SignOnline.scss";
import {
  FetchSignOnlineTemplates,
  showAgreementPdfFile,
  generateSignOnlinePdf,
} from "../../../../store/composer/actions";
import Spinner from "../../../ui/spinner/Spinner";
import Signhere from "../../../../appearance/images/sing_here.png";

const SignOnline = (props) => {
  const dispatch = useDispatch();
  const [agreementUrl, setAgreementUrl] = useState("");
  const [agreementFileName, setAgreementFileName] = useState("");
  const [emailTemplates, setEmailTemplates] = useState(undefined);
  //  const [downloadBtn, setDownloadBtn] = useState(false);
  const [selectedSign, setSelectedSign] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const data = props.composerName;
    data["templateType"] = props.templateType;

    dispatch(FetchSignOnlineTemplates(data))
      .then((res) => {
        if (res.Success) {
          const today = new Date();
          const agreementFileName = props.composerName.FullName.replace(/ /g, "_");
          // const agreementUploadDate = props.composerName.ModifiedDate.replace(/\//g, '_');
          const agreementUploadDate = today
            .toLocaleDateString("us-en", { month: "2-digit", day: "2-digit", year: "numeric" })
            .replace(/\//g, "_");
          const KeyAgreementFile = agreementFileName + "_" + agreementUploadDate + ".pdf";
          setEmailTemplates(res.template);
          fetchPdfUrl(KeyAgreementFile);
        }
      })
      .catch((err) => {
        console.log("ERROR: ", err);
      });
  }, []);

  const fetchPdfUrl = (fileName) => {
    dispatch(showAgreementPdfFile(fileName))
      .then((res) => {
        if (res.Success) {
          setAgreementUrl(res.url);
          setAgreementFileName(res.fileName);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getHtmlData = () => {
    if (!emailTemplates || !emailTemplates) {
      return null;
    } else {
      let el = document.querySelector("#signOnline-page");
      el.innerHTML = null;
      let contentDiv = document.createElement("div");
      contentDiv.innerHTML = emailTemplates;
      el.classList.add("show-pdf-align");
      el.appendChild(contentDiv);
      let element = document.getElementById("sign-image");
      let signatureImage = element.src;
      let elementClick = document.getElementById("signClickImage");
      elementClick.src = Signhere;
      elementClick.width = "100";
      elementClick.height = "10";
      !showImage ? (element.style.display = "none") : (element.style.display = "block");
      element.src = signatureImage;

      let elementHidden = document.getElementById("byHiddenText");
      elementHidden.style.display = "none";
      elementClick.addEventListener("click", () => {
        if (showImage) {
          setShowImage(false);
          element.style.display = "block";
        } else {
          element.width = "380";
          element.height = "100";
          element.style.display = "block";
          if (selectedSign) {
            props.isSignOnlineSelected(true, agreementFileName);
          } else {
            props.isSignOnlineSelected(false, null);
            setSelectedSign(true);
          }
          setIsLoading(true);
          let data = props.composerName;
          data["template"] = emailTemplates;
          data["template"] = data["template"].replace("width='380px' height='100px'", "'width='250px' height='60px'");

          dispatch(generateSignOnlinePdf(data))
            .then((res) => {
              if (res.Success) {
                setShowImage(true);
                // setDownloadBtn(true);
                element.classList.add("show-signature-image");
                props.isSignOnlineSelected(true, res.FileName);
                setAgreementFileName(res.FileName);
                setIsLoading(false);
              }
            })
            .catch((err) => {
              setIsLoading(false);
              console.log("ERROR:", err);
            });
        }
      });
    }
  };

  return (
    <div className="signOnline-page">
      <Spinner isLoading={isLoading} />
      <div className="sign-schedule-body">
        <div className="sign-schedule-statement">
          Please review and sign the agreement. The agreement will be downloadable for your records once your digital
          e-signature has been executed.{" "}
        </div>
        <div className="signOnline-page" id="signOnline-page">
          <div className="show-pdf-align">{getHtmlData()}</div>
        </div>

        {/* {
           !selectedSign && props.showSignOnlineValidations ? ( <span className="error-message">Please click Sign here...</span>) : ''
          } */}

        {/* {
          downloadBtn ? (<Button title="Download Signed Schedule"
            buttonStyle="download-btn" onClick={readyToDownload}></Button>) : null
        } */}
      </div>
    </div>
  );
};

export default SignOnline;
