import React, { Fragment } from 'react';
import "./ConfirmationModal.scss"
import Title from '../../../../ui/title/Title';
import TextInput from '../../../../ui/text-input/TextInput';
import Button from '../../../../ui/button/Button';

const ConfirmationModal = ({
    title,
    message,
    comment,
    anotherComment=null,
    onConfirm = () => { },
    onCancel = () => { },
    isCancelButton = "true"
}) => {
    return (
        <Fragment>
            <div className="confirmation-modal-cotainer">
                <Title title={title} />
                <div className="msg-container">
                    <p className="title is-6">{message}</p>
                    <p className="subtitle is-6 comment">{comment}</p>
                    {anotherComment !== null && <p className="subtitle is-6 comment">{anotherComment}</p> }
                </div>
                {
                    isCancelButton === "true" ? 
                        <div className="btn-container">
                            <Button title="CANCEL" onClick={onCancel} buttonStyle="blue-small-button" />
                            <Button title="OK" onClick={onConfirm} buttonStyle="blue-small-button" />
                        </div>
                    : <div className="btn-container">
                        <Button title="OK" onClick={onConfirm} buttonStyle="blue-small-button" />
                    </div>
                }
            </div>
        </Fragment>
    )
}

export default ConfirmationModal;