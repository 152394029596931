import React, { Fragment } from 'react';
import "./ComposerButton.scss"
import Icon from '../../ui/icon/Icon';
import Image from '../../ui/image/Image';

const ComposerButton = ({
    title,
    subtitle,
    infoTitle,
    imgUrl,
    onClick
}) => {
    return (
        <Fragment>
            <div className="composer-button-container" onClick={onClick}>
                <Image url={imgUrl} imgClassName="img-container" />
                <p className="composer-button-sub-1 title is-4 title-mg">{title}</p>
                <p className="composer-button-sub-2 title is-6 is-marginless">{subtitle}</p>
                <p className="composer-button-sub-3 is-6 has-text-centered">{infoTitle}</p>
            </div>
        </Fragment>
    )
}

export default ComposerButton;