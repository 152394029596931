
export const logError = (logType, message) => {
    const content = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            source: 'The Supervisor - Composers',
            logType: logType,
            errorMessage: message
        })
    };

    fetch('/api/error/log', content);
}