import React, { Fragment, useState, useEffect } from 'react';
import Title from '../../../../ui/title/Title';
import "./Privacy.scss";

const PrivacyModal = ({ onClose }) => {   
    return (
        <Fragment>
            <div className="privacy-modal-cotainer">
                <Title title="Privacy Statement" />
                <p className="mg-top-1 mg-lft-rgt-1">
                  When you register for a ScoreKeepers Composer account, we ask for certain information such as your username, real name, birthdate, address, phone number, e-mail address, Performing Rights affiliation, and payment preferences. We may also retain any messages you send through the Composer Portal, and may collect information you provide in user content you upload to the Portal. We use this information to operate, maintain, and provide to you the features and functionality of the Portal. Only your real name, PRO, and music titles will be published publicly or shared with third parties for the purpose of promoting your music on our search application and generating music cue sheet data. We do not rent or sell your personal information.
                </p>
                <p className="mg-top-1 mg-lft-rgt-1">
                  ScoreKeepers cares about the security of your information, and uses commercially reasonable safeguards to preserve the integrity and security of all information collected through the Composer Portal. To protect your privacy and security, we take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. You are responsible for maintaining the secrecy of your unique password and account information, and for controlling access to your email communications from ScoreKeepers, at all times.
                </p>
            </div>
        </Fragment>
    )
}

export default PrivacyModal;