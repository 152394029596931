import React, { Fragment, useState, useRef } from "react";
import "./Review.scss";
const Review = () => {
  return (
    <div className="review-page">
      <div >
        Please review agreement, if you're ready to sign online, go to step 2.
      </div>
      <br />
      <div>
        Questions about the agreement? Please email:
        <div>anr@scorekeepersmusic.com</div>
      </div>
    </div>
  );
};

export default Review;
