import React, { Fragment } from 'react';
import "./Footer.scss"
import Image from '../../ui/image/Image';

const Footer = () => {
    const [uploadModalTracks] = State.useGlobalState('uploadModalTracks');

    const uploadsIcon = uploadModalTracks.length > 0
        ? <div onClick={() => setUploadModalVisible(true)}><Image url="../../../appearance/images/upload-indicator.png" imgClassName='upload-indicator' /></div>
        : null;

    return (
        <>
            <div className="footer-container no-mg columns is-mobile is-marginless">
                <div className="column is-paddingless copy-left-container">
                    {uploadsIcon}                    
                </div>
                <div className="column is-paddingless copy-right-container">
                    <a target="_blank" rel="noopener noreferrer" href="http://www.facebook.com/scorekeepersmusic" className="center"><Image url={require("../../../appearance/images/Facebook.png")} imgClassName="social-icon" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.twitter.com/scorekeepers" className="center"><Image url={require("../../../appearance/images/Twitter.png")} imgClassName="social-icon" /></a>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.instagram.com/scorekeepers_music" className="center"><Image url={require("../../../appearance/images/Instagram.png")} imgClassName="social-icon" /></a>
                    <span><p>© SCOREKEEPERS MUSIC, INC.</p></span>
                </div>
            </div>            
        </>
    )
}

export default Footer;