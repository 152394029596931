import React from 'react';
import { render } from 'react-dom';
import './Snackbar.scss';

const SnackbarType = {
    Error: 'error',
    Success: 'success',
    Warning: 'warning',
}

const Snackbar = (props) => {
    return (
        <div className={`Snackbar ${extraClassName(props.snackbarType)}`} role="alert">
            <div className="Snackbar-content">
                <div className="Snackbar-icon">{icon(props.snackbarType)}</div>
                <div className="Snackbar-msg">{props.message}</div>
                <div className="Snackbar-actions">
                    <button aria-label="Close" onClick={props.close}><i className='icon-times-circle' /></button>
                </div>
            </div>
        </div>
    );
};

function icon(snackbarType) {
    switch (snackbarType) {
        case SnackbarType.Error:
            return <div><i className="fas fa-exclamation-triangle"></i></div>;
        case SnackbarType.Success:
            return <div><i className="fas fa-check-circle"></i></div>;
        case SnackbarType.Warning:
            return <div><i className="fas fa-exclamation-triangle"></i></div>;
    }
}

function extraClassName(snackbarType) {
    switch (snackbarType) {
        case SnackbarType.Error:
            return 'ErrorSnackbar';
        case SnackbarType.Success:
            return 'SuccessSnackbar';
        case SnackbarType.Warning:
            return 'WarningSnackbar';
    }
}

const ErrorSnackbar = (props) => {
    return <Snackbar {...props} snackbarType={SnackbarType.Error} />;
};

const SuccessSnackbar = (props) => {
    return <Snackbar {...props} snackbarType={SnackbarType.Success} />;
};

const WarningSnackbar = (props) => {
    return <Snackbar {...props} snackbarType={SnackbarType.Warning} />;
};



const ShowSnackbarNotification = (type, message, time) => {
    const removeElement = (element) => {
        document.querySelector('.notification-center').removeChild(div);
    };

    const div = document.createElement("div");

    let snackbar = null;
    switch (type) {
        case SnackbarType.Success:
            snackbar = <SuccessSnackbar message={message} close={() => removeElement(div)} />;
            break;
        case SnackbarType.Error:
            snackbar = <ErrorSnackbar message={message} close={() => removeElement(div)} />;
            break;
        case SnackbarType.Warning:
            snackbar = <WarningSnackbar message={message} close={() => removeElement(div)} />;
            break;
    }

    render(snackbar, div);
    document.querySelector('.notification-center').appendChild(div);

    if (time) {
        setTimeout(function () {
            document.querySelector('.notification-center').removeChild(div);
        }, time);
    }
}

export const ShowSuccessNotification = (message, time) => {
    ShowSnackbarNotification(SnackbarType.Success, message, time);
}

export const ShowErrorNotification = (message, time) => {
    ShowSnackbarNotification(SnackbarType.Error, message, time);
}

export const ShowWarningNotification = (message, time) => {
    ShowSnackbarNotification(SnackbarType.Warning, message, time);
}