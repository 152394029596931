import React from "react";
import "./Spinner.scss";

const Spinner = ({
    isLoading
}) => {
    if (!isLoading) return null


    return (
        <div className="spinner-container">
            <div className="spinner">
                <i className="fas fa-circle-notch fa-spin"></i>
            </div>
            <div className="spinner-text">
                Please wait. This may take some time depending upon your file size.
            </div>
        </div>
    )
}

export default Spinner