import React, { Fragment, useState, useEffect } from "react";
import "./FinalPage.scss";
import Done from "../../../../appearance/images/Done.png";
import Image from "../../../ui/image/Image";
import { useDispatch } from "react-redux";
import { SendAgreementEmailConfirmation, DownloadComposerAgreement } from "../../../../store/composer/actions";
import Button from "../../../ui/button/Button";

const FinalPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    sendMailFunction();
  },[])

  const sendMailFunction = () => {
    // SendEMail only to new composers
    if (props.composerName !== undefined)
    {
      dispatch(SendAgreementEmailConfirmation(props.composerName.Guid)).then(res => {
      }).catch(err => {
        console.log("ERROR: ", err)
      })
    }
  }

  const renderDownloadButton = () => {
    if (props.showDownloadButton) {
      return (
        <div style={{paddingTop: '20px', textAlign: 'center'}}>
          <Button
            title="Download Signed Agreement"
            buttonStyle="download-btn"
            onClick={handleDownload}
          />
        </div>
      )
    } else {
      return null
    }
  }

  const handleDownload = () => {
    dispatch(DownloadComposerAgreement(props.agreementFileName, "agreement"))
  }

  return (
    <div className="final-page">
       <div className="image-center">
        <Image url={Done} />
      </div>
      <p>Congratulations! You are now signed up with ScoreKeepers! </p>{" "}
      <p>
        You can login to your ScoreKeepers account at the link below. Here you
        can upload new track submissions, update your info, and more!
      </p>
      <p>
        <a href="http://composers.scorekeepersmusic.com/login">http://composers.scorekeepersmusic.com/login</a>
      </p>
      {renderDownloadButton()}
    </div>
  );
};

export default FinalPage;
